import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import { HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {IZipcode} from "../../models/zipcode.model";
import {Observable, throwError} from "rxjs";
import moment from "moment";
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: "root"
})
export class ZipCodeValidatorService {
    zipRegexp = /^\d{5}$/;
    validZipCodes: IZipcode[] = [];


    constructor(private translate: TranslateService, private http: HttpClient) {
    }

    // @ts-ignore
    getAllZipCodeFromServer(): Observable<IZipcode[]> {
        // @ts-ignore
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'ocp-apim-subscription-key': environment.ocp_apim_subscription_key
        });
        const options = {
            headers: headers
        };

        return this.http.get<IZipcode[]>(environment.zipcodeurl, options);
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server Error");
    }

    storeZipCodesAsync(): void {
        this.getAllZipCodeFromServer().subscribe(data => {
            if (data[0]["zip"]) {
                this.validZipCodes = data;
            }
            else {
                let trans = [];
                for (const zip of data) {
                    const item: IZipcode = {
                        zip: zip["Zip"],
                        territory: zip["Territory"],
                        start: zip["Start"],
                        end: zip["End"]
                    };
                    trans.push(item);
                }
                this.validZipCodes = trans;
            }
        });
    }

    validateZipCodes(proposedZipCode, startDate) {
        let errorMessage;
        if ((proposedZipCode && proposedZipCode.length !== 5) ||
            !this.zipRegexp.test(proposedZipCode)) {
            errorMessage = 'ERRORS.ENTER_VALID_ZIPCODE';
            return {
                isError: true,
                showLinkError:false,
                errorMessage
            };
        } else {
            if (startDate !== null) {
                errorMessage = this.zipCodeValidOnQuoteDate(proposedZipCode, startDate);
                if (errorMessage) {
                    return {
                        isError: false,
                        showLinkError:false,
                        errorMessage: ""
                    };
                } else {
                    // tslint:disable-next-line:max-line-length
                    const url = environment.eqasiteurl + environment.participatinginsurersurl;
                    
                    return {
                        isError: false,
                        showLinkError:true,
                        errorMessage: url
                    };
                }
            }
        }
        return {
            isError: false,
            showLinkError:false,
            errorMessage: ""
        };
    }

    zipCodeValidOnQuoteDate(proposedZipCode: any, onDate) {
        let found = false;

        // tslint:disable-next-line:prefer-for-of
        for (const zipCodeInfo of this.validZipCodes) {
            found = zipCodeInfo.zip === proposedZipCode
                && onDate >= moment(zipCodeInfo.start) &&
                onDate <= moment(zipCodeInfo.end);

            if (found) {
                break;
            }
        }

        return found;
    }

    CreateLocalDate(dateString) {
        const dt = new Date(dateString);
        const userTimezoneOffset = dt.getTimezoneOffset() * 60000;
        return new Date(dt.getTime() + userTimezoneOffset);
    }
}
