import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef, NgZone, ViewChild, ElementRef, Injectable, HostListener, ChangeDetectorRef } from "@angular/core";
import { NgbDateStruct, NgbModal, NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import { PopupComponent } from "..";
import { moduleType } from "../../shared/models/module.type";
import { PiValidatorsService } from "../../shared/services/validators/pi-validators.service";
import { QuoterRequest } from "../../shared/models/quoterRequest.model";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { HelperService } from "../../shared/services/helper/helper.service";
import { QuoterRequestErrorGeneral } from "../../shared/models/quoterRequestError";
import { ZipCodeValidatorService } from "../../shared/services/validators/zip-code-validator.service";
import { QuoterRequest2Service} from "../../shared/services/quote-request/quote-request2.service";
import { CalculatorSettingsService } from 'src/app/shared/services/calculatorsettings/calculator-settings.service';
import { ImageService } from '../../shared/services/images/image.service';
import { ErrorMessageService } from "../../shared/services/error-message/error-message.service";
import { CalcDatesService } from "../../shared/services/calc-dates/calc-dates.service";
import { TranslationWidth } from "@angular/common";

const I18N_VALUES = {
    'chi': {
        weekdays: ['一', '二', '三', '四', '五', '六', '日'],
        months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    },
    'en': {
        weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    'es': {
        weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá', 'Do'],
        months: ['Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.', 'Jul.', 'Ago.', 'Sep.', 'Oct.', 'Nov.', 'Dic.'],
    },
    'zh': {
        weekdays: ['一', '二', '三', '四', '五', '六', '日'],
        months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    }
};

@Injectable()
export class CusDatepickerI18n extends NgbDatepickerI18n {
    constructor(private translate: TranslateService) {
        super();
    }

    getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
        const lang = this.getLanguage();
        return I18N_VALUES[lang].weekdays[weekday - 1];
    }

    getMonthShortName(month: number): string {
        const lang = this.getLanguage();
        return I18N_VALUES[lang].months[month - 1];
    }

    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }

    getLanguage() {
        let lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang
        if (lang === "kv") {
            lang = "en";
        }

        return lang;
    }
}

@Component({
    selector: "app-general",
    templateUrl: "./general.component.html",
    styleUrls: ["./general.component.scss"],
    providers: [{ provide: NgbDatepickerI18n, useClass: CusDatepickerI18n }]
})

export class GeneralComponent implements OnInit {
    constructor(private modalService: NgbModal,
        private piValidatorsService: PiValidatorsService,
        private viewContainerRef: ViewContainerRef,
        private router: Router,
        private translate: TranslateService, private helper: HelperService,
        public zone: NgZone,
        private changeDetector: ChangeDetectorRef,
        private zipCodeValidatorService: ZipCodeValidatorService,
        private quoterRequestService: QuoterRequest2Service,
        private route: ActivatedRoute,
        private calculatorSettingsService: CalculatorSettingsService,
        private errorMessageService: ErrorMessageService,
        private imageService: ImageService,
        private calcDateService: CalcDatesService) {
        this.validPiNames = this.piValidatorsService.GetPossiblePiValues();
        this.zipCodeValidatorService.storeZipCodesAsync();
        this.defaultSetting = calculatorSettingsService.participatingInsurerSettings.defaultParticipatingInsurerFieldValue;
    }
    defaultSetting = '';
    zipCodeErrorMessage = "";
    @Output() gotoQuestionPageEvent = new EventEmitter<string>();
    @Input() policytype: number;
    @ViewChild("startDate") startDateInput: ElementRef;
    page = moduleType;
    gotoQuestion = "true";
    quoterRequest: QuoterRequest;
    quoterRequestError: QuoterRequestErrorGeneral = new QuoterRequestErrorGeneral();
    validPiNames: [];
    isMobileView: boolean;
    isMobileArr: boolean[] = [];
    // tslint:disable-next-line:ban-types
    address: Object;
    // tslint:disable-next-line:ban-types
    establishmentAddress: Object;
    groupkey = this.helper.GenerateGuid();
    startDateTouched = false; //needed in IE11 - track first touch
    dateRangeErrorMessage: string;

    screenWidth = 0;
    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.screenWidth = window.innerWidth;
    }

    ngOnInit(): void {
        if (this.route.snapshot.queryParams['reset']) {
            const reset = this.route.snapshot.queryParams['reset'];
            if (reset === "true") {
                if (this.quoterRequestService.getLastPolicyTypeExplored() !== this.policytype) {
                    this.quoterRequestService.resetAllData();
                }
            }
        }
        this.errorMessageService.moveTop();

        this.quoterRequestService.saveLastPolicyTypeExplored(this.policytype);
        this.quoterRequest = this.quoterRequestService.getSavedQuoterRequest();
        this.quoterRequest.policytype = this.policytype;

        if (!this.quoterRequest.ParticipatingInsurer) {
            this.quoterRequest.ParticipatingInsurer = this.calculatorSettingsService.
                participatingInsurerSettings.defaultParticipatingInsurerFieldValue;
        }


        //fixes timing issue on ie evaluating zipcode
        if (this.quoterRequest.ZipCode) {
            let that = this;
            this.quoterRequest.ZipCode = this.trimZipcode(this.quoterRequest.ZipCode);
            setTimeout(function () {
                that.validateZipCode(that.quoterRequest.ZipCode, false);
            }, 2000);
        }
    }


    getMinDateVal() {
        return this.calcDateService.getMinStartDate();
    }

    getMaxDateVal() {
        return this.calcDateService.getMaxStartDate();
    }

    calanderButtonClicked(d) {
        d.toggle();
        this.startDateInput.nativeElement.focus();
        this.startDateTouched = true;
    }

    calanderFieldClicked(d) {
        d.toggle();
        this.startDateTouched = true;
    }

    mobileVisible(value: boolean, index: number) {
        this.isMobileArr[index] = value;
        this.isMobileView = this.isMobileVisible();
        this.changeDetector.detectChanges();
    }


    isMobileVisible() {
        for (let val of this.isMobileArr) {
            if (val) {
                return true;
            }
        }

        return false; 
    }

    openModal(data, size) {
        const modalRef = this.modalService.open(PopupComponent,
            {
                scrollable: true,
                windowClass: "myCustomModalClass",
                size
            });
        modalRef.componentInstance.fromParent = data;
        modalRef.result.then();
    }

    goBack() {
        this.router.navigate([""]);
    }

    isRenewPeriod() {
        return this.calcDateService.isRenewPeriod(this.quoterRequest.ActualStartDate);
    }

    participatingInsurerOpened() {
        if (this.quoterRequest.ActualStartDate && !this.quoterRequestError.startDateNotSet) {
            this.quoterRequestError.participatingInsurerDateNeeded = false;
        } else {
            this.quoterRequestError.participatingInsurerDateNeeded = true;
        }
    }

    validateSetStartDate(startDateInput: NgbDateStruct | string) {
        //brute force on translation but need it to work
        this.dateRangeErrorMessage = this.calcDateService.getErrorMessage();

        let startDate = this.calcDateService.parseStartDate(startDateInput);

        if (!this.calcDateService.isValidDate(startDate)) {
            this.quoterRequestError.startDateNotSet = true;
            this.quoterRequestError.startDateOutOfRange = false;
            return;
        } else {
            this.quoterRequestError.startDateNotSet = false;
        }

        this.quoterRequest.ActualStartDate = startDate;
        this.quoterRequest.StartYear = startDate.year();

        if (this.calcDateService.isInRangeDate(startDate)) {
            this.quoterRequestError.startDateOutOfRange = false;
            this.quoterRequestError.participatingInsurerDateNeeded = false;
        }
        else {
            this.quoterRequestError.startDateOutOfRange = true;
        }
    }

    validateStreetAddress(): void {
        this.quoterRequestError.streetIsEmpty = false;
        if (this.quoterRequest.StreetAddress === null || this.quoterRequest.StreetAddress === "") {
            this.quoterRequestError.streetIsEmpty = true;
            this.quoterRequestError.street = false;
        } else {
            this.quoterRequestError.streetIsEmpty = false;
            this.quoterRequestError.street = false;
        }
    }

    validateInsurer(): void {
        this.quoterRequestError.ParticipatingInsurerIsEmpty = false;
        if (this.quoterRequest.ParticipatingInsurer === "") {
            this.quoterRequestError.ParticipatingInsurerIsEmpty = true;
        }
    }

    validateRenewal(): void {
        if (this.calcDateService.isRenewPeriod(this.quoterRequest.ActualStartDate)) {
            this.quoterRequestError.renewalNeeded = this.quoterRequest.IsRenewal === null;
        }
        else {
            this.quoterRequestError.renewalNeeded = false;
        }
    }

    validate(): boolean {
        return !this.quoterRequestError.startDateNotSet &&
            !this.quoterRequestError.startDateOutOfRange &&
            !this.quoterRequestError.streetIsEmpty &&
            !this.quoterRequestError.street &&
            !this.quoterRequestError.ParticipatingInsurerIsEmpty &&
            !this.quoterRequestError.zipCodeIsEmptyOrlengthIncorrect &&
            !this.quoterRequestError.AddressStateIsIncorrect &&
            !this.quoterRequestError.startDateOutOfRange &&
            !this.quoterRequestError.startDateNotSet &&
            !this.quoterRequestError.renewalNeeded;
    }

    verifyAndSubmit() {
        this.standardizeStateValue();
        this.startDateTouched = true;

        if (this.quoterRequest.StreetAddress === null) {
            this.quoterRequest.StreetAddress = this.quoterRequestService.getStreetAddress();
        }
        this.quoterRequestService.setSettingForQuestionPage(this.quoterRequest);

        this.validateRenewal();
        this.validateStreetAddress();
        this.validateInsurer();
        this.validateZip();
        this.validateSetStartDate(this.quoterRequest.StartDate);

        if (this.validate()) {
            //final check for zipcode being
            var res = this.validateZipCode(this.quoterRequest.ZipCode, false);

            //if not valid keep on page
            if (res["isError"]) {
              return;
            }

            this.quoterRequestService.saveQuoterRequest(this.quoterRequest);
            if (this.policytype === this.page.mobilehome || this.policytype === this.page.homeowner) {
              this.gotoQuestionPageEvent.emit(this.gotoQuestion);
            } else {
              if (this.policytype === this.page.renter) {
                this.router.navigate(["renter/calculations/2"]);
              } else {
                this.router.navigate(["condo/calculations/3"]);
              }
            }
        }
    }

    getEstimate() {
        this.verifyAndSubmit();
    }

    getAddress(place: object) {
        this.zone.run(() => {
            this.quoterRequest.StreetAddress = this.getStreetNumber(place) + " " + this.getStreet(place);
            this.quoterRequest.City = this.getCity(place);
            this.quoterRequest.AddressState = this.getState(place);
            this.quoterRequest.ZipCode = this.getPostCode(place);
        });
    }

    setAddressError(error) {
        if (error) {
            this.quoterRequest.StreetAddress = "";
            this.quoterRequestError.street = true;
            this.quoterRequestError.streetIsEmpty = false;
        } else {
            this.quoterRequestError.street = false;
            this.quoterRequestError.streetIsEmpty = false;
        }
    }

    setAddressPickedFromBrowser(isAddressFromGoogleApi) {
        this.checkModelValue();
        this.validateZipCode(this.quoterRequest.ZipCode, false);
    }

    public trimZipcode(input: string) {
        let index = this.indexOfFirstDigit(input);
        if (index >= 0) {
            return input.substring(index, 5);
        }

        return '';
    }

    private indexOfFirstDigit(input) {
        let i = 0;
        for (; input[i] < '0' || input[i] > '9'; i++);
        return i == input.length ? -1 : i;
    }


    getAddrComponent(place, componentTemplate) {
        if (place.address_components === undefined) {
            return place;
        }
        let result;
        for (let address of place.address_components) {
            const addressType = address.types[0];
            if (componentTemplate[addressType]) {
                result = address[componentTemplate[addressType]];
                return result;
            }
        }
    }

    getStreetNumber(place) {
        const COMPONENT_TEMPLATE = { street_number: "short_name" },
            streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return streetNumber ? streetNumber : "";
    }

    getStreet(place) {
        const COMPONENT_TEMPLATE = { route: "long_name" },
            street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return street;
    }

    getCity(place) {
        const COMPONENT_TEMPLATE = { locality: "long_name" },
            city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return city;
    }

    getState(place) {
        const COMPONENT_TEMPLATE = { administrative_area_level_1: "short_name" },
            state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return state;
    }

    getPostCode(place) {
        const COMPONENT_TEMPLATE = { postal_code: "long_name" },
            postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return postCode;
    }

    checkModelValue() {
        if (this.quoterRequest.AddressState === null) {
            return false;
        }

        if (this.quoterRequest.AddressState.trim() == null || this.quoterRequest.AddressState.trim() === "") {
            this.quoterRequestError.AddressStateIsIncorrect = false;
        } else if (this.quoterRequest.AddressState.trim() !== null || this.quoterRequest.AddressState.trim() !== "") {
            this.standardizeStateValue();
            if (this.quoterRequest.AddressState.trim() !== "CA") {
                this.quoterRequestError.AddressStateIsIncorrect = true;
            } else {
                this.quoterRequestError.AddressStateIsIncorrect = false;
            }
        }
    }

    standardizeStateValue() {
        let state = this.quoterRequest.AddressState;
        if (state && state !== "CA") {
            let stateLowerAndTrimmed = state.trim().toLowerCase();
            if (stateLowerAndTrimmed === "ca" || stateLowerAndTrimmed.toLowerCase() === "calif" || stateLowerAndTrimmed.toLowerCase() === "california") {
                this.quoterRequest.AddressState = "CA";
            }
        }
    }

    validateZipCode(zipcode, languageChange) {
        if (zipcode !== undefined && zipcode !== null) {
            this.quoterRequest.ZipCode = this.trimZipcode(zipcode);
        }
        if (languageChange && zipcode && zipcode.trim() === '') {
            return false;
        }
        if (!this.quoterRequest.ActualStartDate) {
            return;
        }

        if (zipcode.trim() === '') {
            this.quoterRequestError.zipCodeIsEmptyOrlengthIncorrect = true;
            return;
        }
        else {
            this.quoterRequestError.zipCodeIsEmptyOrlengthIncorrect = false;
        }

        const zipcodeValidation = this.zipCodeValidatorService.validateZipCodes(this.quoterRequest.ZipCode, this.quoterRequest.ActualStartDate);
        this.quoterRequestError.enterValidZipCode = zipcodeValidation.isError;
        if (this.quoterRequestError.enterValidZipCode) {
            this.quoterRequestError.zipCodeIsEmptyOrlengthIncorrect = false;
        }
        this.quoterRequestError.zipCodeIsEmptyShowLink = zipcodeValidation.showLinkError;
        this.zipCodeErrorMessage = zipcodeValidation.errorMessage;

        return !this.quoterRequestError.zipCodeIsEmptyShowLink && !this.quoterRequestError.zipCodeIsEmptyOrlengthIncorrect;
    }

    validateZip(): void {
        this.quoterRequestError.zipCodeIsEmptyOrlengthIncorrect = false;

        if (this.quoterRequest.ZipCode === null) {
            this.quoterRequestError.zipCodeIsEmptyOrlengthIncorrect = true;
        }
        if (this.quoterRequestError.enterValidZipCode) {
            this.quoterRequestError.zipCodeIsEmptyOrlengthIncorrect = true;
        }
    }

    public languageChanged() {
        this.validPiNames = this.piValidatorsService.GetPossiblePiValues();
        if (this.quoterRequest.ParticipatingInsurer === 'Other' ||
            this.quoterRequest.ParticipatingInsurer === 'Otra' ||
            this.quoterRequest.ParticipatingInsurer === '其他') {
            this.translate.stream('GLOBAL.OTHER').subscribe((text: string) => {
                this.quoterRequest.ParticipatingInsurer = text;
            });
        }

        this.validateZipCode(this.quoterRequest.ZipCode, true);

        this.dateRangeErrorMessage = this.calcDateService.getErrorMessage();
    }
}
