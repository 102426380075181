import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        MatTreeModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatIconModule,
        MatInputModule,
        MatSnackBarModule,
        MatDialogModule,
    ],
    exports: [
        MatTreeModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatIconModule,
        MatInputModule,
        MatSnackBarModule,
        MatDialogModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppMaterialComponentsModule { }
