export class Estimate {
    PropertyAddress: string;
    Address2: string;
    City: string;
    State: string;
    ZipCode: string;
    FullAddress: string;

    PolicyType: number;
    PolicyName: string;
    PolicyDate: Date;
    PolicyEffectiveDate: string;
    IsChoice: boolean;
    ReferringSite: string;
    ResidentialInsuranceCompany: string;
    IsFrfMode: boolean;

    BreakablesCoverage: boolean;
    BreakablesCoverageReadable: string;
    BuildingPropertyCoverage: number;
    BuildingPropertyCoverageAmount: string;
    BuildingPropertyDeductible: number;
    BuildingPropertyDeductibleAmount: string;
    BuildingPropertyDeductiblePercent: string;

    IsDefault: number;
    IsFairMarketValueGreaterThan135K: boolean;
    LossAssessmentCoverage: number;
    LossAssessmentCoverageAmount: string;
    LossAssessmentDeductible: number;
    LossAssessmentDeductibleAmount: string;
    LossAssessmentDeductiblePercent: string;
    LossOfUseCoverage?: number;
    LossOfUseCoverageAmount: string;
    PersonalPropertyCoverage?: number;
    PersonalPropertyCoverageAmount: string;
    PersonalPropertyDeductible?: number;
    PersonalPropertyDeductiblePercent: string;
    PersonalPropertyDeductibleAmount: string; 

    BuildingCodeUpgradeLimitIncrease: number;
    BuildingCodeUpgradeLimitIncreaseAmount: string;
    HasEarthquakeResistantBracingSystem: boolean;
    HasEarthquakeResistantBracingSystemReadable: string;
    YearBuilt: number;
    InsuredValueOfHome: number;
    InsuredValueOfHomeAmount: string;
    DwellingDeductibleAmount: string;
    DwellingDeductiblePercent: string;
    MasonryVeneerCoverage: boolean;
    MasonryVeneerCoverageReadable: string;
    AreCrippleWallsBraced: boolean | string;
    Construction: number;  //aka is IsWoodFrame
    ConstructionReadable: string;
    FoundationType: number;
    FoundationTypeName: string;
    HasCrippleWalls: string;
    IsHomeSecuredToFoundation: boolean;
    IsWaterHeaterSecuredToFrame: boolean;
    RoofType: string;
    RoofTypeName: string;
    NumberOfStories: number;
    NumberOfStoriesReadable: string;
    VerifiedByEngineer: boolean;
    HasBracingSystem: boolean;

    HrdStatus: string;
    HrdStatusReadable: string;
    HrdQualified: boolean;
    HrdDiscount: number;
    HrdDiscountReadable: string;
    HrdQualifiedReadable: string;

    Position?: number;
    YesCompare?: boolean;
    Comparing?: boolean;
    AnnualPremium: number;
    AnnualPremiumAmount: string;
    MonthlyPremium: number;
    MonthlyPremiumAmount: string;
    // WantsMasonryCoverage: boolean;
}
