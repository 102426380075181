<div class="cea-page" id="cea-page-1" style="padding-top: 0px;">
    <div class="cea-main-view">
        <form name="theForm" novalidate class="general-form">
            <div class="row cea-form">
                <div class="cea-col-sm-12 cea-col-md-12 offset-md-0 offset-sm-0">
                    <div id="form-container">
                        <ng-container *ngIf="policytype !== page.mobilehome">
                            <label for="yearbuilt" class="cea-visible-xxs-block" trackVisibility (visibile)="mobileVisible($event, 0)">{{'HOMEOWNERS.QUESTIONS.YEAR_BUILT_PLACEHOLDER' | translate }}</label>
                            <div class="form-group required">
                                <input id="yearbuilt" name="yearbuilt" type="text"
                                       placeholder="{{'HOMEOWNERS.QUESTIONS.YEAR_BUILT_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}"
                                       class="form-control" (keyup)="checkBuildYear($event)"
                                       attr.aria-label="{{'HOMEOWNERS.QUESTIONS.YEAR_BUILT_PLACEHOLDER' | translate}}"
                                       [(ngModel)]="quoterRequest.YearBuilt" required numbersOnly>
                            </div>
                            <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                                 *ngIf="quoterRequestError.YearBuiltIsEmpty">{{yearBuiltisEmptyMessage}}</div>
                            <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                                 *ngIf="quoterRequestError.YearBuiltIsOutsideOfRange">{{yearBuiltErrorMessage}}</div>

                            <div class="form-group required">
                                <label for="insuredvalue" class="cea-visible-xxs-block">{{'HOMEOWNERS.QUESTIONS.INSURED_VALUE_PLACEHOLDER' | translate }}</label>
                                <div class="input-group">
                                    <input id="insuredvalue" name="insuredvalue" type="text"
                                           placeholder="{{'HOMEOWNERS.QUESTIONS.INSURED_VALUE_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}"
                                           class="form-control" [(ngModel)]="quoterRequest.InsuredValue" required
                                           (keyup)="checkInsuredValue($event)"
                                           attr.aria-label="{{'HOMEOWNERS.QUESTIONS.INSURED_VALUE_PLACEHOLDER' | translate}}"
                                           numbersOnly />
                                    <span class="input-group-btn">
                                        <app-cea-help-button [stype]="'input'"
                                                             [title]="'HOMEOWNERS.QUESTIONS.HELP.INSURED_VALUE.TITLE'"
                                                             [text1]="'HOMEOWNERS.QUESTIONS.HELP.INSURED_VALUE.TEXT'"
                                                             (moveTop)="moveTop()">
                                        </app-cea-help-button>
                                    </span>
                                </div>
                            </div>
                            <div class="cea-hidden-xss alert alert-danger" *ngIf="quoterRequestError.InsuredValueIsEmpty"
                                 [innerHTML]="'ERRORS.COMPLETE_REQUIRED_FIELDS' | translate"></div>
                            <div class="cea-hidden-xss alert alert-danger" *ngIf="quoterRequestError.InsuredValueOutOfRange"
                                 [innerHTML]="'ERRORS.VALID_INSURED_VALUES' | translate"></div>
                            <div class="cea-hidden-xss alert alert-danger" *ngIf="quoterRequestError.InsuredValueOutOfRangeHigh"
                                 [innerHTML]="'ERRORS.VALID_INSURED_MAX_EXCEEDED' | translate"></div>


                            <div class="form-group required cea-hidden-xss cea-hidden-xxs cea-hidden-xs">
                                
                                <div class="input-group">
                                    <div class="drop-down-wrapper">
                                        <div class="drop-down-arrow">
                                            <select id="numberofstories" name="numberofstories" class="form-control"
                                                    [(ngModel)]="quoterRequest.numberOfStories" required
                                                    attr.aria-label="{{'HOMEOWNERS.QUESTIONS.STORIES_PLACEHOLDER' | translate}}"
                                                    (change)="checkForHRD()" style="padding-left: 8px">
                                                <option [ngValue]="-1" disabled *ngIf="!isRff2025()">
                                                    {{'HOMEOWNERS.QUESTIONS.STORIES_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}
                                                </option>
                                                <option [ngValue]="-1" disabled *ngIf="isRff2025()">
                                                    {{'HOMEOWNERS.QUESTIONS.STORIES_PLACEHOLDER_RFF2025' | ceaPlaceholder: !isMobileView | translate }}
                                                </option>
                                                <option title="{{'HOMEOWNERS.QUESTIONS.STORIES.STORIES_SINGLE' | translate }}"
                                                        [ngValue]="0">
                                                    {{'HOMEOWNERS.QUESTIONS.STORIES.STORIES_SINGLE' | translate }}
                                                </option>
                                                <option title="{{'HOMEOWNERS.QUESTIONS.STORIES.STORIES_GREATER_THAN_ONE' | translate }}"
                                                        [ngValue]="1">
                                                    {{'HOMEOWNERS.QUESTIONS.STORIES.STORIES_GREATER_THAN_ONE' | translate }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <span class="input-group-btn">
                                        <app-cea-help-button *ngIf="!isRff2025()"
                                                             [stype]="'input'"
                                                             [title]="'HOMEOWNERS.QUESTIONS.HELP.NUMBER_OF_STORIES.TITLE'"
                                                             [text1]="'HOMEOWNERS.QUESTIONS.HELP.NUMBER_OF_STORIES.TEXT'"
                                                             (moveTop)="moveTop()">
                                        </app-cea-help-button>
                                        <app-cea-help-button *ngIf="isRff2025()"
                                                             [stype]="'input'"
                                                             [title]="'HOMEOWNERS.QUESTIONS.HELP.NUMBER_OF_STORIES.TITLE'"
                                                             [text1]="'HOMEOWNERS.QUESTIONS.HELP.NUMBER_OF_STORIES.TEXT_RFF2025'"
                                                             (moveTop)="moveTop()">
                                        </app-cea-help-button>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group required cea-visible-xss cea-visible-xxs cea-visible-xs">
                                <label for="numberofstories" class="cea-visible-xxs-block" trackVisibility (visibile)="mobileVisible($event, 1)">{{'HOMEOWNERS.QUESTIONS.STORIES_MOBILE_PLACEHOLDER' | translate }}</label>
                                <div class="input-group">
                                    <div class="drop-down-wrapper">
                                        <div class="drop-down-arrow">
                                            <select id="numberofstories" name="numberofstories" class="form-control"
                                                    [(ngModel)]="quoterRequest.numberOfStories" required
                                                    attr.aria-label="{{'HOMEOWNERS.QUESTIONS.STORIES_MOBILE_PLACEHOLDER' | translate}}"
                                                    (change)="checkForHRD()" style="padding-left: 8px">
                                                <option [ngValue]="-1" disabled>
                                                    {{'HOMEOWNERS.QUESTIONS.STORIES_MOBILE_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}
                                                </option>
                                                <option title="{{'HOMEOWNERS.QUESTIONS.STORIES.STORIES_SINGLE' | translate }}"
                                                        [ngValue]="0">
                                                    {{'HOMEOWNERS.QUESTIONS.STORIES.STORIES_SINGLE' | translate }}
                                                </option>
                                                <option title="{{'HOMEOWNERS.QUESTIONS.STORIES.STORIES_GREATER_THAN_ONE' | translate }}"
                                                        [ngValue]="1">
                                                    {{'HOMEOWNERS.QUESTIONS.STORIES.STORIES_GREATER_THAN_ONE' | translate }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <span class="input-group-btn">
                                        <app-cea-help-button *ngIf="!isRff2025()"
                                                             [stype]="'input'"
                                                             [title]="'HOMEOWNERS.QUESTIONS.HELP.NUMBER_OF_STORIES.TITLE'"
                                                             [text1]="'HOMEOWNERS.QUESTIONS.HELP.NUMBER_OF_STORIES.TEXT'"
                                                             (moveTop)="moveTop()">
                                        </app-cea-help-button>
                                        <app-cea-help-button *ngIf="isRff2025()"
                                                             [stype]="'input'"
                                                             [title]="'HOMEOWNERS.QUESTIONS.HELP.NUMBER_OF_STORIES.TITLE'"
                                                             [text1]="'HOMEOWNERS.QUESTIONS.HELP.NUMBER_OF_STORIES.TEXT_RFF2025'"
                                                             (moveTop)="moveTop()">
                                        </app-cea-help-button>
                                    </span>
                                </div>
                            </div>
                            <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                                 *ngIf="quoterRequestError.numberOfStoriesIsNotSelected"
                                 [innerHTML]="'ERRORS.COMPLETE_REQUIRED_FIELDS' | translate"></div>

                            <div class="form-group required">
                                <label for="foundationtype" class="cea-visible-xxs-block">{{'HOMEOWNERS.QUESTIONS.FOUNDATION_PLACEHOLDER' | translate }}</label>
                                <div class="input-group">
                                    <div class="drop-down-wrapper">
                                        <div class="drop-down-arrow">
                                            <select id="foundationtype" name="foundationtype" class="form-control"
                                                    (change)="checkForHRD()"
                                                    [(ngModel)]="quoterRequest.foundationTypeVariable" required
                                                    style="padding-left: 8px"
                                                    attr.aria-label="{{'HOMEOWNERS.QUESTIONS.FOUNDATION_PLACEHOLDER' | translate}}"
                                                    (ngModelChange)="checkForHRD($event, 'foundationTypeVariable')">
                                                <option [ngValue]="-1" disabled>
                                                    {{'HOMEOWNERS.QUESTIONS.FOUNDATION_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}
                                                </option>
                                                <option *ngFor="let item of foundationTypeVariableOptions; let i = index"
                                                        [ngValue]="i">
                                                    {{ item}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <span class="input-group-btn">
                                        <app-cea-help-button [stype]="'input'"
                                                             [title]="'HOMEOWNERS.QUESTIONS.HELP.FOUNDATION.TITLE'"
                                                             [text1]="'HOMEOWNERS.QUESTIONS.HELP.FOUNDATION.TEXT'"
                                                             [text2]="'HOMEOWNERS.QUESTIONS.HELP.FOUNDATION.IMAGE'"
                                                             (moveTop)="moveTop()">
                                        </app-cea-help-button>
                                    </span>
                                </div>
                            </div>
                            <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                                 [innerHTML]="'ERRORS.COMPLETE_REQUIRED_FIELDS' | translate"
                                 *ngIf="quoterRequestError.foundationTypeVariableIsNotSelected"></div>


                            <div class="form-group required"
                                 *ngIf="quoteApplicationSettings.showRoofDropdown">
                                <label for="rooftype" class="cea-visible-xxs-block" trackVisibility (visibile)="mobileVisible($event, 2)">{{'HOMEOWNERS.QUESTIONS.ROOF_TYPE_PLACEHOLDER' | translate }}</label>
                                <div class="input-group">
                                    <div class="drop-down-wrapper">
                                        <div class="drop-down-arrow">
                                            <select id="rooftype" name="rooftype" class="form-control"
                                                    (change)="checkForHRD()" [(ngModel)]="quoterRequest.roofTypeVariable"
                                                    attr.aria-label="{{'HOMEOWNERS.QUESTIONS.ROOF_TYPE_PLACEHOLDER' | translate}}"
                                                    required style="padding-left: 8px">
                                                <option disabled [ngValue]="-1">
                                                    {{'HOMEOWNERS.QUESTIONS.ROOF_TYPE_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}
                                                </option>
                                                <option *ngFor="let item of roofTypeVariableOptions; let i = index"
                                                        [ngValue]="i">
                                                    {{ item}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <span class="input-group-btn">
                                        <app-cea-help-button *ngIf="!isRff2025()"
                                                             [stype]="'input'"
                                                             [title]="'HOMEOWNERS.QUESTIONS.HELP.ROOF_TYPE.TITLE'"
                                                             [text1]="'HOMEOWNERS.QUESTIONS.HELP.ROOF_TYPE.TEXT'"
                                                             (moveTop)="moveTop()">
                                        </app-cea-help-button>
                                        <app-cea-help-button *ngIf="isRff2025()"
                                                             [stype]="'input'"
                                                             [title]="'HOMEOWNERS.QUESTIONS.HELP.ROOF_TYPE.TITLE'"
                                                             [text1]="'HOMEOWNERS.QUESTIONS.HELP.ROOF_TYPE.TEXT_RFF2025'"
                                                             (moveTop)="moveTop()">
                                        </app-cea-help-button>
                                    </span>
                                </div>
                            </div>
                            <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                                 *ngIf="quoterRequestError.roofTypeVariableIsNotSelected && quoteApplicationSettings.showRoofDropdown"
                                 [innerHTML]="'ERRORS.COMPLETE_REQUIRED_FIELDS' | translate"></div>

                            <div style="padding: 3px 4px 8px 15px;"
                                 class="cea-top-margin20 cea-bottom-margin20 cea-radio-row-adjustment radio-group-collapse form-group">
                                <div class="cea-radio-row">
                                    <div class="cea-radio-labels">
                                        <label class="cea-visible-lg cea-visible-md cea-visible-sm cea-hidden-xs cea-hidden-xss cea-hidden-xxs">{{'HOMEOWNERS.QUESTIONS.FRAME_CONSTRUCTION' | translate }}</label>
                                        <label class="cea-visible-xss cea-visible-xxs cea-visible-xs cea-hidden-lg cea-hidden-md cea-hidden-sm">{{'HOMEOWNERS.QUESTIONS.FRAME_CONSTRUCTION_MOBILE' | translate }}</label>
                                    </div>
                                    <div class="cea-radio-buttons">
                                        <div class="radio-group-accessories">
                                            <app-cea-help-button [stype]="'radio'"
                                                                 [title]="'HOMEOWNERS.QUESTIONS.HELP.WOODFRAME.TITLE'"
                                                                 [text1]="'HOMEOWNERS.QUESTIONS.HELP.WOODFRAME.TEXT'"
                                                                 (moveTop)="moveTop()">
                                            </app-cea-help-button>
                                            <label class="radio-inline button-caption">
                                                <input name="radioGroupIsWoodFrame" id="radioTrue_IsWoodFrame"
                                                       [(ngModel)]="quoterRequest.IsWoodFrame" [value]="true" type="radio"
                                                       checked (change)="checkForHRD()">{{'GLOBAL.YES' | translate }}
                                            </label>
                                            <label class="radio-inline button-caption no-caption">
                                                <input name="radioGroupIsWoodFrame" id="radioFalse_IsWoodFrame"
                                                       [(ngModel)]="quoterRequest.IsWoodFrame" [value]="false" type="radio"
                                                       (change)="checkForHRD()">{{'GLOBAL.NO' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group cea-radio-row-adjustment" style="padding: 3px 4px 8px 15px;"
                                 *ngIf="showHrdDiscount">
                                <br />
                                <h5 *ngIf="!isHrdOptions()">{{'HOMEOWNERS.HRD.OVERVIEW' | translate }}</h5>
                                <h5 *ngIf="isHrdOptions()">{{'HOMEOWNERS.HRD.OVERVIEW_1M_UNDER' | translate }}</h5>
                                <hr />
                                <p *ngIf="quoterRequest.StartYear >= 2016">
                                    {{'HOMEOWNERS.HRD.FEATURES_TEXT' | translate }}
                                </p>
                                <ol>
                                    <li *ngIf="quoterRequest.StartYear >= 2016">
                                        {{'HOMEOWNERS.HRD.FEATURE_1' | translate }}
                                        <strong>{{'HOMEOWNERS.HRD.AND' | translate }}</strong>
                                    </li>
                                    <li *ngIf="quoterRequest.StartYear >= 2016">
                                        {{'HOMEOWNERS.HRD.FEATURE_2' | translate }}
                                        <strong>{{'HOMEOWNERS.HRD.AND' | translate }}</strong>
                                    </li>
                                    <li *ngIf="quoterRequest.StartYear >= 2016">
                                        <p style="display: inline">{{'HOMEOWNERS.HRD.FEATURE_3' | translate }}</p>
                                        <app-cea-help-button [stype]="'radio'"
                                                             [title]="'HOMEOWNERS.HELP.HRD_CRIPPLE.TITLE'"
                                                             [text1]="'HOMEOWNERS.HELP.HRD_CRIPPLE.TEXT'"
                                                             [text2]="'HOMEOWNERS.HELP.HRD_CRIPPLE.IMAGE'"
                                                             (moveTop)="moveTop()">
                                        </app-cea-help-button>

                                        <p *ngIf="quoterRequest.StartYear >= 2016">
                                            <strong>{{'HOMEOWNERS.HRD.FEATURE_3_OR' | translate }}</strong>
                                        </p>
                                        <p>
                                            {{'HOMEOWNERS.HRD.FEATURE_3A' | translate }}
                                        </p>
                                    </li>
                                </ol>

                                <hr />

                                <div class="cea-radio-row">
                                    <div class="cea-radio-col">
                                        <label for="hrdYes" id="hrdYes.label" style="width:100%">
                                            <input id="hrdYes" name="hrdYes" type="radio" [value]="true"
                                                   [(ngModel)]="quoterRequest.HrdRequirementsConfirmed" />
                                            {{'HOMEOWNERS.HRD.RESPONSE_1' | translate }}
                                        </label>
                                        <label for="hrdNo">
                                            <input id="hrdNo" name="hrdNo" type="radio" checked="checked"
                                                   [value]="false" [(ngModel)]="quoterRequest.HrdRequirementsConfirmed">
                                            {{'HOMEOWNERS.HRD.RESPONSE_2' | translate }}
                                        </label>
                                    </div>
                                    <div class="cea-radio-help">
                                        <div class="radio-group-accessories" style="float:right;">

                                            <app-cea-help-button *ngIf="isHrdOptions()"
                                                                 [stype]="'radio'"
                                                                 [title]="'HOMEOWNERS.HELP.HRD_1M_UNDER.TITLE'"
                                                                 [text1]="'HOMEOWNERS.HELP.HRD_1M_UNDER.TEXT'"
                                                                 (moveTop)="moveTop()">
                                            </app-cea-help-button>

                                            <app-cea-help-button *ngIf="!isHrdOptions()"
                                                                 [stype]="'radio'"
                                                                 [title]="'HOMEOWNERS.HELP.HRD.TITLE'"
                                                                 [text1]="'HOMEOWNERS.HELP.HRD.TEXT'"
                                                                 (moveTop)="moveTop()">
                                            </app-cea-help-button>
                                        </div>
                                    </div>
                                </div>


                                <div style="padding: 3px 4px 8px 15px;" *ngIf="quoterRequest.HrdRequirementsConfirmed"
                                     class="cea-top-margin20 cea-bottom-margin20 cea-radio-row-adjustment radio-group-collapse form-group">
                                    <div class="cea-radio-row">
                                        <div class="cea-radio-labels">
                                            <label>{{'HOMEOWNERS.HRD.VERIFIED_BY_ENGINEER' | translate }}</label>
                                        </div>
                                        <div class="cea-radio-buttons">
                                            <div class="radio-group-accessories">
                                                <app-cea-help-button [stype]="'radio'"
                                                                     [title]="'HOMEOWNERS.QUESTIONS.HELP.HRD_VERIFICATION.TITLE'"
                                                                     [text1]="'HOMEOWNERS.QUESTIONS.HELP.HRD_VERIFICATION.TEXT'"
                                                                     (moveTop)="moveTop()">
                                                </app-cea-help-button>

                                                <label class="radio-inline button-caption">
                                                    <input name="radioGroupHrdRequirementsVerifiedByEngineer" id="radioTrue_HrdRequirementsVerifiedByEngineer"
                                                           [(ngModel)]="quoterRequest.HrdRequirementsVerifiedByEngineer"
                                                           [value]="true" type="radio" checked
                                                           (change)="checkForHRD()">{{'GLOBAL.YES' | translate }}
                                                </label>
                                                <label class="radio-inline button-caption no-caption">
                                                    <input name="radioGroupHrdRequirementsVerifiedByEngineer" id="radioFalse_HrdRequirementsVerifiedByEngineer"
                                                           [(ngModel)]="quoterRequest.HrdRequirementsVerifiedByEngineer"
                                                           [value]="false" type="radio"
                                                           (change)="checkForHRD()">{{'GLOBAL.NO' | translate }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="policytype === page.mobilehome">
                            <div class="form-group required" *ngIf="quoteApplicationSettings.showYearBuilt">
                                <label for="yearbuilt" class="cea-visible-xxs-block" trackVisibility (visibile)="mobileVisible($event, 0)">{{'MOBILEHOME.YEAR_BUILT_PLACEHOLDER' | translate }}</label>
                                <input id="yearbuilt" name="yearbuilt" type="text"
                                       placeholder="{{'MOBILEHOME.YEAR_BUILT_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}"
                                       class="form-control" [(ngModel)]="quoterRequest.YearBuilt" (keyup)="checkBuildYear($event)"
                                       attr.aria-label="{{'MOBILEHOME.YEAR_BUILT_PLACEHOLDER' | translate}}"
                                       (ngModelChange)="checkForHRD($event, 'yearBuild')" required numbersOnly>
                            </div>
                            <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                                *ngIf="quoterRequestError.YearBuiltIsEmpty && quoteApplicationSettings.showYearBuilt"
                                [innerHTML]="yearBuiltisEmptyMobileErrorMessage"></div>
                            <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                                *ngIf="quoterRequestError.YearBuiltIsOutsideOfRange && quoteApplicationSettings.showYearBuilt"
                                [innerHTML]="yearBuiltMobileErrorMessage"></div>

                            <div class="form-group required">
                                <label for="insuredvalue" class="cea-visible-xxs-block" trackVisibility (visibile)="mobileVisible($event, 1)">{{'HOMEOWNERS.QUESTIONS.INSURED_VALUE_PLACEHOLDER' | translate }}</label>
                                <div class="input-group">
                                    <input id="insuredvalue" name="insuredvalue" type="text"
                                           placeholder="{{'HOMEOWNERS.QUESTIONS.INSURED_VALUE_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}"
                                           class="form-control" [(ngModel)]="quoterRequest.InsuredValue" required
                                           attr.aria-label="{{'HOMEOWNERS.QUESTIONS.INSURED_VALUE_PLACEHOLDER' | translate}}"
                                           (ngModelChange)="checkForHRD($event, 'InsuredValue')" (keyup)="checkInsuredValue($event)" required numbersOnly />
                                    <span class="input-group-btn">
                                        <app-cea-help-button [stype]="'input'"
                                                             [title]="'HOMEOWNERS.QUESTIONS.HELP.INSURED_VALUE.TITLE'"
                                                             [text1]="'HOMEOWNERS.QUESTIONS.HELP.INSURED_VALUE.TEXT'"
                                                             (moveTop)="moveTop()">
                                        </app-cea-help-button>
                                    </span>
                                </div>
                            </div>
                            <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                                *ngIf="quoterRequestError.InsuredValueIsEmpty"
                                [innerHTML]="'ERRORS.COMPLETE_REQUIRED_FIELDS' | translate"></div>
                            <div class="cea-hidden-xss ng-scope ng-isolate-scope alert alert-danger"
                                *ngIf="quoterRequestError.InsuredValueOutOfRange"
                                [innerHTML]="'ERRORS.VALID_INSURED_VALUES' | translate"></div>
                            <div class="cea-hidden-xss alert alert-danger" *ngIf="quoterRequestError.InsuredValueOutOfRangeHigh"
                                [innerHTML]="'ERRORS.VALID_INSURED_MAX_EXCEEDED' | translate"></div>

                            <div style="padding: 3px 4px 8px 15px"
                                class="cea-top-margin20 cea-bottom-margin20 cea-radio-row-adjustment radio-group-collapse form-group">
                                <div class="cea-radio-row">
                                    <div class="cea-radio-labels">
                                        <label>{{'MOBILEHOME.QUESTIONS.HAS_ERBS_QUESTION' | translate}}</label>
                                    </div>
                                    <div class="cea-radio-buttons">
                                        <div class="radio-group-accessories">
                                            <app-cea-help-button [stype]="'radio'"
                                                                 [title]="'MOBILEHOME.QUESTIONS.HELP.ERBS_QUESTION.TITLE'"
                                                                 [text1]="'MOBILEHOME.QUESTIONS.HELP.ERBS_QUESTION.TEXT'"
                                                                 (moveTop)="moveTop()">
                                            </app-cea-help-button>

                                            <label class="radio-inline button-caption">
                                                <input [name]="'radioGroupHasBracingSystem' + groupkey" id="radioTrue_HasBracingSystem"
                                                       [(ngModel)]="quoterRequest.HasBracingSystem" [value]="true"
                                                       type="radio">{{'GLOBAL.YES' | translate }}
                                            </label>
                                            <label class="radio-inline button-caption no-caption">
                                                <input [name]="'radioGroupHasBracingSystem' + groupkey" id="radioFalse_HasBracingSystem"
                                                       [(ngModel)]="quoterRequest.HasBracingSystem" [value]="false"
                                                       type="radio">{{'GLOBAL.NO' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <ng-container *ngIf="quoteApplicationSettings.showHasInspection">
                                <div style="padding: 3px 4px 8px 15px;" *ngIf="quoterRequest.HasBracingSystem"
                                    class="cea-top-margin20 cea-bottom-margin20 cea-radio-row-adjustment radio-group-collapse form-group">
                                    <div class="cea-radio-row">
                                        <div class="cea-radio-labels">
                                            <label
                                                [innerHTML]="'MOBILEHOME.QUESTIONS.HAS_INSPECTION_QUESTION' | translate"></label>

                                        </div>
                                        <div class="cea-radio-buttons">
                                            <div class="radio-group-accessories">
                                                <app-cea-help-button [stype]="'radio'"
                                                                     [title]="'MOBILEHOME.QUESTIONS.HELP.ERBS_QUESTION.TITLE'"
                                                                     [text1]="'MOBILEHOME.QUESTIONS.HELP.ERBS_QUESTION.TEXT'"
                                                                     (moveTop)="moveTop()">
                                                </app-cea-help-button>

                                                <label class="radio-inline button-caption">
                                                    <input [name]="'radioGroup' + groupkey" id="radioTrue_HasInspection"
                                                           [(ngModel)]="quoterRequest.HasInspection" [value]="true"
                                                           type="radio">{{'GLOBAL.YES' | translate }}
                                                </label>
                                                <label class="radio-inline button-caption no-caption">
                                                    <input [name]="'radioGroup' + groupkey" id="radioFalse_HasInspection"
                                                           [(ngModel)]="quoterRequest.HasInspection" [value]="false"
                                                           type="radio">{{'GLOBAL.NO' | translate }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                        <div class="cea-radio-row-adjustment radio-group-collapse form-group submitBtn">
                            <br />


                            <div class="width-50">
                                <button class="btn btn-primary btn-block" (click)="goBack()" id="questions-back-btn">
                                    <em class="fas fa-arrow-left"></em>
                                    {{'GLOBAL.BACK_BUTTON' | translate }}
                                </button>
                            </div>
                            <div class="width-50">
                                <button (click)="submitForm()" class="btn btn-block btn-primary single-button" id="questions-get-estimate-btn"
                                    style="min-width:160px">{{'HOMEOWNERS.QUESTIONS.GET_ESTIMATE_BUTTON' | translate }}</button>
                            </div>
                        </div>


                    </div>
                    <!--form-container-->
                </div>
            </div>
        </form>
    </div>
</div>
