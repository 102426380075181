import { Injectable } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import axios from "axios";
import moment from "moment";
import { environment } from "../../../../environments/environment";
import { CalculatorSettingsService } from "../calculatorsettings/calculator-settings.service";

export const QUOTER_CONSUMER_2019_STARTDATE = "1/1/2021";
export const QUOTER_PROFESSIONAL_2019_STARTDATE = "1/1/2020";
export const QUOTER_FRF_STARTDATE = "8/1/2023";
export const QUOTER_FRF_PREVIEW_DATE = "10/31/2023";
export const QUOTER_RFF_2025_DATE = "1/1/2025";

export const I18N_MONTH_VALUES = {
    'chi': {
        months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    },
    'en': {
        months: ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    },
    'es': {
        months: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    },
    'zh': {
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    }
};

@Injectable({
    providedIn: "root"
})
export class CalcDatesService {
    isInsuranceProfessional: boolean;
    currentDate: Date;
    rffGoLiveDate: Date;
    services: number[] = [2019, 2021, 2022, 2023, 2024, 2025, 2026];

    constructor(private calculatorSettingsService: CalculatorSettingsService,
        private translate: TranslateService) {
        this.isInsuranceProfessional = this.calculatorSettingsService.participatingInsurerSettings.isInsuranceProfessional;
        this.currentDate = moment().toDate();

        //set default dates
        this.formulateDates();

        this.queryForYear();
    }

    formulateDates(): { CurrentYear: number, MaxStartDate: number } {
        if (window["CurrentDate"]) {
            this.currentDate = new Date(window["CurrentDate"]);
        }
        if (window["RffGoLiveDate"]) {
            this.rffGoLiveDate = new Date(window["RffGoLiveDate"]);
        }

        //for quick developer testing, can insert values here
        const activeServices = this.getServices(this.currentDate, this.isInsuranceProfessional, this.rffGoLiveDate);

        return { CurrentYear: activeServices[0], MaxStartDate: activeServices[activeServices.length - 1] };
    }

    queryForYear() {
        let timerurl = environment.apiUrl + environment.timerurl;
        if (window["spaSourceUrl"]) {
            timerurl = window["spaSourceUrl"] + environment.timerurl;
        }

        return new Promise((resolve, reject) => {
            axios({
                method: "get",
                url: timerurl,
                headers: {
                    "Content-Type": "application/json"
                },
            }).then((res) => {
                this.currentDate = res.data[0] ? new Date(res.data[0]) : moment().toDate();
                if (res.data[1] && res.data[1] != null) {
                    this.rffGoLiveDate = new Date(res.data[1]);
                }
            }).catch(error => {
                //will use default values commented
            });
        });
    }


    isRenewPeriod(dt: Date | moment.Moment) {
        if (!dt) {
            return false;
        }

        const dtm = moment(dt, "MM/DD/YYYY");
        if (dtm >= moment(QUOTER_FRF_STARTDATE, "MM/DD/YYYY")) {
            return true;
        }

        return false;
    }

    isFrfMode(dt: Date | moment.Moment, isRenewal: boolean | null) {
        if (!dt) {
            return false;
        }

        const dtm = dt.toISOString ? dt : moment(dt, "YYYY MM DD");
        if (!isRenewal) {
            return dtm >= moment(QUOTER_FRF_STARTDATE);
        }
        else {
            return dtm >= moment(QUOTER_FRF_PREVIEW_DATE).add(1, 'd');
        }
    }

    toDate(dt: Date | moment.Moment): Date {
        let cdt = moment.isMoment(dt) ? dt.toDate() : dt instanceof Date ? dt : new Date(dt);
        cdt.setHours(0, 0, 0, 0);

        return cdt;
    }

    refreshSettings() {
        this.isInsuranceProfessional = this.calculatorSettingsService.participatingInsurerSettings.isInsuranceProfessional;
        this.formulateDates();
    }

    getMinStartDate() {
        const dates = this.formulateDates();
        const startEnv = this.getServiceVariables(dates.CurrentYear);
        const dt = moment(startEnv.startDate, "MM/DD/YYYY");
        return { year: dt.year(), month: dt.month() + 1, day: dt.date() };
    }

    getMaxStartDate() {
        const dates = this.formulateDates();
        const endEnv = this.getServiceVariables(dates.MaxStartDate);
        const dt = moment(endEnv.endDate, "MM/DD/YYYY");
        return { year: dt.year(), month: dt.month() + 1, day: dt.date() };
    }

    parseStartDate(dtInput: NgbDateStruct | string) {
        let startDate = moment();

        if (typeof dtInput === 'string' || dtInput instanceof String) {
            startDate = null;
        } else if (dtInput) {
            startDate.month(dtInput.month - 1);
            startDate.date(dtInput.day);
            startDate.year(dtInput.year);
            startDate.hour(0);
            startDate.minute(0);
            startDate.second(0);
        } else {
            startDate = null;
        }

        return startDate;
    }

    isValidDate(dt: moment.Moment) {
        return dt?.isValid()
    }

    isInRangeDate(dt: moment.Moment) {
        const dates = this.formulateDates();
        let startEnv = this.getServiceVariables(dates.CurrentYear);
        let endEnv = this.getServiceVariables(dates.MaxStartDate);

        if (dt > moment(startEnv.startDate, "MM/DD/YYYY") && dt <= moment(endEnv.endDate, "MM/DD/YYYY").add(1, 'd')) {
            return true;
        }

        return false;
    }

    getErrorMessage() {
        const dates = this.formulateDates();
        const startEnv = this.getServiceVariables(dates.CurrentYear);
        const endEnv = this.getServiceVariables(dates.MaxStartDate);

        let sdt = moment(startEnv.startDate, "MM/DD/YYYY");
        let edt = moment(endEnv.endDate, "MM/DD/YYYY");
        const lang = this.getLanguage();
        const stMonth = I18N_MONTH_VALUES[lang].months[sdt.month()];
        const edMonth = I18N_MONTH_VALUES[lang].months[edt.month()];

        return this.translate.instant("ERRORS.ROLLING_VALID_POLICY_START_DATE")
            .replace("[START_MONTH]", stMonth)
            .replace("[END_MONTH]", edMonth)
            .replace("[START_DAY]", sdt.date().toString())
            .replace("[END_DAY]", edt.date().toString())
            .replace("[START_YEAR]", sdt.year().toString())
            .replace("[END_YEAR]", edt.year().toString());
    }

    getMaxYearBuilt() {
        const dates = this.formulateDates();
        const endEnv = this.getServiceVariables(dates.MaxStartDate);

        let dt = moment(endEnv.endDate, "MM/DD/YYYY");

        return dt.year();
    }

    getService(dt: any) {
        let dtm = moment(dt, "MM/DD/YYYY");

        return this.getServiceVariables(dtm.year());
    }

    getServiceVariables(year: number) {
        let name = "quoter" + year.toString() + "Service";
        let env = environment[name];

        return env;
    }

    getServices(dt: Date, isInsuranceProfessional: boolean, rffOverideDate: Date): number[] {
        let lastIndex = this.getLatestServiceIndex(dt);
        lastIndex = this.getOverrideIndex(lastIndex, dt, rffOverideDate);
        let firstIndex = lastIndex - 2;
        if (firstIndex < 0) {
            firstIndex = 0;
        }

        //will return an array with quoterService years ex. [2019, 2022, 2023]
        let ret = [];
        for (let i = firstIndex; i <= lastIndex; i++) {
            ret.push(this.services[i]);
        }

        return ret;
    }

    getLatestServiceIndex(dt: Date) {
        //check all services and return the last service year enabled
        for (let i = 0; i < this.services.length; i++) {
            let service = this.getServiceVariables(this.services[i]);
            if (dt.getTime() < new Date(service.dateToEnableRates).getTime()) {
                return (i > 0) ? (i - 1) : 0;
            }
        }

        return this.services.length - 1;
    }

    getOverrideIndex(index: number, dt: Date, rffOverrideDate: Date) {
        if (!rffOverrideDate || index + 1 >= this.services.length - 1) {
            return index;
        }

        if (dt.getTime() >= rffOverrideDate.getTime()) {
            return index + 1;
        }

        return index;
    }

    getMode() {
        if (this.isInsuranceProfessional) {
            return "Agent";
        }

        return "Consumer";
    }

    getLanguage() {
        let lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang
        if (lang === "kv") {
            lang = "en";
        }

        return lang;
    }

    isRff2025(dt) {
        const dtm = dt.toISOString ? dt : moment(dt, "YYYY MM DD");
        const rffDtm = moment(QUOTER_RFF_2025_DATE, "MM/DD/YYYY");

        return dtm.isAfter(rffDtm);
    }
}
