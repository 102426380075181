import { Directive, EventEmitter, Output, ElementRef, NgZone, Input } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

declare let google: any;

@Directive({
    selector: '[ebbGoogleAutocomplete]'
})
export class GoogleAutoCompleteDirective  {
    @Output() whenSelected: EventEmitter<any> = new EventEmitter<any>();
    @Input() public value: any;

    private element: HTMLInputElement;
    private autoCompleteService: any;

    constructor(
        private elRef: ElementRef,
        public zone: NgZone
    ) {
        this.element = this.elRef.nativeElement;

        fromEvent(this.element, 'focus').subscribe(val => {
            window['angularGoogleAutocompleteDirectiveRef'] = { component: this, zone: this.zone };
        });

        fromEvent(this.element, 'input').pipe(debounceTime(600)).subscribe(val => {
            if (this.element.value.length >= 5 && this.element === document.activeElement) {
                this.getPrediction(this.element.value);
            }
            if (this.element.value.length < 4) {
                this.getPrediction('');
            }
        });

        fromEvent(this.element, 'focusout').pipe(debounceTime(300)).subscribe(val => {
            this.whenSelected.emit([]);
        })
    }

    public getPrediction(value: string): void {
        if (!this.isServiceAvailable()) {
            return;
        }
        if (value) {
            const bounds = new google.maps.LatLngBounds(new google.maps.LatLng(32.342841356393016, -124.727783203125), new google.maps.LatLng(42.01665183556825, -114.05868530273438));
            const autocompleteRequest = {
                input: value, locationRestriction: bounds, types: ['address'],
                componentRestrictions: { country: 'US' }
            };
            this.autoCompleteService.getQueryPredictions(autocompleteRequest, this.autoCompleteCallBack);
        } else {
            this.whenSelected.emit([]);
        }
    }

    public autoCompleteCallBack(predictions: any, status: any): void {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
            console.log(status);
            return;
        }

        window['angularGoogleAutocompleteDirectiveRef'].zone.run(() => {
            window['angularGoogleAutocompleteDirectiveRef']
                .component.whenSelected.emit(predictions);
        });
    }

    //way to handle google maps library loading async
    //quick way for now to avoid typescript implementation and reference errors
    //https://developers.google.com/maps/documentation/javascript/overview#js_api_loader_package
    public isServiceAvailable() {
        if (this.autoCompleteService) {
            return true;
        }

        if (google.maps.places) {
            this.autoCompleteService = new google.maps.places.AutocompleteService();
            return true;
        }

        return false;
    }
}
