import { EventEmitter, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AnalyticsTrackingService } from '../tracking/tracking.service';
import moment from "moment";

@Injectable({
    providedIn: "root"
})
export class TranslationService {
    // tslint:disable-next-line:variable-name
    private _currentLanguage = "en";

    public onLangChange: EventEmitter<any>

    constructor(
        private translate: TranslateService,
        private tracking: AnalyticsTrackingService
    ) {
        this.onLangChange = new EventEmitter<any>()
    }

    get currentLanguage(): string {
        return this._currentLanguage;
    }

    useLanguage(selectedLanguage: string) {
        this._currentLanguage = selectedLanguage;
        this.tracking.eventEmitter(selectedLanguage, 'Language', 'Translate');

        this.translate.use(selectedLanguage).subscribe(x => {
            this.onLangChange.emit(x);
        });
    }

    whichTranslation(cutDt, evalDt, before: string, after: string) {
        const cutDtm = cutDt.toISOString ? cutDt : moment(cutDt, "MM/DD/YYYY");
        const evalDtm = evalDt.toISOString ? evalDt : moment(evalDt, "MM/DD/YYYY");

        return evalDtm < cutDtm ? before : after;
    }
}
