import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import { ComponentsRoutingModule } from "./components-routing.module";
import { PopupComponent } from "./popup/popup.component";
import { GeneralComponent } from "./general/general.component";
import { QuestionsComponent } from "./questions/questions.component";
import { SharedModule } from "../shared/shared.module";
import { FormsModule } from "@angular/forms";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { AutocompleteComponent } from "./autocomplete/autocomplete.component";
import { NumberDirective } from "../Directives/numbers-only.directive";
import { CeaSliderComponent } from "./cea-slider/cea-slider.component";
import { CeaHelpButtonComponent } from './cea-help-button/cea-help-button.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CeaRadioGroupComponent } from "./cea-radio-group/cea-radio-group.component";
import { EmailPopupComponent } from "./email-popup/email-popup.component";
import { PolicyDiffComponent } from './policy-diff/policy-diff.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { GoogleAutoCompleteDirective } from '../Directives/google-auto-complete.directive';
import { TrackVisibilityDirective } from '../Directives/track-visibility.directive';
import { CeaPlaceholderPipe } from "../shared/pipes/cea-placeholder/cea-placeholder.pipe";

@NgModule({
    declarations: [PopupComponent, EmailPopupComponent,
        GeneralComponent,
        QuestionsComponent,
        AutocompleteComponent,
        NumberDirective,
        CeaSliderComponent,
        CeaRadioGroupComponent,
        CeaHelpButtonComponent,
        PolicyDiffComponent,
        ConfirmComponent,
        GoogleAutoCompleteDirective,
        TrackVisibilityDirective,
        CeaPlaceholderPipe],
    exports: [
        GeneralComponent, EmailPopupComponent,
        QuestionsComponent,
        PopupComponent,
        CeaSliderComponent,
        CeaRadioGroupComponent,
        CeaHelpButtonComponent,
        PolicyDiffComponent
    ],
    imports: [
        CommonModule,
        ComponentsRoutingModule,
        SharedModule,
        FormsModule,
        NgbDatepickerModule,
        NgxSliderModule
    ]
})
export class ComponentsModule {
}
