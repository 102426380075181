import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";
import { DownloadFilePostModel } from "../../models/Download.model";
import axios from "axios";
import * as FS from "file-saver";
import { Email } from "../../models/emailModel.model";
import { ErrorMessageService } from "../error-message/error-message.service";

@Injectable({
    providedIn: "root"
})
export class PrintoutService {

    constructor(private errorMessage: ErrorMessageService, private translate: TranslateService) {

    }

    reportUrl() {
        //local testing
        //console.log('print url');
        //return environment.urireport;

        let url = '';
        if (window["spaSourceUrl"]) {
            url = window["spaSourceUrl"] + environment.urireport;
        } else {
            url = environment.apiUrl + environment.urireport;
        }

        return url;
    }

    downloadFile(downloadParam: DownloadFilePostModel) {
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url: this.reportUrl(),
                data: downloadParam,
                responseType: "arraybuffer",
                headers: {
                    "ocp-apim-subscription-key": environment.urireportkey,
                    "Content-Type": "application/json"
                },
            }).then((response) => {
                const file = new Blob([response.data], { type: "application/pdf" });

                const fileURL = URL.createObjectURL(file);
                const nav = (window.navigator as any);
                if (nav.msSaveOrOpenBlob) {
                    nav.msSaveOrOpenBlob(file, 'estimate.pdf');
                }
                else if (window.navigator.userAgent.match('CriOS') || (window.navigator.userAgent.match(/Chrome/i) && window.navigator.userAgent.match(/Mobile/i))) { //Chrome iOS
                    window.open(fileURL, "_blank");
                }
                else {
                    FS.saveAs(file, "estimate.pdf");
                }
            }).catch(error => {
                this.errorMessage.showErrorMessage(error);
                reject(error);
            });
        });
    }

    printAndDownloadPdf(downloadParam: DownloadFilePostModel, operation) {
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url: this.reportUrl(),
                data: downloadParam,
                responseType: "arraybuffer",
                headers: {
                    "ocp-apim-subscription-key": environment.urireportkey,
                    "Content-Type": "application/json"
                },
            }).then((response) => {
                const file = new Blob([response.data], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(file);


                if (window.navigator.userAgent.match('CriOS') || (window.navigator.userAgent.match(/Chrome/i) && window.navigator.userAgent.match(/Mobile/i))) { //Chrome iOS
                    window.open(fileURL, "_blank");
                }
                else {
                    FS.saveAs(file, "estimate.pdf");
                }
                

            }).catch(error => {
                this.errorMessage.showErrorMessage(error);
                reject(error);
            });
        });
    }

    email(email: Email, cPdfParam: DownloadFilePostModel) {
        let Someone1 = email.to;
        if (Someone1 === "") {
            Someone1 = 'Someone';
        }
        axios({
            method: "post",
            url: this.reportUrl(),
            data: cPdfParam,
            responseType: "arraybuffer",
            headers: {
                "ocp-apim-subscription-key": environment.urireportkey,
                "Content-Type": "application/json"
            },
        }).then((response) => {
            const attachments = [{
                filename: "cea-estimate.pdf",
                content: this._arrayBufferToBase64(response.data),
                encoding: "base64"
            }];
            const subjectString = this.translate.instant("EMAIL.SUBJECT");
            const content1String = this.translate.instant("EMAIL.CONTENT1");
            const content2String = this.translate.instant("EMAIL.CONTENT2");
            const content3String = this.translate.instant("EMAIL.CONTENT3");

            const emailRequest = {
                fromName: Someone1,
                to: email.sendTo.split(","),
                subject: subjectString,
                body: "<p>" + email.first + " " + email.last + " " + content1String + "</p>" +
                    (email.message ? "<p>" + content2String + ":<br>" + email.message + "</p>" : "") +
                    "<div>" + content3String + "</div>",
                attachments
            };

            let emailurl = environment.apiUrl + environment.emailurl;
            if (window["spaSourceUrl"]) {
                emailurl = window["spaSourceUrl"] + environment.emailurl;
            }

            return new Promise((resolve, reject) => {
                axios({
                    method: "post",
                    url: emailurl,
                    data: emailRequest,
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then();
            });
        }).catch(error => {
            this.errorMessage.showErrorMessage(error);
        });
    }

    _arrayBufferToBase64(buffer) {
        let binary = "";
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}
