export const environment = {
    analytics: "UA-18062737-4",
    quoterApi: "https://quoter-dv.earthquakeauthority.com/api/Quote/",
    quoterPort: 443,
    homeownerEndpoint: "CalculateQuoteHome",
    renterEndpoint: "CalculateQuoteRenter",
    condoEndpoint: "CalculateQuoteCondo",
    mobileEndpoint: "CalculateQuoteMobile",
    ocp_apim_subscription_key: "7aa6c44221184a5fad91cc425ddec270",
    apiUrl: "https://calc-dev.earthquakeauthority.com",
    urireport: "/api/Pdf/quote",
    urireportkey: "9603ab5ac5524f2aa8b3d31011b9c9d0",
    emailurl: "/api/email",
    zipcodeurl: "https://quoter-dv.earthquakeauthority.com/api/ZipCodes/detail",
    timerurl: "/api/timer",
    translationurl: "/api/translation",
    languageurl: "/api/Language/Get?language=",
    clientId: "0362a0a8-f084-4d03-8649-efe08f1750e4",
    authority: "https://login.microsoftonline.com/9b6ce1d3-1230-48ac-a353-0c58a56eaf66",
    redirectUri: "https://calc-dev.earthquakeauthority.com/admin",
    protectedurl: "/api/translation",
    protectedapi: "api://53bb347e-d1b7-4da6-bda4-8c749bbb75ed/translation.access",
    eqasiteurl: "https://dv.earthquakeauthority.com",
    participatinginsurersurl: "/California-Earthquake-Insurance-Policies/Participating-Residential-Insurers-Earthquake",
    browsersupporturl: "/california-earthquake-insurance-policies/earthquake-insurance-premium-calculator/browser-compatibility",
    Grantsurl: "/California-Earthquake-Insurance-Policies/Earthquake-Insurance-Policy-Premium-Discounts/Brace-and-Bolt-Seismic-Retrofit-Grants",
    riskpreparednessurl: "/Prepare-Your-House-Earthquake-Risk",
    quoter2026Service: {
        startDate: "1/1/2025",
        endDate: "12/31/2025",
        dateToEnableRates: "1/1/2025",
        version: "V6"
    },
    quoter2025Service: {
        startDate: "8/1/2024",
        endDate: "12/31/2025",
        dateToEnableRates: "8/1/2024",
        version: "V6"
    },
    quoter2024Service: {
        startDate: "9/1/2024",
        endDate: "12/31/2024",
        dateToEnableRates: "3/26/2024",
        version: "V5",
    },
    quoter2023Service: {
        startDate: "11/1/2023",
        endDate: "12/31/2024",
        dateToEnableRates: "1/1/2023",
        version: "V5"
    },
    quoter2022Service: {
        dateToEnable2022Rates: "1/1/2022",
        startDate: "1/1/2022",
        endDate: "3/31/2023",
        dateToEnableRates: "1/1/2022",
        version: "V5",
    },
    quoter2021Service: {
        startDate: "1/1/2021",
        endDate: "3/31/2022",
        dateToEnableRates: "1/1/2021",
        version: "V4"
    },
    quoter2019Service: {
        dateToEnable2020Rates: "1/1/2019",
        dateToEnable2021Rates: "1/1/2019",
        startDate: "7/1/2020",
        endDate: "12/31/2020",
        dateToEnableRates: "5/1/2018",
        version: "V4"
    },
    quoter2016Service: {
        startDate: "1/1/2016",
        endDate: "6/30/2019",
        dateToEnableRates: "1/1/2016",
        version: "V3"
    }
}
