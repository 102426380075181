import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ImageService {

  ImageLoc(relativeUrl): string {
    if (window["spaSourceUrl"]) {
      if (relativeUrl.charAt(0) === '.') {
        relativeUrl = relativeUrl.substring(1);
      }
      return window["spaSourceUrl"] + relativeUrl;
    }

    return relativeUrl;
  }

  HelpImageLoc(text: string): string {
    if (window["spaSourceUrl"]) {
      let base = window["spaSourceUrl"].toString();
      if (base.substring(base.length - 1, 1) !== "/") {
        base = base + "/";
      }
      text = text.replace(/\.\/assets/g, base + "assets");
    }

    return text;
  }
}
