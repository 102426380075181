import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalculatorSettingsService } from '../calculatorsettings/calculator-settings.service';
import { QuoterRequest2Service } from '../quote-request/quote-request2.service';

declare let gtag: Function;

@Injectable({
    providedIn: "root"
})
export class AnalyticsTrackingService {
    constructor(private calcSettingService: CalculatorSettingsService,
        private translate: TranslateService,
        private quoterRequestService: QuoterRequest2Service    ) {

    }

    public eventEmitter(label, category, action) {
        if (!gtag) {
            console.log("tracking event function not found");
            return;
        }
        /*
        console.info("Analytics Event Triggered: " + action +
            " (Category: " + category + ", Label: " + label + ")");
        */
        gtag('event', action, {
            'event_category': category,
            'event_label': label
        });
    }

    public trackLocationChange(pathName: string) {
        const path = this.scrubPath(pathName);
        const lang = this.getLanguage();
        let eventText = lang + " + " + path;

        if (this.isChoice()) {
            eventText = eventText + " + choice";
        }

        if (this.isPro()) {
            eventText = eventText + " + pro";
        }

        this.eventEmitter("location", "User Behavior", eventText);
    }

    public scrubPath(pathName: string): string {
        if (pathName.indexOf("?") > 0) {
            pathName = pathName.substring(0, pathName.indexOf("?"));
        }
            
        if (pathName.startsWith("/")) {
            pathName = pathName.substring(1); //strip / start
        }
            
        if (pathName.endsWith("/")) {
            pathName = pathName.substring(0, pathName.length - 1); //strip / end
        }
            
        if (pathName.length > 2 && pathName.substring(pathName.length - 2, 2) == "/4") {
            pathName = pathName.substring(0, pathName.length - 2); //strip /4 end
        }
            
        if (pathName.length > 2 && pathName.substring(pathName.length - 2, 2) == "/3") {
            pathName = pathName.substring(0, pathName.length - 2); //strip /3 end
        }
            
        if (pathName.length > 2 && pathName.substring(pathName.length - 2, 2) == "/2") {
            pathName = pathName.substring(0, pathName.length - 2); //strip /2 end
        }
            
        if (pathName.length > 2 && pathName.substring(pathName.length - 2, 2) == "/1") {
            pathName = pathName.substring(0, pathName.length - 2); //strip /1 end
        }    

        pathName = pathName.replace("/", "."); //replace / with .
        if (pathName === "") {
            pathName = "start"; //root is start
        }

        return pathName;
    }

    private getLanguage(): string {
        return this.translate.currentLang ? this.translate.currentLang : "en";
    }

    private isChoice(): boolean {
        const quotes = this.quoterRequestService.getSavedQuotes();
        if (quotes) {
            for (const quote of quotes) {
                if (quote.quoterResponse?.PolicyType && quote.quoterResponse.PolicyType.indexOf("Choice") > 0) {
                    return true;
                }
            }
        }

        return false;
    }

    private isPro(): boolean {
        return this.calcSettingService.IsAgentOrPro();
    }
}
