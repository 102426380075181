import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InlineSVGModule } from "ng-inline-svg-2";
import { AppMainRoutingModule } from "./app-main-routing.module";
import { HomeownerComponent } from "./homeowner/homeowner.component";
import { MobilehomeComponent } from "./mobilehome/mobilehome.component";
import { CondoComponent } from "./condo/condo.component";
import { RenterComponent } from "./renter/renter.component";
import {ComponentsModule} from "../components/components.module";
import {SharedModule} from "../shared/shared.module";
import { HomeComponent } from "./home/home.component";
import { CalculationsComponent } from "./calculations/calculations.component";
import { NgxGaugeModule } from "ngx-gauge";
import { CompareComponent } from './compare/compare.component';



@NgModule({
  declarations: [HomeownerComponent, MobilehomeComponent, CondoComponent, RenterComponent, HomeComponent, CalculationsComponent, CompareComponent],
  imports: [
    CommonModule,
    AppMainRoutingModule,
    InlineSVGModule.forRoot(),
    SharedModule,
    ComponentsModule,
    NgxGaugeModule
  ],
})
export class AppMainModule { }
