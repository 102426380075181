import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: "root"
})
export class ErrorMessageService {
    constructor(private translate: TranslateService, private modalService: NgbModal) {

    }

    showErrorMessage(error) {
        let title = 'ERRORS.FAILURE_TO_CONNECT.TITLE';
        let msg = 'ERRORS.FAILURE_TO_CONNECT.TEXT';

        if (error.status === 429) {
            title = 'ERRORS.FAILURE_TOO_MANY_REQUESTS.TITLE';
            msg = 'ERRORS.FAILURE_TOO_MANY_REQUESTS.TEXT';
        }

        const data = {
            header: this.translate.instant(title),
            body: this.translate.instant(msg),
            showTopCloseButton: false
        };
        const modalRef = this.modalService.open(PopupComponent,
            {
                scrollable: false,
                windowClass: "myCustomModalClass",
                size: 'xl'
            });
        modalRef.componentInstance.fromParent = data;
        modalRef.result.then();
    }

    public moveTop() {
        let top = 1000000;
        const maxScroll = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        ) - window.innerHeight;

        // Fix for bug on iOS devices
        // When top was larger than maximum page scroll
        // "getBoundingClientRect" would take that value into calculations
        if (top > maxScroll) {
            top = maxScroll;
        }

        // Scroll the window to the new position
        window.scrollTo(0, top);

        const Cea = (window as any).Cea;
        if (Cea?.WidgetDetails) {
            if (Cea.WidgetDetails.channelOpen && Cea.WidgetDetails.requestScrollIntoView) {
                Cea.WidgetDetails.requestScrollIntoView();
            }
            else {
                const wrapElement = document.getElementById("wrap");
                if (wrapElement) {
                    window.scroll(0, this.findPos(document.getElementById("wrap")))
                    wrapElement.scrollIntoView(true);
                }
            }
        }
    }

    findPos(obj) {
        let curtop = 0;
        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
            return curtop;
        }
    }

}
