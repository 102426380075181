export class QuoterResponse {
    BuildingPropertyCoverage: number;
    BuildingPropertyDeductible: number;
    LossAssessmentCoverage: number;
    LossAssessmentDeductible: number;
    PolicyType: string;
    PolicyTypeTranslate: string;
    AnnualPremium: number;
    MonthlyPremium: number;
    PersonalPropertyBreakablesCoverageOption: boolean;
    PersonalPropertyCoverage: number;
    PersonalPropertyDeductible: number;
    LossOfUseCoverage: number;
    ZipCode: number;
}
