export class QuoterRequestErrorGeneral {
    startDateOutOfRange = false;
    startDateNotSet = false;
    participatingInsurerDateNeeded = false;
    renewalNeeded = false;
    street = false;
    enterValidZipCode = false;
    ParticipatingInsurerIsEmpty = false;
    streetIsEmpty = false;
    AddressStateIsIncorrect = false;
    zipCodeIsEmptyOrlengthIncorrect = false;
    zipCodeIsEmptyShowLink=false;
}

export class QuoterRequestErrorQuestion {
    YearBuiltIsEmpty = false;
    YearBuiltIsOutsideOfRange = false;
    InsuredValueIsEmpty = false;
    InsuredValueOutOfRange = false;
    InsuredValueOutOfRangeHigh = false;
    foundationTypeVariableIsNotSelected = false;
    roofTypeVariableIsNotSelected = false;
    numberOfStoriesIsNotSelected = false;
}
