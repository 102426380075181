import { Component, NgZone, OnInit, HostListener } from "@angular/core";
import * as Rx from "rxjs";
import { switchMap } from 'rxjs/operators';
import { TranslationService } from "../../shared/services/translation/translation.service";
import { language } from "../../shared/models/language.model";
import { moduleType } from "../../shared/models/module.type";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { QuoterRequest2Service} from "../../shared/services/quote-request/quote-request2.service";
import { QuoterRequest } from "../../shared/models/quoterRequest.model";
import { QuoteApplicationSettings } from "../../shared/models/quoteApplicationSettings.model";
import { QuoterRequestErrorQuestion } from "../../shared/models/quoterRequestError";
import { SliderSettings } from "../../shared/models/slider.model";
import { RadioGroupSettings } from "../../shared/models/radioGroup.model";
import { ActivatedRoute, Router } from "@angular/router";
import { QuoterResponse } from "../../shared/models/QuoterResponse.model";
import { CalcInput } from "../../shared/models/CalcInput.model";
import { DownloadFilePostModel } from "../../shared/models/Download.model";
import { PopupComponent } from "../../components";
import { environment } from 'src/environments/environment';
import { ImageService } from "../../shared/services/images/image.service";
import { AnalyticsTrackingService } from 'src/app/shared/services/tracking/tracking.service';
import { QuoteStorageService } from "../../shared/services/quote-storage/quote-storage.service";
import { SessionService } from "../../shared/services/session/session.service";
import { PrintoutService } from "../../shared/services/printout/printout.service";
import { ErrorMessageService } from "../../shared/services/error-message/error-message.service";
import { PrintBuilderService } from "../../shared/services/print-builder/print-builder.service";
import { EstimateBuilderService } from "../../shared/services/estimate-builder/estimate-builder.service";
import { SlidersConfigService } from "../../shared/services/sliders/sliders-config.service";
import { CalcDatesService } from "../../shared/services/calc-dates/calc-dates.service";

declare let $: any;

@Component({
    selector: "app-calculations",
    templateUrl: "./calculations.component.html",
    styleUrls: ["./calculations.component.scss"]
})
export class CalculationsComponent implements OnInit {
    showGeneralPage: boolean;
    yesCompare;
    estimateReq$ = new Rx.ReplaySubject<CalcInput>();
    constructor(
        public translationService: TranslationService,
        private modalService: NgbModal,
        private router: Router,
        public zone: NgZone,
        private quoterRequestService: QuoterRequest2Service,
        private imageService: ImageService,
        private tracking: AnalyticsTrackingService,
        private quoteStorageService: QuoteStorageService,
        private sessionService: SessionService,
        private printoutService: PrintoutService,
        private estimateBuilderService: EstimateBuilderService,
        private printBuilderService: PrintBuilderService,
        private sliderConfigService: SlidersConfigService,
        private calcDatesService: CalcDatesService,
        private errorMessageService: ErrorMessageService,
        private route: ActivatedRoute) {
        this.quoterRequest = new QuoterRequest();
        this.route.params.subscribe(params => {
            // tslint:disable-next-line:radix
            this.policytype = parseInt(params.moduleType);
        });


        this.route.queryParams
            .subscribe(params => {
                if ((Object.keys(params).length !== 0)) {
                    this.isRefreshPage = false;
                    this.yesCompare = this.parseYesCompare(params.yesCompare);
                    this.quoteStorageService.SaveQuoteCompare(this.yesCompare);                     ;
                }
                else {
                    this.yesCompare = undefined;
                }
            });

        this.estimateReq$.pipe(switchMap(data => {
            if (data) {
                return this.quoterRequestService.GetEstimation(data, this.policytype, "slider");
            }
        })).subscribe(res => {
            this.processEstimateRequest(res)
        });


        if (this.isRefreshPage) {
            this.quoterRequestService.getSavedQuotes();
        }

        this.sessionService.listenUnload(["/homeowner/calculations/1", "/renter/calculations/2", "/condo/calculations/3", "/mobilehome/calculations/4"], function () {
            this.quoteStorageService.SaveState(this.sessionService.getRelativeUrl())
                .SaveCalcInputRequest(this.calcInput)
                .SaveLanguage(this.translationService.currentLanguage);
        }.bind(this));

        this.moveTop('');
    }

    // takes value from localStorage whether to show notice or not.
    showRetrofitNotice = false;
    policytype: number;
    processing = false;
    modules = moduleType;
    lang = language;
    // @ts-ignore
    quoterResponse: QuoterResponse;
    quoterRequest: QuoterRequest;
    zipcode;
    startyear;
    Grantsurl = environment.Grantsurl;
    eqaUrl = environment.eqasiteurl;
    frfMode: boolean;
    requestCount: number;
    quoteApplicationSettings: QuoteApplicationSettings;
    quoterRequestError: QuoterRequestErrorQuestion = new QuoterRequestErrorQuestion();
    BuildingPropertyCoverageVariableSlider: SliderSettings;
    BuildingPropertyDeductibleVariableSlider: SliderSettings;
    BuildingPropertyDeductibleVariableSliderFake: SliderSettings;
    PersonalPropertyCoverageVariableSlider: SliderSettings;
    PersonalPropertyDeductibleVariableSlider: SliderSettings;
    PersonalPropertyDeductibleVariableSliderFake: SliderSettings;
    WantsBreakableCoverageRadioGroup: RadioGroupSettings;
    WantsBreakableCoverageRadioGroupFake: RadioGroupSettings;
    LossOfUseCoverageVariableSlider: SliderSettings;
    LossAssessmentCoverageVariableSlider: SliderSettings;
    LossAssessmentDeductibleVariableSlider: SliderSettings;
    LossAssessmentDeductibleVariableSliderFake: SliderSettings;
    LossOfUseCoverageVariableSliderWithPersonalPropertyDeductibleZero: SliderSettings;
    DwellingDeductibleVariableSlider: SliderSettings;
    BCUCoverageVariableSlider: SliderSettings;
    LossOfUseCoverageVariableSlider1: SliderSettings;
    BuildingCodeUpgradeIncreasedLimitRadioGroup: RadioGroupSettings;
    calcInput: CalcInput = new CalcInput();
    downloadFileRequest: DownloadFilePostModel;
    gaugeValue = 0;
    maxValue = 100;
    showBuildingPropertyDeductibleVariableSliderFake = false;
    showLossOfUseCoverageVariableSlider1 = false;
    showPersonalPropertyDeductibleVariableSliderFake = false;
    showLossAssessmentDeductibleVariableSliderFake = false;
    showWantsBreakableCoverageRadioGroupFake = false;
    refreshLossOfUseCoverageVariableSlider = false;

    refreshPersonalPropertyCoverageVariableSlider = false;
    showError: boolean = false;
    isRefreshPage: boolean = true;
    // tslint:disable-next-line:use-lifecycle-interface

    screenWidth = 0;
    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.screenWidth = window.innerWidth;
    }

    slider: any;

    setSliderValue(value: any, type?: string) {
        if (this.policytype === moduleType.condo) {
            this.validateSliderForCondo(type, value);
        }

        if (this.policytype === moduleType.homeowner) {
            this.validateSliderForHome(type, value);
        }

        if (this.policytype === moduleType.mobilehome) {
            this.validateSliderForMobile(type, value);
        }

        this.calcInput[value.key] = value.value;
        this.calcInput.IsDefault = 0;

        this.zone.run(() => {
            clearTimeout(this.slider);
            this.slider = setTimeout(() => {
                this.calculateQuote(this.calcInput);
                clearTimeout(this.slider);
            }, 500);
        });
    }


    validateSliderForHome(type?: string, value?: any) {
        let comparing = (this.quoteStorageService.GetCompareItem() === 'true');
        if (!comparing) {
            comparing = this.calcInput.comparing === true ? true : false;
        }
        
        if (this.PersonalPropertyCoverageVariableSlider.displayValue === 0) {
            this.showPersonalPropertyDeductibleVariableSliderFake = true;
            this.showWantsBreakableCoverageRadioGroupFake = true;
            this.calcInput.BreakablesCoverage = false;
            this.PersonalPropertyDeductibleVariableSlider.disabled = true;
            this.PersonalPropertyDeductibleVariableSlider.displayValue = (comparing && !this.yesCompare) ? 25 : 0;
        }

        if (value != null && value.key === 'BuildingPropertyDeductible' && this.calcInput.PersonalPropertyDeductible > value.value) {
            this.PersonalPropertyDeductibleVariableSlider.errorMessage = 'ERRORS.PERSONAL_PROPERTY_DEDUCTIBLE';
            this.PersonalPropertyDeductibleVariableSlider.errorMessageReplaceText = value.value;
            this.showError = true;
        } else if (value != null && value.key === 'PersonalPropertyDeductible' && this.calcInput.BuildingPropertyDeductible < value.value) {
            this.PersonalPropertyDeductibleVariableSlider.errorMessage = 'ERRORS.PERSONAL_PROPERTY_DEDUCTIBLE';
            this.PersonalPropertyDeductibleVariableSlider.errorMessageReplaceText = this.calcInput.BuildingPropertyDeductible.toString();
            this.showError = true;
        } else if (!type && this.calcInput.PersonalPropertyDeductible > this.calcInput.BuildingPropertyDeductible) {
            this.PersonalPropertyDeductibleVariableSlider.errorMessage = 'ERRORS.PERSONAL_PROPERTY_DEDUCTIBLE';
            this.PersonalPropertyDeductibleVariableSlider.errorMessageReplaceText = this.calcInput.BuildingPropertyDeductible.toString();
            this.showError = true;
        } else if (type !== 'PersonalPropertyCoverage') {
            this.PersonalPropertyDeductibleVariableSlider.errorMessage = "";
            this.showError = false;
        }


        if (!value && !type && this.calcInput.PersonalPropertyDeductible === 0 && this.calcInput.PersonalPropertyCoverage !== 0) {
            this.showLossOfUseCoverageVariableSlider1 = true;
        }

        if (type === 'PersonalPropertyCoverage') {
            if (value != null && value.key === 'PersonalPropertyCoverage' && value.value === 0) {
                this.showPersonalPropertyDeductibleVariableSliderFake = true;
                this.showWantsBreakableCoverageRadioGroupFake = true;
                this.calcInput.BreakablesCoverage = false;
                this.PersonalPropertyDeductibleVariableSlider.disabled = true;
                this.PersonalPropertyDeductibleVariableSlider.displayValue = 0;
                this.showLossOfUseCoverageVariableSlider1 = false;
                this.PersonalPropertyDeductibleVariableSlider.errorMessage = "";
                this.calcInput.PersonalPropertyDeductible = (comparing && !this.yesCompare) ? 25 : 0;
                this.PersonalPropertyDeductibleVariableSliderFake.displayValue = (comparing && !this.yesCompare) ? 25 : 0;
                this.PersonalPropertyDeductibleVariableSlider.displayValue = (comparing && !this.yesCompare) ? 25 : 0;
                this.LossOfUseCoverageVariableSlider.displayValue = this.calcInput.LossOfUseCoverage;
            } else {
                this.showPersonalPropertyDeductibleVariableSliderFake = false;
                this.showWantsBreakableCoverageRadioGroupFake = false;
                if (!this.yesCompare) {
                    this.PersonalPropertyDeductibleVariableSlider.disabled = false;
                }

                if (this.calcInput.PersonalPropertyCoverage === 0) {
                    this.PersonalPropertyDeductibleVariableSlider.displayValue = 15;
                    this.calcInput.PersonalPropertyDeductible = 15;
                    this.LossOfUseCoverageVariableSlider1.displayValue = this.calcInput.LossOfUseCoverage;
                }
            }
        }

        if (type === 'PersonalPropertyDeductible') {
            if (value != null && value.key === 'PersonalPropertyDeductible' && value.value === 0) {
                this.showLossOfUseCoverageVariableSlider1 = true;
                this.PersonalPropertyDeductibleVariableSlider.errorMessage = "";
                this.calcInput.PersonalPropertyDeductible = 0;
                this.PersonalPropertyDeductibleVariableSlider.displayValue = 0;
                this.LossOfUseCoverageVariableSlider1.displayValue = this.calcInput.LossOfUseCoverage === 0 ? 15000 : this.calcInput.LossOfUseCoverage;
                this.calcInput.LossOfUseCoverage = this.LossOfUseCoverageVariableSlider1.displayValue;
            } else {
                if (this.PersonalPropertyDeductibleVariableSlider.displayValue === 0) {
                    this.showLossOfUseCoverageVariableSlider1 = false;
                    this.LossOfUseCoverageVariableSlider.displayValue = this.calcInput.LossOfUseCoverage === 0 ? 15000 : this.calcInput.LossOfUseCoverage;
                    this.calcInput.LossOfUseCoverage = this.LossOfUseCoverageVariableSlider.displayValue;
                }
                this.calcInput.PersonalPropertyDeductible = value.value;
                this.PersonalPropertyDeductibleVariableSlider.displayValue = value.value;
            }
            if (this.calcInput.PersonalPropertyCoverage === 0) {
                this.showWantsBreakableCoverageRadioGroupFake = true;
                this.calcInput.BreakablesCoverage = false;
                this.PersonalPropertyDeductibleVariableSlider.disabled = true;
            } else {
                this.showWantsBreakableCoverageRadioGroupFake = false;
                this.PersonalPropertyDeductibleVariableSlider.disabled = false;
            }
        }

        if (type === 'PersonalPropertyCoverage' && (this.calcInput.PersonalPropertyDeductible > this.calcInput.BuildingPropertyDeductible)) {
            this.PersonalPropertyDeductibleVariableSlider.errorMessage = 'ERRORS.PERSONAL_PROPERTY_DEDUCTIBLE';
            this.PersonalPropertyDeductibleVariableSlider.errorMessageReplaceText = this.calcInput.BuildingPropertyDeductible.toString();
            this.showError = true;
        } else if (type === 'PersonalPropertyCoverage') {
            this.PersonalPropertyDeductibleVariableSlider.errorMessage = '';
            this.showError = false;
        }

        if (this.PersonalPropertyDeductibleVariableSlider.disabled) {
            this.showError = false;
        }
    }

    validateSliderForMobile(type?: string, value?: any) {
        this.validateSliderForHome(type, value);
    }

    validateSliderForCondo(type?: string, value?: any) {
        if (!type && !value) {
            if (this.PersonalPropertyCoverageVariableSlider.displayValue == 0 &&
                this.calcInput.PersonalPropertyCoverage == 0) {
                type = 'PersonalProperty';
                value = { value: '0', key: 'PersonalPropertyCoverage' };
            } else {
                this.PersonalPropertyDeductibleVariableSlider.displayValue = this.calcInput.PersonalPropertyDeductible;
                this.PersonalPropertyDeductibleVariableSliderFake.displayValue = this.calcInput.PersonalPropertyDeductible;
            }
            if (this.calcInput.BuildingPropertyCoverage === 0) {
                this.BuildingPropertyDeductibleVariableSliderFake.displayValue = 0;
                this.showBuildingPropertyDeductibleVariableSliderFake = true;
            }

            if (this.quoterRequest.IsFairMarketValueOver135k === true) {
                this.calcInput.LossAssessmentCoverage = this.calcInput.LossAssessmentCoverage === 25000 ? 50000 : this.calcInput.LossAssessmentCoverage;
                this.LossAssessmentCoverageVariableSlider.displayValue = this.calcInput.LossAssessmentCoverage;
            }

            if (this.calcInput.LossAssessmentCoverage === 0) {
                this.LossAssessmentCoverageVariableSlider.displayValue = 0;
                this.LossAssessmentDeductibleVariableSliderFake.displayValue = 0;
                this.calcInput.LossAssessmentDeductible = 0;
                this.showLossAssessmentDeductibleVariableSliderFake = true;
            } else {
                this.showLossAssessmentDeductibleVariableSliderFake = false;
            }
        }
        if (type === 'BuildingPropertyCoverage') {
            if (value != null && value.key === 'BuildingPropertyCoverage' && value.value === 0) {
                this.BuildingPropertyDeductibleVariableSliderFake.displayValue = 0;
                this.calcInput.BuildingPropertyDeductible = 0;
                this.showBuildingPropertyDeductibleVariableSliderFake = true;
            } else {
                if (this.calcInput.BuildingPropertyDeductible === 0) {
                    this.BuildingPropertyDeductibleVariableSlider.displayValue = 15;
                    this.calcInput.BuildingPropertyDeductible = 15;
                    this.showBuildingPropertyDeductibleVariableSliderFake = false;
                }
            }
        }
        if (type === 'LossAssessmentCoverage') {
            if (value != null && value.key === 'LossAssessmentCoverage' && value.value === 0) {
                this.LossAssessmentDeductibleVariableSliderFake.displayValue = 0;
                this.calcInput.LossAssessmentDeductible = 0;
                this.showLossAssessmentDeductibleVariableSliderFake = true;
            } else {
                if (this.calcInput.LossAssessmentDeductible === 0) {
                    this.LossAssessmentDeductibleVariableSlider.displayValue = 15;
                    this.calcInput.LossAssessmentDeductible = 15;
                    this.showLossAssessmentDeductibleVariableSliderFake = false;
                }
            }
        }
        if (type === 'PersonalProperty') {
            if (value.key === 'PersonalPropertyCoverage' && value.value == 0) {

                this.LossOfUseCoverageVariableSlider.displayValue = 0;
                this.PersonalPropertyDeductibleVariableSlider.displayValue = 0;
                this.PersonalPropertyDeductibleVariableSliderFake.displayValue = 0;
                this.calcInput.PersonalPropertyDeductible = 0;
                this.calcInput.LossOfUseCoverage = 0;
                this.calcInput.BreakablesCoverage = false;
                this.WantsBreakableCoverageRadioGroupFake.disabled = true;
                this.WantsBreakableCoverageRadioGroup.disabled = true;
            } else {
                if (this.calcInput.PersonalPropertyCoverage == null || this.calcInput.PersonalPropertyCoverage === 0) {
                    this.LossOfUseCoverageVariableSlider.displayValue = 15000;
                    this.calcInput.LossOfUseCoverage = 15000;
                    this.WantsBreakableCoverageRadioGroupFake.disabled = false;
                    this.WantsBreakableCoverageRadioGroup.disabled = false;

                    this.PersonalPropertyDeductibleVariableSlider.displayValue = 15;
                    this.PersonalPropertyDeductibleVariableSliderFake.displayValue = 15;
                    this.calcInput.PersonalPropertyDeductible = 15;

                } else {
                    this.LossOfUseCoverageVariableSlider.displayValue = this.calcInput.LossOfUseCoverage;
                    this.PersonalPropertyDeductibleVariableSlider.displayValue = this.calcInput.PersonalPropertyDeductible;
                    this.PersonalPropertyDeductibleVariableSliderFake.displayValue = this.calcInput.PersonalPropertyDeductible;
                    this.WantsBreakableCoverageRadioGroup.bindModel = this.calcInput.BreakablesCoverage;
                    this.WantsBreakableCoverageRadioGroupFake.bindModel = this.calcInput.BreakablesCoverage;
                }
            }

            const flag2 = !this.refreshLossOfUseCoverageVariableSlider;
            if (value.value === 0 || this.calcInput.PersonalPropertyCoverage === 0 || this.calcInput.PersonalPropertyCoverage == null) {
                const flag = !this.showWantsBreakableCoverageRadioGroupFake;
                this.showPersonalPropertyDeductibleVariableSliderFake = flag;
                this.showWantsBreakableCoverageRadioGroupFake = flag;
            }
            this.refreshLossOfUseCoverageVariableSlider = flag2;
        }

        if (type === 'LossOfUseCoverage') {
            if (value.key === 'LossOfUseCoverage' && value.value === 0) {
                this.PersonalPropertyCoverageVariableSlider.displayValue = 0;
                this.PersonalPropertyDeductibleVariableSlider.displayValue = 0;
                this.PersonalPropertyDeductibleVariableSliderFake.displayValue = 0;
                this.calcInput.PersonalPropertyDeductible = 0;
                this.calcInput.PersonalPropertyCoverage = 0;
                this.calcInput.BreakablesCoverage = false;
                this.WantsBreakableCoverageRadioGroupFake.disabled = true;
                this.WantsBreakableCoverageRadioGroup.disabled = true;
            } else {
                if (this.calcInput.LossOfUseCoverage === 0) {
                    this.PersonalPropertyCoverageVariableSlider.displayValue = 50000;
                    this.calcInput.PersonalPropertyCoverage = 50000;
                    this.PersonalPropertyDeductibleVariableSlider.displayValue = 15;
                    this.PersonalPropertyDeductibleVariableSliderFake.displayValue = 15;
                    this.calcInput.PersonalPropertyDeductible = 15;
                    this.WantsBreakableCoverageRadioGroupFake.disabled = false;
                    this.WantsBreakableCoverageRadioGroup.disabled = false;
                } else {
                    this.PersonalPropertyCoverageVariableSlider.displayValue = this.calcInput.PersonalPropertyCoverage;
                    this.PersonalPropertyDeductibleVariableSlider.displayValue = this.calcInput.PersonalPropertyDeductible;
                    this.PersonalPropertyDeductibleVariableSliderFake.displayValue = this.calcInput.PersonalPropertyDeductible;
                    this.WantsBreakableCoverageRadioGroup.bindModel = this.calcInput.BreakablesCoverage;
                    this.WantsBreakableCoverageRadioGroupFake.bindModel = this.calcInput.BreakablesCoverage;
                }
            }

            const flag2 = !this.refreshPersonalPropertyCoverageVariableSlider;
            this.refreshPersonalPropertyCoverageVariableSlider = flag2;
            if (value.value === 0 || this.calcInput.LossOfUseCoverage === 0) {
                const flag = !this.showWantsBreakableCoverageRadioGroupFake;
                this.showPersonalPropertyDeductibleVariableSliderFake = flag;
                this.showWantsBreakableCoverageRadioGroupFake = flag;
            }

        }
    }

    loadCondoComponents() {
        this.BuildingPropertyCoverageVariableSlider = this.sliderConfigService.getBuildingPropertyCoverage(this.calcInput.BuildingPropertyCoverage,
            "CONDO.CALCULATIONS.BUILDING_PROPERTY_LIMIT",
            "CONDO.CALCULATIONS.BUILDING_PROPERTY_LIMIT",
            "BuildingPropertyCoverage",
            false,
            this.sliderConfigService.getCondoBPCoverageSteps());

        this.BuildingPropertyDeductibleVariableSlider = this.sliderConfigService.getBuildingPropertyDeductible(this.calcInput.BuildingPropertyDeductible,
            false,
            this.sliderConfigService.getCondoBPDSteps(false));
        this.BuildingPropertyDeductibleVariableSliderFake = this.sliderConfigService.getBuildingPropertyDeductible(this.calcInput.BuildingPropertyDeductible,
            true,
            this.sliderConfigService.getCondoBPDSteps(true));
        
        let ppList = this.sliderConfigService.frfPPCoverageOptions(this.sliderConfigService.getCondoPPCoverageSteps(), this.frfMode);
        this.calcInput.PersonalPropertyCoverage = this.defaultPersonalProperty(ppList, this.calcInput.PersonalPropertyCoverage);
        this.PersonalPropertyCoverageVariableSlider = this.sliderConfigService.getPersonalPropertyCoverage(this.calcInput.PersonalPropertyCoverage,
            "CONDO.CALCULATIONS.PERSONAL_PROPERTY_COVERAGE", "CONDO.CALCULATIONS.PERSONAL_PROPERTY_COVERAGE",
            ppList);

        this.PersonalPropertyDeductibleVariableSlider = this.sliderConfigService.getPersonalPropertyDeductible(this.calcInput.PersonalPropertyDeductible,
            "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_2",
            "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_2",
            false,
            "GLOBAL.NONE",
            this.sliderConfigService.getCondoPPDeductible(false));
        this.PersonalPropertyDeductibleVariableSliderFake = this.sliderConfigService.getPersonalPropertyDeductible(this.calcInput.PersonalPropertyDeductible,
            "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_2",
            "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_2",
            true,
            "GLOBAL.NONE",
            this.sliderConfigService.getCondoPPDeductible(true));

        this.WantsBreakableCoverageRadioGroup = this.sliderConfigService.getWantsBreakableCoverage(this.calcInput.BreakablesCoverage, false);
        this.WantsBreakableCoverageRadioGroupFake = this.sliderConfigService.getWantsBreakableCoverage(this.calcInput.BreakablesCoverage, true);
        this.LossOfUseCoverageVariableSlider = this.sliderConfigService.getLossOfUseCoverage(this.calcInput.LossOfUseCoverage,
            this.sliderConfigService.getCondoLUCoverageSteps());
        this.LossAssessmentCoverageVariableSlider = this.sliderConfigService.getLossAssessmentCoverage(
            (this.quoterRequest.IsFairMarketValueOver135k === true && this.calcInput.LossAssessmentCoverage === 25000) ? 50000 : this.calcInput.LossAssessmentCoverage,
            this.sliderConfigService.getCondoSteps(this.quoterRequest.IsFairMarketValueOver135k));

        this.LossAssessmentDeductibleVariableSlider = this.sliderConfigService.getLossAssessmentDeductible(this.calcInput.LossAssessmentDeductible,
            false,
            this.sliderConfigService.getLADSteps(false));
        this.LossAssessmentDeductibleVariableSliderFake = this.sliderConfigService.getLossAssessmentDeductible(this.calcInput.LossAssessmentDeductible,
            true,
            this.sliderConfigService.getLADSteps(true));
    }

    loadRenterComponents() {
        let ppList = this.sliderConfigService.frfPPCoverageOptions(this.sliderConfigService.getRenterPPCoverageSteps(), this.frfMode);
        this.calcInput.PersonalPropertyCoverage = this.defaultPersonalProperty(ppList, this.calcInput.PersonalPropertyCoverage);
        this.PersonalPropertyCoverageVariableSlider = this.sliderConfigService.getPersonalPropertyCoverage(this.calcInput.PersonalPropertyCoverage,
            "CONDO.CALCULATIONS.PERSONAL_PROPERTY_COVERAGE", "CONDO.CALCULATIONS.PERSONAL_PROPERTY_COVERAGE",
            ppList);
        this.PersonalPropertyDeductibleVariableSlider = this.sliderConfigService.getPersonalPropertyDeductible(this.calcInput.PersonalPropertyDeductible,
            "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_2",
            "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_2",
            false,
            "GLOBAL.NO",
            this.sliderConfigService.getCondoPPDeductible(false));
        this.WantsBreakableCoverageRadioGroup = this.sliderConfigService.getWantsBreakableCoverage(this.calcInput.BreakablesCoverage, false);
        this.LossOfUseCoverageVariableSlider = this.sliderConfigService.getLossOfUseCoverage(this.calcInput.LossOfUseCoverage,
            this.sliderConfigService.getRenterLUCoverageSteps());
    }


    private loadHomeOwnerPPD() {
        //weaves through a few special rules related to homeowner sliders
        if (!this.isRefreshPage) {
            if (this.calcInput.yesCompare === false && this.policytype === this.modules.homeowner && !this.calcInput.PersonalPropertyDeductible && this.calcInput.PersonalPropertyCoverage) {
                this.calcInput.PersonalPropertyDeductible = 15;
            }
            else if (this.calcInput.yesCompare === true && this.policytype === this.modules.homeowner && (!this.calcInput.PersonalPropertyCoverage || (this.calcInput.PersonalPropertyDeductible !== 0 && this.calcInput.PersonalPropertyDeductible !== null))) {
                this.calcInput.PersonalPropertyDeductible = 0;
            }
        }
    }

    private loadHomeOwnerComponents() {
        // Creating a new object to delete reference with original object by using JSON technique
        // Resetting the slider value if comparing is enabled
        this.loadHomeOwnerPPD();

        this.calcInput.PersonalPropertyCoverage = (this.calcInput.yesCompare && this.calcInput.PersonalPropertyCoverage === 0) ? 50000 : this.calcInput.PersonalPropertyCoverage;
        this.calcInput.LossOfUseCoverage = this.calcInput.yesCompare && this.calcInput.LossOfUseCoverage == 0 ? 15000 : this.calcInput.LossOfUseCoverage;
        this.calcInput.PersonalPropertyDeductible = this.calcInput.yesCompare ? 0 : this.calcInput.PersonalPropertyDeductible;

        let ddList = this.sliderConfigService.frfPPDeductibleOptions(this.sliderConfigService.getDDSteps(), this.sliderConfigService.frfException(this.frfMode, this.quoterRequest));
        this.calcInput.BuildingPropertyDeductible = this.defaultDwellingDeductible(ddList, this.calcInput.BuildingPropertyDeductible);
        this.DwellingDeductibleVariableSlider = this.sliderConfigService.getDwellingDeductible(this.calcInput.BuildingPropertyDeductible,
            ddList,
            this.sliderConfigService.frfException(this.frfMode, this.quoterRequest),
            this.sliderConfigService.frfOver1Million(this.quoterRequest));
        this.BuildingPropertyCoverageVariableSlider = this.sliderConfigService.getBuildingPropertyCoverage(this.calcInput.BuildingCodeUpgradeLimitIncrease,
            "HOMEOWNERS.CALCULATIONS.BUILDING_COVERAGE",
            "HOMEOWNERS.CALCULATIONS.BUILDING_COVERAGE_MOBILE",
            "BuildingCodeUpgradeLimitIncrease",
            false,
            this.sliderConfigService.getHomeownerBPCoverageSteps());
        this.BuildingCodeUpgradeIncreasedLimitRadioGroup = this.sliderConfigService.getBuildingCodeUpgradeIncreasedLimit(this.calcInput.MasonryVeneerCoverage);
        let ppList = this.sliderConfigService.frfPPCoverageOptions(this.sliderConfigService.getHomeownerPPCoverageSteps(this.calcInput.yesCompare), this.frfMode);
        this.calcInput.PersonalPropertyCoverage = this.defaultPersonalProperty(ppList, this.calcInput.PersonalPropertyCoverage);
        this.PersonalPropertyCoverageVariableSlider = this.sliderConfigService.getPersonalPropertyCoverage(this.calcInput.PersonalPropertyCoverage,
            "HOMEOWNERS.CALCULATIONS.PERSONAL_PROPERTY_COVERAGE", "HOMEOWNERS.CALCULATIONS.PERSONAL_PROPERTY_COVERAGE_MOBILE",
            ppList);
        this.PersonalPropertyDeductibleVariableSlider = this.sliderConfigService.getPersonalPropertyDeductible(this.calcInput.PersonalPropertyDeductible,
            (this.quoterRequest.IsInsuranceProfessional && this.quoterRequest.IsChoiceRequest ? "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_2" : "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE"),
            (this.quoterRequest.IsInsuranceProfessional && this.quoterRequest.IsChoiceRequest ? "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_MOBILE_PRO" : "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_MOBILE"),
            this.calcInput.yesCompare,
            "GLOBAL.NO",
            this.sliderConfigService.getHomeownerPPDeductibleSteps(this.calcInput.yesCompare)
            //this.sliderConfigService.getHomeownerPPDeductibleSteps(this.sliderConfigService.isChoice(this.calcInput.PersonalPropertyDeductible, this.calcInput.PersonalPropertyCoverage))
        );
        this.PersonalPropertyDeductibleVariableSliderFake = this.sliderConfigService.getPersonalPropertyDeductible(this.calcInput.PersonalPropertyDeductible,
            (this.quoterRequest.IsInsuranceProfessional && this.quoterRequest.IsChoiceRequest ? "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_2" : "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE"),
            (this.quoterRequest.IsInsuranceProfessional && this.quoterRequest.IsChoiceRequest ? "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_MOBILE_PRO" : "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_MOBILE"),
            true,
            "GLOBAL.NO",
            this.sliderConfigService.getHomeownerPPDeductibleSteps(this.calcInput.yesCompare)
            //this.sliderConfigService.getHomeownerPPDeductibleSteps(this.sliderConfigService.isChoice(this.calcInput.PersonalPropertyDeductible, this.calcInput.PersonalPropertyCoverage))
        );


        if (this.showGeneralPage && this.policytype === this.modules.homeowner && this.calcInput.PersonalPropertyDeductible !== 0 && this.calcInput.PersonalPropertyDeductible !== null
        ) {
            this.PersonalPropertyDeductibleVariableSlider.stepsArray.splice(0, 1);
            this.PersonalPropertyDeductibleVariableSlider.rightLabel = '25%';
        }

        this.WantsBreakableCoverageRadioGroup = this.sliderConfigService.getWantsBreakableCoverage(this.calcInput.BreakablesCoverage, false);
        this.WantsBreakableCoverageRadioGroupFake = this.sliderConfigService.getWantsBreakableCoverage(this.calcInput.BreakablesCoverage, true);
        this.LossOfUseCoverageVariableSlider = this.sliderConfigService.getLossOfUseCoverage(this.calcInput.LossOfUseCoverage,
            this.sliderConfigService.getCondoLUCoverageSteps());
        this.LossOfUseCoverageVariableSlider1 = this.sliderConfigService.getLossOfUseCoverage(this.calcInput.LossOfUseCoverage,
            this.sliderConfigService.getRenterLUCoverageSteps());
    }


    private loadMobilehomeComponents() {
        
        // Creating a new object to delete reference with original object by using JSON technique
        if (!this.isRefreshPage) {
            if (this.calcInput.yesCompare === false && this.policytype === this.modules.mobilehome && !this.calcInput.PersonalPropertyDeductible) {
                this.calcInput.PersonalPropertyDeductible = 15;
            }
            else if (this.calcInput.yesCompare === true && this.policytype === this.modules.mobilehome && this.calcInput.PersonalPropertyDeductible !== 0 && this.calcInput.PersonalPropertyDeductible !== null) {
                this.calcInput.PersonalPropertyDeductible = 0;
            }
        }
        
        this.calcInput.PersonalPropertyCoverage = (this.calcInput.yesCompare && this.calcInput.PersonalPropertyCoverage === 0) ? 50000 : this.calcInput.PersonalPropertyCoverage;
        this.calcInput.LossOfUseCoverage = this.calcInput.yesCompare && this.calcInput.LossOfUseCoverage == 0 ? 15000 : this.calcInput.LossOfUseCoverage;
        this.calcInput.PersonalPropertyDeductible = this.calcInput.yesCompare ? 0 : this.calcInput.PersonalPropertyDeductible;

        let ddList = this.sliderConfigService.frfPPDeductibleOptions(this.sliderConfigService.getDDSteps(), this.sliderConfigService.frfException(this.frfMode, this.quoterRequest));
        this.calcInput.BuildingPropertyDeductible = this.defaultDwellingDeductible(ddList, this.calcInput.BuildingPropertyDeductible);
        this.DwellingDeductibleVariableSlider = this.sliderConfigService.getDwellingDeductible(this.calcInput.BuildingPropertyDeductible,
            ddList,
            this.sliderConfigService.frfException(this.frfMode, this.quoterRequest),
            this.sliderConfigService.frfOver1Million(this.quoterRequest));
        this.BCUCoverageVariableSlider = this.sliderConfigService.getBuildingCodeUpgradeLimitIncrease(this.calcInput.BuildingCodeUpgradeLimitIncrease);
        let ppList = this.sliderConfigService.frfPPCoverageOptions(this.sliderConfigService.getHomeownerPPCoverageSteps(this.calcInput.yesCompare), this.frfMode);
        this.calcInput.PersonalPropertyCoverage = this.defaultPersonalProperty(ppList, this.calcInput.PersonalPropertyCoverage);
        this.PersonalPropertyCoverageVariableSlider = this.sliderConfigService.getPersonalPropertyCoverage(this.calcInput.PersonalPropertyCoverage,
            "HOMEOWNERS.CALCULATIONS.PERSONAL_PROPERTY_COVERAGE",
            "HOMEOWNERS.CALCULATIONS.PERSONAL_PROPERTY_COVERAGE_MOBILE",
            ppList);
        this.PersonalPropertyDeductibleVariableSlider = this.sliderConfigService.getPersonalPropertyDeductible(this.calcInput.PersonalPropertyDeductible,
            "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE",
            "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_MOBILE",
            this.calcInput.yesCompare,
            "GLOBAL.NO",
            this.sliderConfigService.getHomeownerPPDeductibleSteps(this.calcInput.yesCompare));
        this.PersonalPropertyDeductibleVariableSliderFake = this.sliderConfigService.getPersonalPropertyDeductible(this.calcInput.PersonalPropertyDeductible,
            "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE",
            "HOMEOWNERS.CALCULATIONS.SEPARATE_PERSONAL_PROPERTY_DEDUCTIBLE_MOBILE",
            this.calcInput.yesCompare,
            "GLOBAL.NO",
            this.sliderConfigService.getHomeownerPPDeductibleSteps(this.calcInput.yesCompare));

        if (this.showGeneralPage && this.policytype === this.modules.mobilehome && this.calcInput.PersonalPropertyDeductible !== null) {
            this.PersonalPropertyDeductibleVariableSlider.stepsArray.splice(0, 1);
            this.PersonalPropertyDeductibleVariableSlider.rightLabel = '25%';
        }

        this.WantsBreakableCoverageRadioGroup = this.sliderConfigService.getWantsBreakableCoverage(this.calcInput.BreakablesCoverage, false);
        this.WantsBreakableCoverageRadioGroupFake = this.sliderConfigService.getWantsBreakableCoverage(this.calcInput.BreakablesCoverage, true);
        this.LossOfUseCoverageVariableSlider = this.sliderConfigService.getLossOfUseCoverage(this.calcInput.LossOfUseCoverage,
            this.sliderConfigService.getCondoLUCoverageSteps());
        this.LossOfUseCoverageVariableSlider1 = this.sliderConfigService.getLossOfUseCoverage(this.calcInput.LossOfUseCoverage,
            this.sliderConfigService.getRenterLUCoverageSteps());
    }

    defaultDwellingDeductible(list, value) {
        let lowest = list[list.length - 1].value;

        return lowest > value ? lowest : value;
    }

    defaultPersonalProperty(list, value) {
        let highest = list[list.length - 1].value;

        return value > highest ? highest : value;
    }


    async calculateQuote(cRequest: CalcInput) {
        this.gaugeValue = 0;
        this.maxValue = 100;
        this.processing = true;
        setTimeout(() => {
            $($(".fakeGuage")[0]).children(".reading-block").text("-");
        }, 0);
        $(".fakeGuage.reading-block").text("-");

        this.estimateReq$.next(cRequest);
    }

    processEstimateRequest(response) {
        this.quoterResponse = response;
        setTimeout(() => {
            this.gaugeValue = parseFloat(this.quoterResponse.MonthlyPremium.toFixed(2));
            this.maxValue = this.quoterResponse.AnnualPremium;
            this.processing = false;
            this.setActualGuageValue();
            this.requestCount++;
        }, 200);
    }

    setActualGuageValue() {
        setTimeout(() => {
            // tslint:disable-next-line:max-line-length
            let v = $(".actualGuage").find(".reading-block").text().replace("$", "");
            v = v.replace(/,/g, '')
            v = parseFloat(v).toFixed(2);
            v = v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            $(".actualGuage").find(".reading-block").text("$" + v);
        }, 10);
    }

    loadDefaultValuesForCondo() {
        this.calcInput.BuildingPropertyCoverage = 50000;
        this.calcInput.BreakablesCoverage = false;
        this.calcInput.BuildingPropertyDeductible = 15;
        this.calcInput.PersonalPropertyCoverage = this.frfMode? 25000 : 50000;
        this.calcInput.PersonalPropertyDeductible = 15;
        this.calcInput.LossOfUseCoverage = 15000;
        this.calcInput.LossAssessmentCoverage = 50000;
        this.calcInput.LossAssessmentDeductible = 15;
        this.calcInput.IsDefault = 1;
    }

    loadDefaultValuesForRenter() {
        this.calcInput.BuildingPropertyCoverage = 50000;
        this.calcInput.BuildingPropertyDeductible = 15;
        this.calcInput.PersonalPropertyCoverage = this.frfMode ? 25000 : 75000;
        this.calcInput.PersonalPropertyDeductible = 15;
        this.calcInput.BreakablesCoverage = false;
        this.calcInput.LossOfUseCoverage = 25000;
        this.calcInput.LossAssessmentCoverage = 50000;
        this.calcInput.LossAssessmentDeductible = 15;
        this.calcInput.IsDefault = 1;
    }

    loadDefaultValuesForHomeOwnoner() {
        this.calcInput.BuildingPropertyDeductible = 15;
        this.calcInput.BuildingCodeUpgradeLimitIncrease = 20000;
        this.calcInput.MasonryVeneerCoverage = false;
        this.calcInput.PersonalPropertyCoverage = this.frfMode ? 25000 : 50000;
        this.calcInput.PersonalPropertyDeductible = 15;
        this.calcInput.BreakablesCoverage = false;
        this.calcInput.LossOfUseCoverage = 15000;
        this.calcInput.IsDefault = 1;
    }

    loadDefaultValuesForMobileOwnoner() {
        this.calcInput.BuildingPropertyDeductible = 15;
        this.calcInput.PersonalPropertyCoverage = this.frfMode ? 25000 : 50000;
        this.calcInput.PersonalPropertyDeductible = 15;
        this.calcInput.LossOfUseCoverage = 15000;
        this.calcInput.BuildingCodeUpgradeLimitIncrease = 20000;
        this.calcInput.BreakablesCoverage = false;
        this.calcInput.IsDefault = 1;
    }

    saveCalcInputData() {
        this.quoteStorageService.SaveCalcInputRequest(this.calcInput);
    }

    ngOnInit(): void {
        this.quoterRequest = this.quoterRequestService.getSavedQuoterRequest();
        let savedData = this.quoterRequestService.getCalcInputData();
        this.requestCount = 0;

        //added for session management
        //direct link with no session data should re-direct back to home page
        if (this.quoterRequest.StartDate == null) {
            this.moveTop(null);
            this.quoterRequestService.resetAllData();
            this.router.navigate([""]);
        }

        this.frfMode = this.calcDatesService.isFrfMode(this.quoterRequest.ActualStartDate, this.quoterRequest.IsRenewal);
        
        if (savedData === null) {
            this.quoteStorageService.SaveQuoteCompare(undefined);
            this.loadComponets(this.policytype, true);

            this.calculateQuote(this.calcInput);
            this.shouldShowNotice();
        } else {
            this.calcInput = savedData;
            this.setComparing(this.isComparing());
            this.loadComponets(this.policytype, false);
            
            this.zone.run(() => {
                setTimeout(() => {
                    this.validateExisting(this.policytype, savedData.PersonalPropertyDeductible);

                    this.calculateQuote(this.calcInput);
                    this.shouldShowNotice();

                }, 500);
            });
        }
    }

    loadComponets(policytype: number, setDefault: boolean) : void {
        if (policytype === moduleType.renter) {
            if (setDefault) {
                this.loadDefaultValuesForRenter();
            }
            this.loadRenterComponents();
        }

        if (policytype === moduleType.condo) {
            if (setDefault) {
                this.loadDefaultValuesForCondo();
            }
            this.loadCondoComponents();
        }

        if (policytype === moduleType.mobilehome) {
            if (setDefault) {
                this.loadDefaultValuesForMobileOwnoner();
            }
            this.loadMobilehomeComponents();
        }

        if (policytype === moduleType.homeowner) {
            if (setDefault) {
                this.loadDefaultValuesForHomeOwnoner();
            }
            this.loadHomeOwnerComponents();
        }
    }

    validateExisting(policytype: number, personalPropertyDeductible: any): void {
        if (policytype === moduleType.condo) {
            //validate area needs to be refactored, this should be a good enough fix for now
            this.validateSliderForCondo();
            setTimeout(() => {
                this.validateSliderForCondo('PersonalProperty', { key: 'PersonalPropertyCoverage', value: personalPropertyDeductible });
            }, 500);
        }

        if (policytype === moduleType.homeowner) {
            this.validateSliderForHome();
        }

        if (policytype === moduleType.mobilehome) {
            this.validateSliderForMobile();
        }
    }

    shouldShowNotice() {
        const foundation = this.quoterRequest.foundationTypeVariable;
        const yearBuilt = this.quoterRequest.YearBuilt;
        let showNotice = yearBuilt && yearBuilt <= 1979 && [1, 2].indexOf(foundation) !== -1;

        this.showRetrofitNotice = (!this.quoterRequest.HrdRequirementsConfirmed && showNotice) || (!this.quoterRequest.HrdRequirementsVerifiedByEngineer && showNotice);
    }

    private isComparing() {
        if (this.yesCompare === true || this.yesCompare === false) {
            return this.yesCompare;
        }
        else if (this.isRefreshPage) {
            return this.parseYesCompare(this.quoteStorageService.GetCompareItem());
        }

        return undefined;
    }

    private setComparing(yesCompare) {
        //note - two fields appear redundant, look into future refactor
        this.calcInput.yesCompare = yesCompare;
        this.calcInput.comparing = yesCompare;
    }


    goBack() {
        const id = this.quoterRequestService.getEditCalculationId();
        this.submitForm();
        if (id) {
            this.quoterRequestService.setSavedQuotes(id);
        }

        if (this.policytype === moduleType.renter) {
            this.router.navigate(["renter/general"]);
        } else if (this.policytype === moduleType.condo) {
            this.router.navigate(["condo/general"]);
        } else if (this.policytype === moduleType.homeowner) {
            this.router.navigate(["homeowner/details"]);
        } else if (this.policytype === moduleType.mobilehome) {
            this.router.navigate(["mobilehome/details"]);
        }
    }

    startOver() {
        this.moveTop(null);
        this.quoterRequestService.resetAllData();
        this.sessionService.saveSession(null);
        this.router.navigate([""]);
    }

    compare(e) {
        e.preventDefault();
        const id = this.quoterRequestService.getEditCalculationId();
        this.submitForm();
        this.quoterRequestService.setSavedQuotes(id);
        if (!id && id != 0) {
            this.quoterRequestService.setEditCalculationId(null);
        }

        //call quoter to log compare request
        this.quoterRequestService.logRequests([this.quoterRequestService.getCalcInputData()], this.policytype, "compare");

        this.router.navigate(["compare"]);
    }

    useLanguage(selectedlanguage: string) {
        this.translationService.useLanguage(selectedlanguage);
        this.quoteStorageService.SaveLanguage(this.translationService.currentLanguage);
    }

    trackBraceBoltGrantsClick(url: string) {
        this.tracking.eventEmitter(url, 'BraceBoltGrants', 'Click');
    }

    download(event: any) {
        this.tracking.eventEmitter("location", "User Behavior", "DownloadPrint");
        event.preventDefault();
        event.stopPropagation();

        const estimate = this.estimateBuilderService.BuildEstimate(this.calcInput, this.quoterRequest, this.quoterResponse);

        this.downloadFileRequest = new DownloadFilePostModel();
        this.downloadFileRequest.header = this.printBuilderService.buildHeader(estimate);
        this.downloadFileRequest.label = this.printBuilderService.buildLabel(estimate);
        this.downloadFileRequest.quotes = [];
        this.downloadFileRequest.quotes.push(this.printBuilderService.buildQuotePdf(estimate));
        this.printoutService.downloadFile(this.downloadFileRequest);

        //call quoter to log download request
        this.quoterRequestService.logRequests([this.calcInput], this.policytype, "print");
    }


    submitForm() {
        this.saveCalcInputData();
    }


    openModal(data, size) {
        const modalRef = this.modalService.open(PopupComponent,
            {
                scrollable: false,
                windowClass: "myCustomModalClass",
                size
                // keyboard: false,
                // backdrop: 'static'
            });
        modalRef.componentInstance.fromParent = data;
        modalRef.result.then();
    }

    public moveTop($event) {
        this.errorMessageService.moveTop();
    }

    imageUrl(relative: string) {
        return this.imageService.ImageLoc(relative);
    }

    private parseYesCompare(input: string) {
        if (input === "true") {
            return true;
        }

        if (input === "false") {
            return false;
        }

        return undefined;
    }
}
