import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
//import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { CalculatorSettingsService } from './shared/services/calculatorsettings/calculator-settings.service';
import { TranslationService } from "./shared/services/translation/translation.service";
import { QuoteStorageService } from "./shared/services/quote-storage/quote-storage.service";
import { SessionService } from "./shared/services/session/session.service";
import { CalcDatesService } from "./shared/services/calc-dates/calc-dates.service";
import { AnalyticsTrackingService } from "./shared/services/tracking/tracking.service";
import { Router } from "@angular/router";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from "rxjs";
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
    title = "PremiumCalcApp";
    id = 1;
    //fromCode = _("demo.text-in-code");
    isIframe = false;
    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();


    constructor(
        private calculatorSettingsService: CalculatorSettingsService,
        private translate: TranslateService,
        private quoteStorageService: QuoteStorageService,
        private sessionService: SessionService,
        private calcDateService: CalcDatesService,
        private tracking: AnalyticsTrackingService,
        private translationService: TranslationService,
        public router: Router,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private broadcastService: MsalBroadcastService,
        private authService: MsalService) {
        let that = this;
        this.calculatorSettingsService.updateCalculatorSettings(function () {
            calcDateService.refreshSettings();
            const piSettings = that.calculatorSettingsService.participatingInsurerSettings;

            if (piSettings.enableChinese) {
                that.translationService.useLanguage("zh");
            } else if (piSettings.enableSpanish) {
                that.translationService.useLanguage("es");
            }
            that.translate.setDefaultLang(that.calculatorSettingsService.localeSettings.defaultLocale);
            that.tracking.trackLocationChange(router.url);
        });

        this.translationService.useLanguage("en");
        const lang = this.quoteStorageService.GetLanguage();
        if (lang) {
            this.translationService.useLanguage(lang);
        }

        const snapshot = new URL(window.location.href);
        const langV = snapshot.searchParams.get('lang');
        if (langV) {
            this.translationService.useLanguage(langV);
        }

        if (!snapshot.pathname.startsWith("/admin")) {
            this.sessionService.restoreSession();
        }  
    }

    ngOnInit() {
        this.isIframe = window !== window.parent && !window.opener;

        this.broadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
            })
    }

    login() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    ngOnDestroy(): void {
        this._destroying$.next();
        this._destroying$.complete();
    }
}
