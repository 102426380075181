import { Injectable } from "@angular/core";
import { SessionStorageService } from '../session-storage/session-storage.service';

export const EDIT_QUOTE_OBJ = "editQuote";
export const QUOTE_REQUEST_OBJ = "quoterRequest";
export const CALC_INPUT_REQUEST_OBJ = "CalcInput";
export const QUOTE_LIST_OBJ = "quotesList";
export const STATE_PARAMS_OBJ = "params";
export const STATE_VAL = "state";
export const LANGUAGE_VAL = "language";
export const COMPARE_VAL = "page";
export const QUOTE_COMPARE_VAL = "YesCompare";
export const COMPARE_ITEM_VAL = "compare-item";
export const SESSION_KEY = "sessionId";

@Injectable({
  providedIn: "root"
})
export class QuoteStorageService {

  constructor(private storageService: SessionStorageService) {

  }

  /* edit_quote Helpers */
  SaveQuote(obj) {
    this.storageService.saveObject(EDIT_QUOTE_OBJ, obj);
    return this;
  }

  RemoveQuote() {
    this.storageService.removeObject(EDIT_QUOTE_OBJ);
    return this;
  }

  GetQuote() {
    return this.storageService.getObject(EDIT_QUOTE_OBJ);
    }

    /* session id Helpers */
  SaveSession(obj) {
      this.storageService.saveObject(SESSION_KEY, obj);
        return this;
  }

  RemoveSession() {
      this.storageService.removeObject(SESSION_KEY);
      return this;
  }

  GetSession() {
      return this.storageService.getObject(SESSION_KEY);
  }

  /* quoterRequest Helpers */
  SaveRequest(obj) {
    this.storageService.saveObject(QUOTE_REQUEST_OBJ, obj);
    return this;
  }

  RemoveRequest() {
    this.storageService.removeObject(QUOTE_REQUEST_OBJ);
    return this;
  }

  GetRequest() {
    return this.storageService.getObject(QUOTE_REQUEST_OBJ);
  }

  /* CalcInput Helpers */
  SaveCalcInputRequest(obj) {
      this.storageService.saveObject(CALC_INPUT_REQUEST_OBJ, obj);
    return this;
  }

  RemoveCalcInputRequest() {
      this.storageService.removeObject(CALC_INPUT_REQUEST_OBJ);
    return this;
  }

  GetCalcInputRequest() {
      return this.storageService.getObject(CALC_INPUT_REQUEST_OBJ);
  }

  /* QUOTE_LIST Helpers */
  SaveQuoteList(obj) {
    this.storageService.saveObject(QUOTE_LIST_OBJ, obj);
    return this;
  }

  RemoveQuoteList() {
    this.storageService.removeObject(QUOTE_LIST_OBJ);
    return this;
  }

  GetQuoteList() {
    return this.storageService.getObject(QUOTE_LIST_OBJ);
  }

  /* STATE_PARAMS Helper */
  SaveStateParams(obj) {
    this.storageService.saveObject(STATE_PARAMS_OBJ, obj);
    return this;
  }

  RemoveStateParams() {
    this.storageService.removeObject(STATE_PARAMS_OBJ);
    return this;
  }

  GetStateParams() {
    return this.storageService.getObject(STATE_PARAMS_OBJ);
  }

  /* State Helpers */
  SaveState(val) {
    this.storageService.saveItem(STATE_VAL, val);
    return this;
  }

  RemoveState() {
    this.storageService.removeItem(STATE_VAL);
    return this;
  }

  GetState() {
    return this.storageService.getItem(STATE_VAL);
  }

  /* Language Helpers */
  SaveLanguage(val) {
    this.storageService.saveItem(LANGUAGE_VAL, val);
    return this;
  }

  RemoveLanguage() {
    this.storageService.removeItem(LANGUAGE_VAL);
    return this;
  }

  GetLanguage() {
    return this.storageService.getItem(LANGUAGE_VAL);
  }

  /* Compare Page Helpers */
  SaveComparePage(val) {
    this.storageService.saveItem(COMPARE_VAL, val);
    return this;
  }

  RemoveComparePage() {
    this.storageService.removeItem(COMPARE_VAL);
    return this;
  }

  GetComparePage() {
    return this.storageService.getItem(COMPARE_VAL);
  }

  /* Quote Compare Helpers */
  SaveQuoteCompare(val) {
    this.storageService.saveItem(QUOTE_COMPARE_VAL, val);
    return this;
  }

  RemoveQuoteCompare() {
    this.storageService.removeItem(QUOTE_COMPARE_VAL);
    return this;
  }

  GetQuoteCompare() {
    return this.storageService.getItem(QUOTE_COMPARE_VAL);
  }

  /* Compare Items Helpers */
  SaveCompareItem(val) {
    this.storageService.saveItem(COMPARE_ITEM_VAL, val);
    return this;
  }

  RemoveCompareItem() {
    this.storageService.removeItem(COMPARE_ITEM_VAL);
    return this;
  }

  GetCompareItem() {
    return this.storageService.getItem(COMPARE_ITEM_VAL);
  }
}
