import { Injectable } from "@angular/core";
import { Location, PathLocationStrategy } from "@angular/common";

/**
 * @description
 * 
 * ensure that base URL will not have trailing slash
 * source: https://github.com/angular/angular/issues/21003
 * 
 * tested via:
 *  package.json - "start": "ng serve --serve-path=/California-Earthquake-Insurance-Policies/Earthquake-Insurance-Premium-Calculator/",
 *  index.html   - <base href="/California-Earthquake-Insurance-Policies/Earthquake-Insurance-Premium-Calculator/" />
 *
 * @usageNotes
 *
 * ### Example
 *
 * // app.module.ts
 * import { LocationStrategy } from "@angular/common";
 * import { BaseHrefPathLocationStrategy } from "./BaseHrefPathLocationStrategy.ts";
 * 
 * @NgModule({
 * ...
 * providers: [
 *     { provide: LocationStrategy, useClass: BaseHrefPathLocationStrategy }
 *   ],
 * ...
 * })
 * export class AppModule {}
 *
 */
@Injectable()
export class BaseHrefPathLocationStrategy extends PathLocationStrategy {
    prepareExternalUrl(url: string) {
        const baseHref = this.getBaseHref();
        const extUrlNormalizedStripped = Location.stripTrailingSlash(super.prepareExternalUrl(url));
        const extUrl = super.prepareExternalUrl(url);
        let result = baseHref === extUrlNormalizedStripped ? extUrlNormalizedStripped : extUrl;
        return result;
    }
}
