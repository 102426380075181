<div class="modal-header">
    <h5 class="modal-title" [innerHTML]="fromParent.header"></h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')" *ngIf="fromParent.showTopCloseButton">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [innerHTML]="fromParent.body">
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary btn-blue" data-dismiss="modal" (click)="closeModal('close')">{{'GLOBAL.OK' | translate}}</button>
</div>



