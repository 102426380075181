<div class="form-group slider-group" [ngClass]="{'cea-disable': sliderSettings.disabled}">

    <div class="row">
        <div class="slider-group slider-cell-caption">
            <div class="slider-caption cea-hidden-xss cea-hidden-xxs cea-hidden-xs">
                <label class="slider-caption" [id]="sliderIdPrefix + 'Label'">{{sliderSettings.caption | translate}}</label>
            </div>
            <div
                class="slider-caption cea-visible-xss cea-visible-xxs cea-visible-xs cea-hidden-sm cea-hidden-md cea-hidden-lg">
                <label class="slider-caption">{{sliderSettings.shortCaption | translate}}</label>
            </div>
        </div>
        <div class="slider-cell-input">
            <div class="input-group input-group-sm">
                <input [id]="sliderIdPrefix + 'NotSpecialInput'" readonly type="text" *ngIf="sliderSettings.isSpecial === false"
                    class="form-control default-cursor" notab="notab" disabled="disabled"
                    [ngClass]="{'error': !!errorMessage && !sliderSettings.disabled}"
                    attr.aria-label="{{sliderSettings.shortCaption | translate}}"
                    value="{{sliderSettings.unit === '$'? '$': ''}}{{value| number}}{{sliderSettings.unit === '%'? '%': ''}}">
                <input [id]="sliderIdPrefix + 'SpecialInput'" readonly type="text" *ngIf="sliderSettings.isSpecial === true"
                    class="form-control default-cursor" notab="notab" disabled="disabled"
                    [ngClass]="{'error': !!errorMessage && !sliderSettings.disabled}"
                    attr.aria-label="{{sliderSettings.shortCaption | translate}}"
                    value="{{value === 0 ? ((sliderSettings.SpecialValue==='NONE' || sliderSettings.SpecialValue==='GLOBAL.NONE'  || sliderSettings.SpecialValue==='GLOBAL.NO')?( sliderSettings.SpecialValue |translate):sliderSettings.SpecialValue)  : (sliderSettings.unit === '$'? '$': '') + (value | number) + (sliderSettings.unit === '%'? '%': '')}}">
                <span class="input-group-btn">
                    <app-cea-help-button [stype]="'slider'"
                                         [title]="sliderSettings?.helpSlug.title"
                                     [text1]="sliderSettings?.helpSlug.text"
                                     (moveTop)="moveUp($event)">
                    </app-cea-help-button>
                </span>
            </div>
        </div>
    </div>
    <div class="row slider-row">
        <div class="slider-cell-label" [ngClass]="{'left-padding-0':((sliderSettings?.leftLabel=='GLOBAL.NONE' || sliderSettings?.rightLabel=='GLOBAL.NONE')
         && translate.currentLang=='es')}">
            <label class="slider-label"
                *ngIf="!sliderSettings?.isReversed">{{sliderSettings?.leftLabel | translate}}</label>
            <label class="slider-label" *ngIf="sliderSettings?.isReversed"
                [innerHTML]="sliderSettings?.rightLabel | translate"></label>
        </div>
        <div class="slider-cell-bar" [ngClass]="{'errorSlider' : errorMessage !== ''  && !sliderSettings.disabled}" [id]="sliderIdPrefix + 'SliderControl'">
            <ngx-slider [(value)]="value" [options]="options"
                        (valueChange)=sliderValueChanged(sliderSettings.sliderKey)></ngx-slider>
        </div>

        <div class="text-right slider-cell-label">
            <label class="slider-label" *ngIf="sliderSettings?.isReversed"
                [innerHTML]="sliderSettings?.leftLabel"></label>
            <label class="slider-label" *ngIf="!sliderSettings?.isReversed"
                [innerHTML]="sliderSettings?.rightLabel"></label>
        </div>
    </div>
    <div class="alert alert-danger" *ngIf="errorMessage !== '' && !sliderSettings.disabled">
        {{(errorMessage | translate).replace('[#]',errorMessageReplaceText)}}
    </div>
    <div class="after"></div>
</div>
