import { Component, ViewChild } from "@angular/core";
import { moduleType } from "../../shared/models/module.type";
import { language } from "../../shared/models/language.model";
import { TranslationService } from "../../shared/services/translation/translation.service";
import { QuoterRequest2Service } from "../../shared/services/quote-request/quote-request2.service";
import { GeneralComponent } from "../../components/general/general.component";
import { QuestionsComponent } from "../../components/questions/questions.component";
import { ImageService } from "../../shared/services/images/image.service";
import { QuoteStorageService } from "../../shared/services/quote-storage/quote-storage.service";
import { SessionService } from "../../shared/services/session/session.service";

@Component({
  selector: "app-condo",
  templateUrl: "./condo.component.html",
  styleUrls: ["./condo.component.scss"]
})
export class CondoComponent {
  @ViewChild(GeneralComponent) general: GeneralComponent;
  @ViewChild(QuestionsComponent) questions: QuestionsComponent;
  showGeneralPage = true;
  showQuestionPage = false;
  policytype = moduleType.condo;
  lang = language;
  constructor(public translationService: TranslationService,
    private quoterRequestService: QuoterRequest2Service,
    private quoteStorageService: QuoteStorageService,
    private sessionService: SessionService,
    private imageService: ImageService) {

    this.sessionService.listenUnload("/condo/general", function () {
      this.quoteStorageService.SaveState(this.sessionService.getRelativeUrl())
          .SaveStateParams({ showGeneralPage: this.showGeneralPage, showQuestionPage: this.showQuestionPage })
          .SaveRequest(this.quoterRequestService.getCurrentQuoterRequest())
          .SaveLanguage(this.translationService.currentLanguage);
    }.bind(this));
  }

  useLanguage(selectedlanguage: string) {
      this.translationService.useLanguage(selectedlanguage);
      this.quoteStorageService.SaveLanguage(this.translationService.currentLanguage);
      if (this.general) {
          this.general.languageChanged();
      }
  }

  goToQuestionPage($event: any) {
    if ($event === "true") {
      this.showGeneralPage = false;
      this.showQuestionPage = true;
    }
  }

  goToGeneralPage($event: any) {
    if ($event === "true") {
      this.showGeneralPage = true;
      this.showQuestionPage = false;
    }
  }

  imageUrl(relative: string) {
    return this.imageService.ImageLoc(relative);
  }

}
