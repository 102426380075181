import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { moduleType } from "../../shared/models/module.type";
import { QuoterRequest } from "../../shared/models/quoterRequest.model";
import { TranslateService } from "@ngx-translate/core";
import { PopupComponent } from "..";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { QuoterRequest2Service } from "../../shared/services/quote-request/quote-request2.service";
import { QuoteApplicationSettings } from "../../shared/models/quoteApplicationSettings.model";
import { HelperService } from "../../shared/services/helper/helper.service";
import { QuoterRequestErrorQuestion } from "../../shared/models/quoterRequestError";
import { ImageService } from '../../shared/services/images/image.service';
import { Router } from "@angular/router";
import { ErrorMessageService } from "../../shared/services/error-message/error-message.service";
import { CalcDatesService } from "../../shared/services/calc-dates/calc-dates.service";
import { TranslationService } from "../../shared/services/translation/translation.service";

@Component({
    selector: "app-questions",
    templateUrl: "./questions.component.html",
    styleUrls: ["./questions.component.scss"]
})
export class QuestionsComponent implements OnInit {
    page = moduleType;
    isValid = false;
    showHrdDiscount = false;
    groupkey = this.helper.GenerateGuid();
    homeownerFirstYearBuilt = 1800;
    mobilehomeFirstYearBuilt = 1800;
    // tslint:disable-next-line:max-line-length
    quoterRequestError: QuoterRequestErrorQuestion = new QuoterRequestErrorQuestion();

    @Output() gotoGeneralPageEvent = new EventEmitter<string>();
    @Input() policytype: number;
    @Input() yesCompare: boolean = false;
    quoterRequest: QuoterRequest;
    quoteApplicationSettings: QuoteApplicationSettings;
    isMobileView: boolean;
    isMobileArr: boolean[] = [];
    gotoGeneral = "true";
    yearBuiltisEmptyMessage = this.translate.instant("ERRORS.COMPLETE_REQUIRED_FIELDS");
    yearBuiltErrorMessage = this.translate.instant("ERRORS.VALID_CONSTRUCTION_YEARS")
        .replace("[CURRENT_YEAR]", this.calcDatesService.getMaxYearBuilt().toString());
    yearBuiltisEmptyMobileErrorMessage = this.translate.instant("ERRORS.COMPLETE_REQUIRED_FIELDS")
    //    .replace("[CURRENT_YEAR]", new Date().getFullYear().toString());
    yearBuiltMobileErrorMessage = this.translate.instant("ERRORS.VALID_CONSTRUCTION_YEARS")
        .replace("[CURRENT_YEAR]", this.calcDatesService.getMaxYearBuilt().toString());
    foundationTypeVariableOptions = [
        this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.SLAB"),
        this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.RAISED"),
        this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.OTHER")
    ];

    roofTypeVariableOptions = [
        this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.TILE"),
        this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.COMPOSITION"),
        this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.WOODSHAKE"),
        this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.OTHER")
    ];

    constructor(private translate: TranslateService,
        private helper: HelperService,
        private modalService: NgbModal,
        private router: Router,
        private changeDetector: ChangeDetectorRef,
        private quoterRequestService: QuoterRequest2Service,
        private errorMessageService: ErrorMessageService,
        private calcDatesService: CalcDatesService,
        private translationService: TranslationService,
        private imageService: ImageService) {
        this.errorMessageService.moveTop();
    }

    ngDoCheck() {
        this.yearBuiltisEmptyMessage = this.translate.instant("ERRORS.COMPLETE_REQUIRED_FIELDS");
        this.yearBuiltErrorMessage = this.translate.instant("ERRORS.VALID_CONSTRUCTION_YEARS")
            .replace("[CURRENT_YEAR]", this.calcDatesService.getMaxYearBuilt().toString());
        this.yearBuiltisEmptyMobileErrorMessage = this.translate.instant("ERRORS.COMPLETE_REQUIRED_FIELDS")
        this.yearBuiltMobileErrorMessage = this.translate.instant("ERRORS.VALID_CONSTRUCTION_YEARS")
            .replace("[CURRENT_YEAR]", this.calcDatesService.getMaxYearBuilt().toString());
        this.foundationTypeVariableOptions = [
            this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.SLAB"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.RAISED"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.OTHER")
        ];

        this.roofTypeVariableOptions = [
            this.translate.instant(this.translationService.whichTranslation("1/1/2025", this.quoterRequest.ActualStartDate, "HOMEOWNERS.QUESTIONS.ROOF_TYPES.TILE", "HOMEOWNERS.QUESTIONS.ROOF_TYPES.TILE_RFF2025")),
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.COMPOSITION"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.WOODSHAKE"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.OTHER")
        ];
    }

    ngOnInit(): void {
        this.quoterRequest = this.quoterRequestService.getSavedQuoterRequest();
        this.quoteApplicationSettings = this.quoterRequestService.getSettingForQuestionPage();
        this.checkHrdDiscount();
    }

    moveTop() {
        this.errorMessageService.moveTop();
    }

    mobileVisible(value: boolean, index: number) {
        this.isMobileArr[index] = value;
        this.isMobileView = this.isMobileVisible();
        this.changeDetector.detectChanges();
    }


    isMobileVisible() {
        for (let val of this.isMobileArr) {
            if (val) {
                return true;
            }
        }

        return false;
    }

    openHelperPopup(title: string, text: string, text2: string = null, text3: string = null) {
        this.errorMessageService.moveTop();

        if (title === 'HOMEOWNERS.HELP.HRD_CRIPPLE.TITLE') {
            text2 = text3;
            text3 = null;
        }
        const header = this.translate.instant(title);
        let body = this.imageService.HelpImageLoc(this.translate.instant(text));

        if (text2 !== null) {
            body += this.imageService.HelpImageLoc(this.translate.instant(text2));

            if (text3 !== null) {
                body += this.imageService.HelpImageLoc(this.translate.instant(text3));
            }
        }
        const data = {
            header,
            body,
            showTopCloseButton: false
        };
        const size = "xl";
        this.openModal(data, size);
    }

    openModal(data, size) {
        const modalRef = this.modalService.open(PopupComponent,
            {
                scrollable: false,
                windowClass: "myCustomModalClass",
                size
                // keyboard: false,
                // backdrop: 'static'
            });
        modalRef.componentInstance.fromParent = data;
        modalRef.result.then();
    }

    goBack() {
        this.quoterRequestService.saveQuoterRequest(this.quoterRequest);
        this.gotoGeneralPageEvent.emit(this.gotoGeneral);
    }

    validateYearBuilt(): void {
        if (!this.quoterRequest.YearBuilt) {
            this.quoterRequestError.YearBuiltIsEmpty = true;
        } else {
            this.quoterRequestError.YearBuiltIsEmpty = false;
            this.quoterRequestError.YearBuiltIsOutsideOfRange = this.policytype === moduleType.mobilehome
                ? false : true;
            this.quoterRequestError.YearBuiltIsOutsideOfRange = !this.checkYearBuiltInRange();
        }
    }

    validateInsuredValue(): void {
        if (this.quoterRequest.InsuredValue === null || this.quoterRequest.InsuredValue === '') {
            this.quoterRequestError.InsuredValueIsEmpty = true;
        } else {
            this.quoterRequestError.InsuredValueIsEmpty = false;
        }

        if (this.quoterRequest.InsuredValue < 1 && !this.quoterRequestError.InsuredValueIsEmpty) {
            this.quoterRequestError.InsuredValueOutOfRange = true;
        } else {
            this.quoterRequestError.InsuredValueOutOfRange = false;
        }
        if (this.quoterRequest.InsuredValue > 2147483647 && !this.quoterRequestError.InsuredValueIsEmpty) {
            this.quoterRequestError.InsuredValueOutOfRangeHigh = true;
        } else {
            this.quoterRequestError.InsuredValueOutOfRangeHigh = false;
        }
    }

    validateNumberOfStories() {
        if (this.quoterRequest.numberOfStories === -1) {
            this.quoterRequestError.numberOfStoriesIsNotSelected = true;
        } else {
            this.quoterRequestError.numberOfStoriesIsNotSelected = false;
        }
    }

    validateFoundation(): void {
        if (this.quoterRequest.foundationTypeVariable === -1) {
            this.quoterRequestError.foundationTypeVariableIsNotSelected = true;
        } else {
            this.quoterRequestError.foundationTypeVariableIsNotSelected = false;
        }
    }

    validateRoofType(): void {
        this.quoterRequestError.roofTypeVariableIsNotSelected = this.quoterRequest.roofTypeVariable === -1 ? true : false;
    }

    isValidMobile(): boolean {
        let isValid = false;
        if (!this.quoterRequestError.YearBuiltIsOutsideOfRange
            && !this.quoterRequestError.YearBuiltIsEmpty
            && !this.quoterRequestError.InsuredValueOutOfRange
            && !this.quoterRequestError.InsuredValueOutOfRangeHigh
            && !this.quoterRequestError.InsuredValueIsEmpty
        ) {
            isValid = true;
        }

        return isValid;
    }

    isValidHomeowner(): boolean {
        let isValid = false;

        if (!this.quoterRequestError.YearBuiltIsOutsideOfRange
            && !this.quoterRequestError.YearBuiltIsEmpty
            && !this.quoterRequestError.InsuredValueOutOfRange
            && !this.quoterRequestError.InsuredValueOutOfRangeHigh
            && !this.quoterRequestError.InsuredValueIsEmpty
            && !this.quoterRequestError.foundationTypeVariableIsNotSelected
            && !this.quoterRequestError.roofTypeVariableIsNotSelected
            && !this.quoterRequestError.numberOfStoriesIsNotSelected) {
            isValid = true;
        }

        return isValid;
    }

    validateFrom(): boolean {
        let isFormValid = false;

        this.validateYearBuilt();
        this.validateInsuredValue();
        this.validateNumberOfStories();
        this.validateFoundation();
        this.validateRoofType();

        if (this.policytype === moduleType.mobilehome) {
            isFormValid = this.isValidMobile();
        }

        if (this.policytype === moduleType.homeowner) {
            isFormValid = this.isValidHomeowner();
        }

        return isFormValid;
    }


    submitForm() {
        let isFormValid = false;
        isFormValid = this.validateFrom();

        if (isFormValid) {
            this.quoterRequestService.saveQuoterRequest(this.quoterRequest);
            if (this.policytype === this.page.mobilehome || this.policytype === this.page.homeowner) {
                if (this.policytype === this.page.mobilehome) {
                    this.router.navigate(["mobilehome/calculations/4"], { queryParams: { yesCompare: this.yesCompare } });
                } else {
                    this.router.navigate(["homeowner/calculations/1"], { queryParams: { yesCompare: this.yesCompare } });
                }
            }
        }
    }

    hrdRequirementsConfirmedHelp() {
        if (this.isHrdOptions()) {
            const title = this.translate.instant("HOMEOWNERS.HELP.HRD_1M_UNDER.TITLE");
            const text = this.translate.instant("HOMEOWNERS.HELP.HRD_1M_UNDER.TEXT");
            this.openHelperPopup(title, text);
        }
        else {
            const title = this.translate.instant("HOMEOWNERS.HELP.HRD.TITLE");
            const text = this.translate.instant("HOMEOWNERS.HELP.HRD.TEXT");
            this.openHelperPopup(title, text);
        }
    }

    isHrdOptions() {
        return this.calcDatesService.isFrfMode(this.quoterRequest.ActualStartDate, this.quoterRequest.IsRenewal) && this.quoterRequest.InsuredValue <= 1000000;
    }

    HrdRequirementsVerifiedByEngineerHelp() {
        const title = this.translate.instant("HOMEOWNERS.QUESTIONS.HELP.HRD_VERIFICATION.TITLE");
        const text = this.translate.instant("HOMEOWNERS.QUESTIONS.HELP.HRD_VERIFICATION.TEXT");
        this.openHelperPopup(title, text);
    }

    HasBracingSystemHelp() {
        const title = this.translate.instant("MOBILEHOME.QUESTIONS.HELP.ERBS_QUESTION.TITLE");
        const text = this.translate.instant("MOBILEHOME.QUESTIONS.HELP.ERBS_QUESTION.TEXT");
        this.openHelperPopup(title, text);
    }

    checkBuildYear(event) {
        if (!(event?.target?.value && event.target.value.trim() === "")) {
            if (event.target.value.trim() === "") {
                this.quoterRequestError.YearBuiltIsEmpty = true;
                this.quoterRequestError.YearBuiltIsOutsideOfRange = false;
            } else {
                this.quoterRequest.YearBuilt = event.target.value.trim();
                const convertedNumber = Number(this.quoterRequest.YearBuilt);
                if (!isNaN(convertedNumber) && this.quoterRequest.YearBuilt) {
                    this.quoterRequest.YearBuilt = Math.floor(convertedNumber);
                }
                this.quoterRequestError.YearBuiltIsOutsideOfRange = !this.checkYearBuiltInRange();
                this.quoterRequestError.YearBuiltIsEmpty = false;
            }
        }
        this.checkForHRD();
    }

    checkYearBuiltInRange() {
        let thisYear = this.calcDatesService.getMaxYearBuilt();
        if (this.quoterRequest.policytype === moduleType.homeowner && this.quoterRequest.YearBuilt >= this.homeownerFirstYearBuilt && this.quoterRequest.YearBuilt <= thisYear) {
            return true;
        }
        if ((this.quoterRequest.policytype === moduleType.mobilehome && this.quoterRequest.YearBuilt >= this.mobilehomeFirstYearBuilt && this.quoterRequest.YearBuilt <= thisYear)) {
            return true;
        }

        return false;
    }

    checkInsuredValue(event) {
        if (!(event?.target?.value && event.target.value.trim() === "")) {
            if (event.target.value.trim() === "") {
                this.quoterRequestError.InsuredValueIsEmpty = true;
                this.quoterRequestError.InsuredValueOutOfRange = false;
                this.quoterRequestError.InsuredValueOutOfRangeHigh = false;
            } else {
                this.quoterRequest.InsuredValue = event.target.value.trim();
                const convertedNumber = Number(this.quoterRequest.InsuredValue);
                if (!isNaN(convertedNumber) && this.quoterRequest.InsuredValue) {
                    this.quoterRequest.InsuredValue = Math.floor(convertedNumber);
                }
                this.quoterRequestError.InsuredValueOutOfRange = this.quoterRequest.InsuredValue >= 1 ? false : true;
                this.quoterRequestError.InsuredValueOutOfRangeHigh = this.quoterRequest.InsuredValue <= 2147483647 ? false : true;
                this.quoterRequestError.InsuredValueIsEmpty = false;
            }

        }
        this.checkForHRD();
    }


    checkForHRD(data = null, type = "") {
        if (this.quoterRequest.numberOfStories !== -1) {
            this.quoterRequestError.numberOfStoriesIsNotSelected = false;
        }

        if (this.quoterRequest.foundationTypeVariable !== -1) {
            this.quoterRequestError.foundationTypeVariableIsNotSelected = false;
        }

        if (this.quoterRequest.roofTypeVariable !== -1) {
            this.quoterRequestError.roofTypeVariableIsNotSelected = false;
        }

        this.checkHrdDiscount();
    }

    checkHrdDiscount() {
        if (this.quoterRequest.YearBuilt) {
            this.showHrdDiscount = false;
            if (this.quoterRequest.YearBuilt.toString().length === 4 && this.quoterRequest.YearBuilt <= 1979) {
                if (this.quoterRequest.foundationTypeVariable === 1 || this.quoterRequest.foundationTypeVariable === 2) {
                    if (this.quoterRequest.IsWoodFrame) {
                        this.showHrdDiscount = true;
                    } else {
                        this.showHrdDiscount = false;
                    }
                } else {
                    this.showHrdDiscount = false;
                }
            } else {
                this.showHrdDiscount = false;
            }
        }
    }

    isRff2025() {
        return this.calcDatesService.isRff2025(this.quoterRequest.ActualStartDate);
    }
}
