import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TranslationComponent } from "./translation/translation.component";


const routes: Routes = [
    {
        path: "", component: TranslationComponent,
    }
];



@NgModule({
    imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { 

}
