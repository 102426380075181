<h4 mat-dialog-title>Create New Draft</h4>
<h5>Source: {{sourceName}}</h5>

<div mat-dialog-content>
    <form [formGroup]="form">
        <input type="text"
               id="draft"
               name="draft"
               formControlName="draft"
               autocomplete="off"
               placeholder="Enter Name"
               style="padding: 5px !important;"
               autofocus />
    </form>

</div>

<div mat-dialog-actions style="margin-top: 9px !important; float: right !important;">
    <button mat-button
            style="margin-right: 9px;"
            (click)="onClickClose()">
        Cancel
    </button>
    <button mat-raised-button
            color="primary"
            (click)="onClickCreate()">
        Create
    </button>
</div>
