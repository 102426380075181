import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialComponentsModule } from './app-material-components';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgbModule, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { AppMainModule } from "./app-main/app-main.module";
import { AdminModule } from './admin/admin.module';
import { CustomDateParserFormatterService } from "./shared/services/CustomDateParserFormatter/custom-date-parser-formatter.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalculatorSettingsService } from "./shared/services/calculatorsettings/calculator-settings.service";
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { LocationStrategy } from "@angular/common";
import { BaseHrefPathLocationStrategy } from "./shared/services/BaseHrefPathLocationStrategy/BaseHrefPathLocationStrategy";


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        NgbModule,
        AppRoutingModule,
        RouterModule,
        AppMainModule,
        AdminModule,
        FormsModule,
        ReactiveFormsModule,
        AppMaterialComponentsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: creatTranslateLoaderRemote,
                deps: [HttpClient, CalculatorSettingsService]
            }
        }),
        BrowserAnimationsModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.clientId,
                authority: environment.authority,
                redirectUri: environment.redirectUri,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            },

        }), {
            interactionType: InteractionType.Redirect, // MSAL Guard Configuration
            authRequest: {
                scopes: ['user.read', environment.protectedapi],
            },
            
            loginFailedRoute: "/login-failed"
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            
            protectedResourceMap: new Map([
                ['https://graph.microsoft.com/v1.0/me', ['user.read']],
                [environment.protectedurl, [environment.protectedapi]]
            ])
        })
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService },
        { provide: LocationStrategy, useClass: BaseHrefPathLocationStrategy },
        { provide: 'Window', useValue: window },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        MsalGuard
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

// required for AOT compilation
export function createTranslateLoader(http: HttpClient) {
    const prefix = window["spaSourceUrl"] ? window["spaSourceUrl"] + "/assets/i18n/" : "/assets/i18n/";
    const suffix = ".json";

    return new TranslateHttpLoader(http, prefix, suffix);
}

export function creatTranslateLoaderRemote(http: HttpClient, settings: CalculatorSettingsService) {
    const baseUrl = window["spaSourceUrl"] ? window["spaSourceUrl"] : environment.apiUrl;
    const prefix = baseUrl + environment.languageurl;

    if (!settings.localeSettings.version) {
        const snapshot = new URL(window.location.href);
        const version = snapshot.searchParams.get('lv');
        settings.localeSettings.version = version ? version : '';
    }

    const suffix = settings.localeSettings.version ? "&draft=" + settings.localeSettings.version : "";
    return new TranslateHttpLoader(http, prefix, suffix);
}

