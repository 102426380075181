import { Component, ViewChild, EventEmitter, Output, OnInit,  Input, NgZone } from "@angular/core";
// @ts-ignore
import { } from "@types/googlemaps";
import { QuoterRequest2Service } from "../../shared/services/quote-request/quote-request2.service";

@Component({
    // tslint:disable-next-line:component-selector
    selector: "[app-autocomplete]",
    templateUrl: "./autocomplete.component.html",
    styleUrls: ["./autocomplete.component.scss"]
})
export class AutocompleteComponent implements OnInit {

    @Input() adressType: string;
    @Input() setSavedAddress: string;
    @Input() isMobileView: boolean;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @Output() setAddressError: EventEmitter<any> = new EventEmitter();
    @Output() setAddressPickedFromBrowser: EventEmitter<any> = new EventEmitter();
    isAddressFromGoogleApi = false;
    @ViewChild("addresstext") addresstext: any;


    autocompleteInput: string;
    queryWait: boolean;
    public placesService = null;
    public listResults: any[] = [];

    constructor(private quoterRequestService: QuoterRequest2Service, public zone: NgZone) {
    }

    ngOnInit() {
        if (this.setSavedAddress !== null && this.setSavedAddress !== undefined) {
            this.autocompleteInput = this.setSavedAddress;
        }
    }


    getAddressFromInput(event) {
        this.quoterRequestService.setAddress(this.autocompleteInput);
        this.isAddressFromGoogleApi = false;
        if (event?.target && event.target.value.trim() === "") {
            this.setAddressError.emit(true);
        } else {
            this.setAddressError.emit(false);
        }
        this.setAddressPickedFromBrowser.emit(this.isAddressFromGoogleApi);
    }

    getAddrComponent(place, componentTemplate) {
        let result;

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < place.address_components.length; i++) {
            const addressType = place.address_components[i].types[0];
            if (componentTemplate[addressType]) {
                result = place.address_components[i][componentTemplate[addressType]];
                return result;
            }
        }
    }

    getStreetNumber(place) {
        const COMPONENT_TEMPLATE = { street_number: "short_name" },
            streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return streetNumber ? streetNumber : "";
    }

    getStreet(place) {
        const COMPONENT_TEMPLATE = { route: "long_name" },
            street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return street;
    }

    // tslint:disable-next-line:ban-types
    invokeEvent(place: Object) {
        this.isAddressFromGoogleApi = true;
        this.setAddress.emit(place);
        this.autocompleteInput = this.getStreetNumber(place) + " " + this.getStreet(place);
        this.setAddressPickedFromBrowser.emit(this.isAddressFromGoogleApi);
    }

    public onFocus(event: Event): void {
        window['angularGooglePlaceDetailRef'] = { component: this, zone: this.zone };
    }

    public openDropdown(predictions: any[]): void {
        this.listResults = predictions;
    }

    public getPlaceDetails(result: any): void {
        if (this.isServiceAvailable()) {
            this.placesService.getDetails({ placeId: result.place_id }, this.fillInAddress);
        }
    }

    //way to handle google maps library loading async
    //quick way for now to avoid typescript implementation and reference errors
    //https://developers.google.com/maps/documentation/javascript/overview#js_api_loader_package
    public isServiceAvailable() {
        if (this.placesService) {
            return true;
        }

        if (google.maps.places) {
            this.placesService = new google.maps.places.PlacesService(window.document.createElement('div'));
            return true;
        }

        return false;
    }

    public fillInAddress(place: any, status: any): void {
        let address = '';
        place.address_components.forEach((comp: any) => {
            const addressType = comp.types[0];

            switch (addressType) {
                case 'street_number':
                case 'route':
                    address += comp['short_name'] != undefined ? ' ' + comp['short_name'] : ' ';
                    break;
            }
        });

        window['angularGooglePlaceDetailRef'].zone.run(() => {
            const comp = window['angularGooglePlaceDetailRef'].component;
            comp.isAddressFromGoogleApi = true;
            comp.setAddress.emit(place);
            comp.autocompleteInput = address ? address.trim() : '';
            comp.setAddressPickedFromBrowser.emit(comp.isAddressFromGoogleApi);
        });
    }

    public onKey(e: any): void {
        const key = e.keyCode;
        let $selected = this.listResults.findIndex(element => element.selected === true);

        if (key === 40) {
            if (!this.listResults.length || this.listResults.length <= $selected + 1) {
                $selected = 0;
            } else {
                $selected += 1;
            }
            for (let i = 0; i < this.listResults.length; i++) {
                if (i === $selected) {
                    this.listResults[i].selected = true;
                    continue;
                }
                this.listResults[i].selected = false;
            }
        } else if (key === 38) {
            if (!this.listResults.length || $selected <= 0) {
                $selected = this.listResults.length + 1;
            } else {
                $selected -= 1;
            }

            for (let i = 0; i < this.listResults.length; i++) {
                if (i === $selected) {
                    this.listResults[i].selected = true;
                    continue;
                }
                this.listResults[i].selected = false;
            }
        } else if (key === 13) {
            if (this.listResults.length > 0) {
                console.log(this.listResults[$selected]);
                this.getPlaceDetails(this.listResults[$selected]);
                this.listResults = [];
            }
            e.preventDefault();
        }
    }
}
