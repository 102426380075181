import { Injectable } from "@angular/core";
import { ParticipatingInsurerSettings } from '../../models/participating-insurer-settings';
import { CompareAndSendPageSettings } from '../../models/compare-send-page-settings';
import { HostSettings } from '../../models/host-settings';
import { EventSettings } from '../../models/event-settings';
import { LocaleSettings } from '../../models/locale-settings';


@Injectable({
    providedIn: "root"
})
export class CalculatorSettingsService {
    public participatingInsurerSettings: ParticipatingInsurerSettings =
        {
            customParticipatingInsurerFieldList: [], 
            enableSpanish: false, 
            enableChinese: false,
            findAnAgentButtonUrl: null, 
            findAnAgentButtonText: null,
            enableParticipatingInsurerField: true, 
            displayParticipatingInsurerField: true,
            defaultParticipatingInsurerFieldValue: '',
            isInsuranceProfessional: false,
        };
    public compareAndSendPageSettings: CompareAndSendPageSettings = { customCallToActionHtml: '' };
    public hostSettings: HostSettings = { styleSheet: 'eqa', channelOpen: true };
    public eventSettings: EventSettings = {
        locationChangeCallback: null, openWidgetChannelCallback: null, requestScrollIntoViewCallback: null,
        windowSizeCallback: null
    };
    public localeSettings: LocaleSettings = {
        defaultLocale: 'en',
        version: ''
    };

    
    public getDefaultSettings() {
        this.participatingInsurerSettings.defaultParticipatingInsurerFieldValue = '';
        this.participatingInsurerSettings.isInsuranceProfessional = window["isInsuranceProfessional"] ? window["isInsuranceProfessional"] : this.autoDetectInsuranceProfessional() ;
        this.participatingInsurerSettings.displayParticipatingInsurerField = true;
        this.participatingInsurerSettings.enableParticipatingInsurerField = true;
        this.participatingInsurerSettings.findAnAgentButtonText = null; // = "Find Your Agent";
        this.participatingInsurerSettings.findAnAgentButtonUrl = null;
        this.participatingInsurerSettings.enableSpanish = false;
        this.participatingInsurerSettings.enableChinese = false;
        this.participatingInsurerSettings.customParticipatingInsurerFieldList = []
        this.compareAndSendPageSettings.customCallToActionHtml = null;
        this.hostSettings.styleSheet = "eqa";
        this.hostSettings.channelOpen = true;

        // NOTE: The following settings must be set in premiumCalc.js when the widget is initialized and triggered
        // by messages from the iframe.
        this.eventSettings.locationChangeCallback = null;
        this.eventSettings.openWidgetChannelCallback = null;
        this.eventSettings.requestScrollIntoViewCallback = null;
        this.eventSettings.windowSizeCallback = null;

        this.localeSettings.defaultLocale = "en";
        const snapshot = new URL(window.location.href);
        const version = snapshot.searchParams.get('lv');
        this.localeSettings.version = version ? version : '';
    }

    public updateCalculatorSettings(fn: any): void {
        this.getDefaultSettings();

        window.addEventListener("message", (e) => {
            if (e.data && e.data.command === "setSettings" &&  e.data.settings){
                const settings = e.data.settings;
                
                this.getDefaultSettings();

                if (settings !== null) {
                    this.participatingInsurerSettings 
                        = {...this.participatingInsurerSettings, ...settings.participatingInsurerSettings};
                    this.compareAndSendPageSettings 
                        = {...this.compareAndSendPageSettings, ...settings.compareAndSendPageSettings};
                    this.hostSettings 
                        = {...this.hostSettings, ...settings.hostSettings};
                    this.eventSettings 
                        = {...this.eventSettings, ...settings.eventSettings};
                }

                if (this.participatingInsurerSettings.enableChinese) {
                    this.localeSettings.defaultLocale = "zh";
                } else if (this.participatingInsurerSettings .enableSpanish) {
                    this.localeSettings.defaultLocale = "es";
                }

                if (fn) {
                    fn();
                }
            }
         }, false)
    }

    //will only work outside of iframe
    public autoDetectInsuranceProfessional() {
        let re = new RegExp(/portal.*\.earthquakeauthority\.com/g);
        
        let arr = window.location.host.match(re);
        
        return arr && arr.length > 0;
    }

    public IsAgentOrPro() : boolean {
        return this.participatingInsurerSettings.isInsuranceProfessional;
    }
}
