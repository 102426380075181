<label for="street" class="cea-visible-xxs-block">{{'HOMEOWNERS.ADDRESS1_PLACEHOLDER' | translate }}</label>
<input class="form-control" type="text" [(ngModel)]="autocompleteInput" id="address1" name="street"
       placeholder="{{'HOMEOWNERS.ADDRESS1_PLACEHOLDER' | ceaPlaceholder: !isMobileView | translate }}" #addresstext
       style="padding: 0px 15px !important; border: thin solid #ccc; width: 400px;  margin: 0 0 0 !important;" (keyup)="getAddressFromInput($event)"
       attr.aria-label="{{'HOMEOWNERS.ADDRESS1_PLACEHOLDER' | translate}}"
       ebbGoogleAutocomplete (whenSelected)="openDropdown($event)" (focus)="onFocus($event)">
<div #resultDropDown *ngIf="listResults.length" id="resultDropDown" class="pac-container pac-logo">
       <div [class.pac-item-selected]="result.selected" class="pac-item" *ngFor="let result of listResults"
              (click)="getPlaceDetails(result)">
              <span class="pac-icon pac-icon-marker"></span>
              <span class="pac-item-query">{{result.description}}</span>
       </div>
</div>
