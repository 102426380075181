import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { TranslationComponent } from './translation/translation.component';
import { TreeFolderComponent } from './components/tree-folder/tree-folder.component';
import { AppMaterialComponentsModule } from './../app-material-components';
import { TranslationFormComponent } from './components/translation-form/translation-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewDraftComponent } from './components/new-draft/new-draft.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HistoryComponent } from './components/history/history.component';


@NgModule({
  declarations: [TranslationComponent, TreeFolderComponent, TranslationFormComponent, NewDraftComponent, LoadingComponent, HistoryComponent],
  imports: [
      CommonModule,
      AdminRoutingModule,
      AppMaterialComponentsModule,
      FormsModule,
      ReactiveFormsModule
  ]
})
export class AdminModule { }
