import { NgModule } from "@angular/core";
import { Routes, RouterModule, Router, NavigationEnd } from "@angular/router";
import { AnalyticsTrackingService } from './shared/services/tracking/tracking.service';
import { MsalGuard } from '@azure/msal-angular';


const routes: Routes = [
    { path: "",  children: [
        { path: "", loadChildren: () => import('./app-main/app-main.module').then(m => m.AppMainModule) }
    ]
    },
    {
        path: "home",  children: [
        { path: "", loadChildren: () => import('./app-main/app-main.module').then(m => m.AppMainModule) }
        ]
    },
    {
        path: "admin", children: [
            {
                path: "",
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
                //loadChildren: "./admin/admin.module#AdminModule",
                canActivate: [MsalGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        //enableTracing: true
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {

    constructor(
        private tracking: AnalyticsTrackingService,
        private router: Router
    ) {

        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                let pathName = val.url;
                this.tracking.trackLocationChange(pathName);
            }
        });
    }
}
