import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import { CalculatorSettingsService } from '../calculatorsettings/calculator-settings.service';

@Injectable({
    providedIn: "root"
})
export class PiValidatorsService {
    allPiNames = null;
    allPis = null;
    message = null;

    constructor(private translate: TranslateService,private calculatorSettingsService: CalculatorSettingsService) {
    }

    validate(startDate) {
      if (!startDate) {
        this.message = this.translate.instant("ERRORS.SELECT_POLICY_YEAR_FIRST");
      } else {
        this.validateParticipatingInsurers(startDate);
      }
      return !this.message;
    }

    GetPossiblePiValues() {
        // tslint:disable-next-line:only-arrow-functions
        this.allPiNames = this.GetAllPis("").map(function(pi) {
            return pi.Name;
        });
        return this.allPiNames;
    }

    private GetAllPis(defaultUrl: string) {
        const otherStringInArray = [{
            Name: "GLOBAL.OTHER",
            // tslint:disable-next-line:max-line-length
            Url: defaultUrl ? defaultUrl : "https://www.earthquakeauthority.com/California-Earthquake-Insurance-Policies/Participating-Residential-Insurers-Earthquake"
        }];

        if (!this.allPis) {
            // TODO: There needs to be a CEA-wide standard list for this:
            this.allPis = [
                //{
                //    Name: "ACA Insurance (AAA)",
                //    Url: "https://calstate.aaa.com/about-aaa/contact-us/find-agent-branch"
                //},
                {
                    Name: "Allstate",
                    Url: "https://agents.allstate.com/"
                },
                {
                    Name: "Amica Mutual Insurance Company",
                    Url: "https://www.amica.com/en.html"
                },
                {
                    Name: "Armed Forces Insurance Exchange",
                    Url: "https://www.afi.org/"
                },
                {
                    Name: "ASI-2016",
                    Url: "https://www.americanstrategic.com/"
                },
                {
                    Name: "Automobile Club of Southern California (AAA)",
                    Url: "https://www.calif.aaa.com"
                },
                {
                    Name: "CSAA-AAA Northern California",
                    Url: "https://calstate.aaa.com/about-aaa/contact-us/find-agent-branch"
                },
                {
                    Name: "California FAIR Plan",
                    Url: "https://www.cfpnet.com/index.php/find-a-broker/"
                },
                //{
                //    Name: "Commerce West",
                //    Url: "https://www.mapfreinsurance.com/b2cwebapp/cwic/home"
                //},
                {
                    Name: "Encompass",
                    Url: "https://www.encompassinsurance.com/agency-locator.aspx"
                },
                {
                    Name: "Farmers Insurance Group",
                    Url: "https://agents.farmers.com/search.html"
                },
                {
                    Name: "Foremost",
                    Url: "https://www.foremost.com/"
                },
                {
                    Name: "Hyundai",
                    Url: "http://www.hyundaiinsuranceusa.com/"
                },
                {
                    Name: "Liberty Mutual Group Business Insurance",
                    Url: "https://www.libertymutualgroup.com"
                },
                {
                    Name: "Liberty Mutual Insurance",
                    Url: "https://www.libertymutual.com/"
                },
                {
                    Name: "MAPFRE",
                    Url: "https://www.mapfreinsurance.com/"
                },
                {
                    Name: "Mercury",
                    Url: "https://www.mercuryinsurance.com/"
                },
                {
                    Name: "Nationwide Insurance",
                    Url: "https://agency.nationwide.com/ca"
                },
                {
                    Name: "Nationwide Private Client",
                    Url: "https://www.nationwideprivateclient.com/find-an-agent"
                },
                {
                    Name: "Progressive-2016",
                    Url: "https://www.progressive.com/agent/"
                },
                {
                    Name: "Safeco",
                    Url: "https://www.safeco.com/"
                },
                {
                    Name: "State Farm Insurance",
                    Url: "https://www.statefarm.com/"
                },
                {
                    Name: "Toggle",
                    Url: "https://www.gettoggle.com/"
                },
                {
                    Name: "USAA",
                    Url: "https://www.usaa.com/"
                }
            ];
        }
        const allPisWithOther = this.allPis.concat(otherStringInArray);
        if(this.calculatorSettingsService.participatingInsurerSettings !=null &&
            this.calculatorSettingsService.participatingInsurerSettings.customParticipatingInsurerFieldList.length !== 0){
                const tempList=[];
                this.calculatorSettingsService.participatingInsurerSettings.customParticipatingInsurerFieldList.forEach( l => {
                    allPisWithOther.forEach(ap => {
                        if(ap.Name === l){
                            tempList.push(ap);
                        }
                    });
                })
            return tempList;
        }else{
            return allPisWithOther;
        }
    }

    GetPiByName = function(piName, defaultUrl) {
        const pis = this.GetAllPis(defaultUrl);
        const piLower = piName.toLowerCase();

        let result = pis[pis.length - 1];
        result.Url = defaultUrl;

        for (let i = 0, len = pis.length; i < len; i++) {
            const piLowerCurrent = pis[i].Name.toLowerCase();

            if (piLower === piLowerCurrent || piLower + "-2016" === piLowerCurrent) {
                result = pis[i];
            }
        }
        return result;
    };

    private validateParticipatingInsurers(proposedPiValue) {
        const possibleValues = this.GetPossiblePiValues();

        // Allows an empty string because the field is not required.
        if (proposedPiValue && !this.isInList(possibleValues, proposedPiValue.trim())
            && proposedPiValue.trim() !== this.translate.instant("GLOBAL.OTHER")) {
            this.message = "Sorry, the participating insurer " + proposedPiValue.trim() + " is invalid.";
        } else if (!proposedPiValue) {
            this.message = this.translate.instant("ERRORS.COMPLETE_REQUIRED_FIELDS");
        }
    }

    private isInList(validValues, value) {
        return (validValues.indexOf(value) > -1 || validValues.indexOf(value + "-2016") > -1);
    }
}
