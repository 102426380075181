export class CalcInput {
    PropertyAddress: string;
    Address2: string;
    City: string;
    State: string;
    ZipCode: string;
    FullAddress: string;

    PolicyType: number;
    PolicyName: string;
    PolicyDate: Date;
    PolicyEffectiveDate: string;
    IsChoice: boolean;
    ReferringSite: string;
    ResidentialInsuranceCompany: string;
    TransactionTypeID: number|null;

    BreakablesCoverage: boolean;
    BuildingPropertyCoverage: number;
    BuildingPropertyDeductible: number;
    
    Headers: HeadersReq;
    IsDefault: number;
    IsFairMarketValueGreaterThan135K: boolean;
    LossAssessmentCoverage: number;
    LossAssessmentDeductible: number;
    LossOfUseCoverage?: number;
    PersonalPropertyCoverage?: number;
    PersonalPropertyDeductible?: number;
    
    
    
    
    BuildingCodeUpgradeLimitIncrease: number;
    HasEarthquakeResistantBracingSystem: boolean;
    YearBuilt: number;
    InsuredValueOfHome: number;
    MasonryVeneerCoverage: boolean;
    AreCrippleWallsBraced: boolean | string;
    Construction: number;  //aka is IsWoodFrame
    FoundationType: number;
    FoundationTypeName: string;
    HasCrippleWalls: string;
    IsHomeSecuredToFoundation: boolean;
    IsWaterHeaterSecuredToFrame: boolean;
    RoofType: string;
    RoofTypeName: string;
    NumberOfStories: number;
    VerifiedByEngineer: boolean;
    HrdStatus: string;
    HrdQualified: boolean;
    HrdDiscount: number;
    
    position?: number;
    yesCompare?: boolean;
    comparing?: boolean;
    AnnualPremium: number;
    MonthlyPremium: number;
    // WantsMasonryCoverage: boolean;
}

export class HeadersReq {
    SessionId: string;
    RequestKey: string;
    PolicyType: number;
    RequestType: string;
}
