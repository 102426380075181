import { Inject, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DraftNameService } from './../../services/draftname.service';

@Component({
    selector: 'app-new-draft',
    templateUrl: './new-draft.component.html',
    styleUrls: ['./new-draft.component.scss']
})
export class NewDraftComponent implements OnInit {
    public form: UntypedFormGroup;
    source: string;
    sourceName: string;

    constructor(
        public dialogRef: MatDialogRef<NewDraftComponent>,
        private readonly fb: UntypedFormBuilder,
        private draftnameService: DraftNameService,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        this.source = data.source;
        this.sourceName = this.draftnameService.DisplayName(this.source);
    }

    ngOnInit(): void {
        this.initializeForms();
    }

    private initializeForms(): void {
        this.form = this.fb.group({
            draft: ''
        });
    }

    public onClickClose(): void {
        this.dialogRef.close();
    }

    public onClickCreate(): void {
        this.dialogRef.close(this.form.get('draft').value);
    }
}
