import { NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

export class QuoterRequest {
    Key: string;
    State = "invalid";
    ValidStates = ["invalid", "valid.unquoted", "valid.quoting", "valid.quoted", "saved"];
    CurrentPromiseForQuote = null;
    CurrentRequestKey = null;

    // Simple Static Variables
    IsChoiceRequest = false;
    policytype: number;
    IsRenewal: boolean | null = null;

    ZipCode = null;
    StreetAddress = null;
    Address2 = null;
    City = null;
    AddressState = "CA";
    ParticipatingInsurer = "";

    IsInsuranceProfessional = false;
    StartYear = null;
    StartDate: NgbDateStruct = null;
    ActualStartDate = null;

    PossibleStartYearValues = null;
    numberOfStories = -1;
    InsuredValue = null;
    YearBuilt: number;
    foundationTypeVariable = -1;
    IsWoodFrame = true;
    HrdRequirementsConfirmed = false;
    roofTypeVariable = -1;
    HasInspection = false;
    HasBracingSystem = false;
    HrdRequirementsVerifiedByEngineer = false;
    IsDefault = 1;


    //Condo
    IsFairMarketValueOver135k = true;
    Wants25kBuildingPropertyCoverage: boolean;
    WantsBreakableCoverage = false;

    BuildingPropertyCoverageVariable: InteractiveVariable;
    BuildingPropertyDeductibleVariable: InteractiveVariable;

    PersonalPropertyCoverageVariable: InteractiveVariable;
    PersonalPropertyDeductibleVariable: InteractiveVariable;

    LossOfUseCoverageVariable: InteractiveVariable;

    LossAssessmentCoverageVariable: InteractiveVariable;
    LossAssessmentDeductibleVariable: InteractiveVariable;
}

export class InteractiveVariable {
    Index: number;
    Value: number;
}
