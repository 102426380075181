import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { RadioGroupSettings } from "../../shared/models/radioGroup.model";
import { HelperService } from "../../shared/services/helper/helper.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "[app-cea-radio-group]",
    templateUrl: "./cea-radio-group.component.html",
    styleUrls: ["./cea-radio-group.component.scss"]
})
export class CeaRadioGroupComponent implements OnInit {
    @Input() radioGroupSettings: RadioGroupSettings;
    bindModel: boolean;
    @Output() setRadioValue: EventEmitter<any> = new EventEmitter();
    @Output() moveTop: EventEmitter<string> = new EventEmitter();
    @ViewChild("label") label: ElementRef;
    groupkey = this.helper.GenerateGuid();

    constructor(private translate: TranslateService,
        private helper: HelperService) { }

    ngOnInit(): void {
        this.bindModel = this.radioGroupSettings.bindModel;
    }

    radioValueChanged() {
        this.setRadioValue.emit({ key: this.radioGroupSettings.radioKey, value: this.bindModel });
    }

    moveUp($event) {
        this.moveTop.emit(null);
    }
}
