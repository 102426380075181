import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Translation } from './../models/translation';
import { Observable, EMPTY, of  } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from "../../../environments/environment";


@Injectable({
    providedIn: "root"
})
export class TranslationService {
    host = 'https://localhost:44333/api/translation';

    constructor(private http: HttpClient) {
        this.host = environment.translationurl;
    }

    Drafts(): Observable<string[]> {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const options = {
            headers: headers
        };

        return this.http.get(this.host + '/drafts', options).pipe(map(response => {
            return response as string[];
        }),
            catchError((error) => {
                console.log(error);
                return EMPTY;
            })
        );
    }

    History(): Observable<string[]> {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const options = {
            headers: headers
        };

        return this.http.get(this.host + '/history', options).pipe(map(response => {
            return response as string[];
        }),
            catchError((error) => {
                console.log(error);
                return EMPTY;
            })
        );
    }

    Get(draft: string): Observable<Translation[]> {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const options = {
            headers: headers
        };

        return this.http.get(this.host + '/get?draft=' + draft, options).pipe(map(response => {
            return response as Translation[];
        }),
            catchError((error) => {
                console.log(error);
                return EMPTY;
            })
        );
    }

    Find(draft: string, path: string): Observable<Translation> {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const options = {
            headers: headers
        };

        return this.http.get(this.host + '/find?draft=' + draft + "&path=" + path, options).pipe(map(response => {
            return response as Translation;
        }),
            catchError((error) => {
                console.log(error);
                return EMPTY;
            })
        );
    }

    Draft(draft: string, source: string): Observable<string> {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const options = {
            headers: headers
        };

        return this.http.post(this.host + '/draft', options, {
            params: {
                "draft": draft,
                "source": source
            }
            }).pipe(map(response => {
            return response as string;
        }),
            catchError(err => of(err))
        );
    }

    Merge(draft: string): Observable<string> {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const options = {
            headers: headers
        };

        return this.http.put(this.host + '/merge', options, {
            params: {
                "draft": draft
            }
        }).pipe(map(response => {
            return response as string;
        }),
            catchError(err => of(err))
        );
    }

    Remove(draft: string): Observable<string> {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const options = {
            headers: headers
        };

        return this.http.put(this.host + '/delete', options, {
            params: {
                "draft": draft
            }
        }).pipe(map(response => {
            return response as string;
        }),
            catchError(err => of(err))
        );
    }

    Update(draft: string, translation: Translation): Observable<any> {
        const body = new FormData();
        body.append("draft", draft);
        body.append("path", translation.path);
        body.append("en", translation.english);
        body.append("es", translation.spanish);
        body.append("zh", translation.chinese);

        return this.http.put(this.host + '/update', body).pipe(
            map((response: Response) => response),
            catchError(err => of(err))
        );
    }

    Paths(parent: string, translations: Translation[]): Translation[] {
        for (let translation of translations) {
            translation.path = parent ? parent + "." + translation.label : translation.label;

            if (translation.children) {
                translation.children = this.Paths(translation.path, translation.children);
            }
        }

        return translations;
    }

    Request(draft: string): Observable<any> {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const options = {
            headers: headers
        };

        return this.http.put(this.host + '/request', options, {
            params: {
                "draft": draft
            }
        }).pipe(
            map((response: Response) => response),
            catchError(err => of(err))
        );
    }

    Me(): Observable<any> {
        return this.http.get("https://graph.microsoft.com/v1.0/me").pipe(map(response => {
            return response;;
        }),
            catchError((error) => {
                console.log(error);
                return EMPTY;
            })
        );
    }

}
