import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DraftNameService } from './../../services/draftname.service';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent {
    history: string[];

    constructor(
        public dialogRef: MatDialogRef<HistoryComponent>,
        private draftnameService: DraftNameService,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        this.history = data.history;
    }

    public onClickClose(): void {
        this.dialogRef.close();
    }

    public onClickView(): void {
        this.dialogRef.close((document.getElementById('history') as HTMLSelectElement).value);
    }

    public displayName(val: string): string {
        return this.draftnameService.DisplayName(val);
    }

}
