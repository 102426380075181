import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Options } from '@angular-slider/ngx-slider';
import { SliderSettings } from "../../shared/models/slider.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "[app-cea-slider]",
    templateUrl: "./cea-slider.component.html",
    styleUrls: ["./cea-slider.component.scss"]
})
export class CeaSliderComponent implements OnInit {
    @Input() sliderSettings: SliderSettings;
    @Output() moveTop: EventEmitter<string> = new EventEmitter();
    @Output() setSliderValue: EventEmitter<any> = new EventEmitter();
    value: number = 0;
    options: Options = null;
    errorMessage = "";
    errorMessageReplaceText = '';
    sliderIdPrefix = "";


    constructor(public translate: TranslateService) {
    }


    ngOnInit(): void {
        this.sliderIdPrefix = this.sliderSettings.sliderKey;
        this.value = this.sliderSettings.displayValue;
        if (this.sliderSettings.isStepArrayRequired === false) {
            this.options = {
                step: this.sliderSettings.step,
                floor: this.sliderSettings.floor,
                ceil: this.sliderSettings.ceil,
                ariaLabelledBy: this.sliderIdPrefix + 'Label',
                showTicks: true,
                hideLimitLabels: true,
                hidePointerLabels: true,
                readOnly: false,
                disabled: this.sliderSettings.disabled,
                reversedControls: this.sliderSettings.isReversed,
            };
        } else {
            this.options = {
                step: this.sliderSettings.step,
                stepsArray: this.sliderSettings.stepsArray,
                showTicks: true,
                ariaLabelledBy: this.sliderIdPrefix + 'Label',
                hideLimitLabels: true,
                hidePointerLabels: true,
                readOnly: this.sliderSettings.readonly,
                disabled: this.sliderSettings.disabled,
                reversedControls: this.sliderSettings.isReversed,
            };
        }
    }

    ngAfterContentChecked() {
        this.errorMessage = this.sliderSettings.errorMessage;
        this.errorMessageReplaceText = this.sliderSettings.errorMessageReplaceText;
    }

    sliderValueChanged(key) {
        this.setSliderValue.emit({ key, value: this.value });
    }

    moveUp($event) {
        this.moveTop.emit(null);
    }
}
