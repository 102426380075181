import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { QuoteStorageService } from '../quote-storage/quote-storage.service';

@Injectable({
    providedIn: "root"
})
export class SessionService {

    constructor(
        private quoteStorageService: QuoteStorageService,
        private router: Router) {
    }


    //dont love this solution
    //have to create a new event per controller in order to save local values from controller
    //at some point will visit to refactor because it simply not intutive to understand
    listenUnload = function (state: any, fn: any): void {
        //where session info is stored if user navigates away
        //detect browser, IOS 13 and above uses a different event
        let eventName = this.isIos() ? "pagehide" : "beforeunload";

        //listen for page leave and store values to storage
        window.addEventListener(eventName, function (event) {
            const baseUrl = this.router.url.split('?')[0];
            this.handleUnload(state, baseUrl, fn, event);
        }.bind(this));
    }

    handleUnload(state: any, baseUrl: any, fn: any, event: any) {
        //have multiple listeners, save when matches state
        if (Array.isArray(state)) {
            let match = false;
            for (const part of state) {
                if (baseUrl === part) {
                    match = true;
                }
            }

            if (!match) {
                return true;
            }
        }
        else {
            if (baseUrl !== state) {
                return;
            }
        }

        if (fn) {
            fn(event);
        }
    }

    isIos() {
        return window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i) || (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1);
    }

    getRelativeUrl() {
        return this.router.url.split('?')[0];
    }

    saveSession = function (params: any): void {
        this.quoteStorageService.SaveState(this.router.url.split('?')[0])
            .SaveStateParams(params);
    }

    restoreSession = function (): void {
        const state = this.quoteStorageService.GetState();
        const params = this.quoteStorageService.GetStateParams();

        if (state) {
            if (params) {
                this.router.navigate([state.split('?')[0]], { queryParams: params });
            }
            else {
                this.router.navigate([state.split('?')[0]]);
            }
        }
        else {
            this.router.navigate([""]);
        }
        
    }
}
