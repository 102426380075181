<h4 mat-dialog-title>History</h4>

<div mat-dialog-content>
    <select id="history" name="history" style="width: 200px;">
        <option *ngFor="let h of history" [value]="h">{{displayName(h)}}</option>
    </select>
</div>

<div mat-dialog-actions style="margin-top: 9px !important; float: right !important;">
    <button mat-button
            style="margin-right: 9px;"
            (click)="onClickClose()">
        Cancel
    </button>
    <button mat-raised-button
            color="primary"
            (click)="onClickView()">
        View
    </button>
</div>
