<div class="form-group radio-group"  [ngClass]="{'cea-disable': radioGroupSettings.disabled}">
    <div class="cea-radio-row">
        <div class="cea-radio-labels" #label>
            <label class="slider-caption cea-hidden-xss cea-hidden-xxs cea-hidden-xs">{{radioGroupSettings.caption | translate | uppercase}}</label>
            <label class="slider-caption cea-visible-xss cea-visible-xxs cea-visible-xs cea-hidden-sm cea-hidden-md cea-hidden-lg">{{radioGroupSettings.shortCaption | translate | uppercase}}</label>
        </div>
        <div class="cea-radio-buttons">
            <div class="radio-group-accessories">
                <app-cea-help-button [stype]="'radio'"
                                     [title]="radioGroupSettings?.helpSlug.title"
                                     [text1]="radioGroupSettings?.helpSlug.text"
                                     (moveTop)="moveUp($event)">
                </app-cea-help-button>

                <label class="radio-inline button-caption">
                    <input name="radioGroup{{groupkey}}" [id]="'radioTrue_' + radioGroupSettings.radioKey" [(ngModel)]="bindModel" [value]="true" (change)="radioValueChanged()"
                           type="radio">{{'GLOBAL.YES' | translate }}
                </label>
                <label class="radio-inline button-caption no-caption">
                    <input name="radioGroup{{groupkey}}" [id]="'radioFalse_' + radioGroupSettings.radioKey" [(ngModel)]="bindModel" [value]="false" (change)="radioValueChanged()"
                           type="radio">{{'GLOBAL.NO' | translate }}
                </label>
            </div>
        </div>
    </div>
    <div class="after"></div>
</div>
