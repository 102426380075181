import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { moduleType } from "../../models/module.type";
import { QuoterRequest } from "../../models/quoterRequest.model";
import { CalcDatesService } from "../calc-dates/calc-dates.service";

//NOTE: These constant values coincide with translations, changing will break translation process used below
export const HRD_STATUS_NA = "COMPARE_SEND.FIELDS.NA";
export const HRD_STATUS_DOES_NOT_QUALIFY = "COMPARE_SEND.FIELDS.DOES_NOT_QUALIFY";
export const HRD_STATUS_QUALIFY = "COMPARE_SEND.FIELDS.QUALIFIED";
export const HRD_STATUS_MAY_QUALIFY = "COMPARE_SEND.FIELDS.MAY_QUALIFY";
export const HRD_STATUS_VERIFIED = "COMPARE_SEND.FIELDS.VERIFIED";
export const HRD_STATUS_SELFVERIFIED = "COMPARE_SEND.FIELDS.SELFVERIFIED";

@Injectable({
    providedIn: "root"
})
export class HazardReductionService {
    constructor(private translate: TranslateService,
        private calcDatesService: CalcDatesService) {

    }

    GetHrdQualificationStatus(quoterRequest: QuoterRequest): any {
        if (quoterRequest.policytype === moduleType.renter) {
            return this.renterStatus(quoterRequest);
        }

        if (quoterRequest.policytype === moduleType.condo) {
            return this.condoStatus(quoterRequest);
        }

        if (quoterRequest.policytype === moduleType.mobilehome) {
            return this.mobilehomeStatus(quoterRequest);
        }

        if (quoterRequest.policytype === moduleType.homeowner) {
            return this.homeownerStatus(quoterRequest);
        }

        return null;
    }

    private renterStatus(quoterRequest: QuoterRequest) {
        return {
            status: HRD_STATUS_NA,
            qualified: false,
            discount: 0
        };
    }

    private condoStatus(quoterRequest: QuoterRequest) {
        return this.renterStatus(quoterRequest);
    }

    private homeownerStatus(quoterRequest: QuoterRequest) {
            return this.homeowner2019Status(quoterRequest);
    }

    private homeowner2019Status(quoterRequest: QuoterRequest) {
        if (!this.isHomeowner2019Qualified(quoterRequest)) {
            return {
                status: HRD_STATUS_DOES_NOT_QUALIFY,
                qualified: false,
                discount: 0
            };
        }

        let discount = 0;
        if (quoterRequest.foundationTypeVariable === 1) {
            discount = quoterRequest.YearBuilt <= 1939 ? 25 : 20;
        }

        if (quoterRequest.foundationTypeVariable === 2) {
            discount = quoterRequest.YearBuilt <= 1939 ? 15 : 10;
        }

        return {
            status: HRD_STATUS_MAY_QUALIFY,
            qualified: true,
            discount: discount
        };
    }


    public isHomeowner2019Qualified(quoterRequest: QuoterRequest): boolean {
        const isYearBuiltWithinRange = quoterRequest.YearBuilt <= 1979;
        const isFoundationTypeRaised = quoterRequest.foundationTypeVariable === 1 || quoterRequest.foundationTypeVariable === 2;
        return isYearBuiltWithinRange &&
            isFoundationTypeRaised &&
            quoterRequest.IsWoodFrame &&
            quoterRequest.HrdRequirementsConfirmed &&
            quoterRequest.HrdRequirementsVerifiedByEngineer;
    }


    private mobilehomeStatus(quoterRequest: QuoterRequest) {
        return this.mobilehome2019Status(quoterRequest);
    }

    public mobilehome2019Status(quoterRequest: QuoterRequest) {
        if (!this.isMobilehome2019Qualified(quoterRequest)) {
            return {
                status: HRD_STATUS_DOES_NOT_QUALIFY,
                qualified: false,
                discount: 0
            };
        }

        return {
            status: HRD_STATUS_MAY_QUALIFY,
            qualified: true,
            discount: 21
        };
    }


    private isMobilehome2019Qualified(quoterRequest: QuoterRequest): boolean {
        return quoterRequest.HasBracingSystem && quoterRequest.HasInspection;

    }

}
