import { Injectable } from "@angular/core";
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: "root"
})
export class CustomDateParserFormatterService {

  readonly DELIMITER = "/";

  //format date in MM/DD/YYYY
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        month : parseInt(date[0], 10),
        day : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ?  date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : "";
  }
}
