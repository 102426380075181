import { Component, OnInit, Input, NgZone, ChangeDetectorRef } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslationService } from "../../shared/services/translation/translation.service";
import { TranslateService } from "@ngx-translate/core";
import { HelperService } from "../../shared/services/helper/helper.service";
import { ActivatedRoute, Router } from "@angular/router";
import { QuoterRequest2Service } from "../../shared/services/quote-request/quote-request2.service";
import { QuoterResponse } from "../../shared/models/QuoterResponse.model";
import { QuoterRequest } from "../../shared/models/quoterRequest.model";
import { CalcInput } from "../../shared/models/CalcInput.model";
import { CompareProperties } from "../../shared/models/compareTypes.model";
import { environment } from "../../../environments/environment";
import { moduleType } from "../../shared/models/module.type";
import { Email } from "../../shared/models/emailModel.model";
import moment from 'moment';
import { PopupComponent } from '../popup/popup.component';
import { HeaderForHeader, QuoteForPdf, ComparePdfModel, LabelForPdf } from 'src/app/shared/models/ComparePdf.model';
import { language } from 'src/app/shared/models/language.model';
import { PiValidatorsService } from 'src/app/shared/services/validators/pi-validators.service';
import { AnalyticsTrackingService } from 'src/app/shared/services/tracking/tracking.service';
import { PrintoutService } from "../../shared/services/printout/printout.service";
import { SessionService } from "../../shared/services/session/session.service";
import { EstimateBuilderService } from "../../shared/services/estimate-builder/estimate-builder.service";
import { PrintBuilderService } from "../../shared/services/print-builder/print-builder.service";
import { DownloadFilePostModel } from "../../shared/models/Download.model";
import { CalcDatesService } from "../../shared/services/calc-dates/calc-dates.service";

@Component({
    selector: "app-email-popup",
    templateUrl: "./email-popup.component.html",
    styleUrls: ["./email-popup.component.scss"]
})
export class EmailPopupComponent implements OnInit {

    @Input() fromParent;

    constructor(
        private translationService: TranslationService,
        private translate: TranslateService,
        private helper: HelperService,
        private modalService: NgbModal,
        private router: Router,
        public zone: NgZone,
        private changeDetector: ChangeDetectorRef,
        private quoterRequestService: QuoterRequest2Service,
        private piValidatorsService: PiValidatorsService,
        private trackingService: AnalyticsTrackingService,
        private printoutService: PrintoutService,
        private printBuilderService: PrintBuilderService,
        private estimateBuilderService: EstimateBuilderService,
        private sessionService: SessionService,
        private calcDatesService: CalcDatesService,
        private route: ActivatedRoute,
        public activeModal: NgbActiveModal
    ) {

    }

    quote: QuoterRequest;
    firstSelectedPolicy = null;
    quotes: QuoterResponse[] = [];
    calcInput: CalcInput = new CalcInput();
    compareCollection: CompareProperties[] = [];
    pCompareCollection: CompareProperties[] = [];
    showErrorSentToEmail = false;
    showTable = false;
    eqaUrl = environment.eqasiteurl;
    isMobileView: boolean;
    ids: number[] = [];
    viewableIds: number[] = [];
    lang = language;
    participatingInsurersUrl = environment.eqasiteurl + environment.participatinginsurersurl;
    riskpreparednessurl = environment.eqasiteurl + environment.riskpreparednessurl;
    email: Email = new Email();
    modules = moduleType;
    selectedQuotes = [];
    deductible = 0;
    table = [];
    page = 1;
    pages = 1;
    perPage = 4;
    renderLandscape = false;
    policy = null;
    monthly = null;
    annually = null;
    firstSelectedPolicyDate = null;
    quotesList = [];
    findAgentUrl = "";
    showSuccess = false;
    showSpin = false;
    showErrorToEmail = false;
    isQuote2019 = null;
    comparepdf: ComparePdfModel = null;
    sendToConfig = {
        placeholder: this.translate.instant("EMAIL.SENDTO")
        // "EMAIL.SENDTO_SMALL"   width > 479
    };

    ngOnInit() {
        this.init();
        this.email.message = this.translate.instant("EMAIL.DEFAULT_CONTENT");
    }

    startOver() {
        this.closeModal('close');
        this.quoterRequestService.resetAllData();
        this.quoterRequestService.deleteAllCalculations();
        this.router.navigate(['']);
    }

    init() {
        this.quote = this.quoterRequestService.getSavedQuoterRequest();
        this.quotesList = this.quoterRequestService.getSavedQuotes();
        if (this.quotesList && this.quotesList.length > 0) {
            let position = 1;
            this.findAgentUrl = this.piValidatorsService.GetPiByName(this.quotesList[0].quote.ParticipatingInsurer.trim(), this.translate.instant("GLOBAL.PARTICIPATING_INSURER_URL").toLocaleLowerCase()).Url;
            (this.quotesList).forEach((value) => {
                value.quoterResponse.PolicyType = this.setPolicyTypeTranslation(value.quote.policytype, value.quoterResponse.PolicyType);
                this.quotes.push(value.quoterResponse);
                value.calcInput.position = position;
                position++;
            });

            this.selectCalculation(-1);
        }
    }

    //an area broken, this is a corrective routine but the real solution would be to fix the data prior
    setPolicyTypeTranslation(policytype, policyTypeName): string {
        if (policytype !== 1) {
            if (policytype === 4) {
                return (policyTypeName === 'MobileStandard' || policyTypeName === 'Mobilehome') ?
                    this.translate.instant("GLOBAL.Mobilehome") :
                    this.translate.instant("GLOBAL.Mobilehome Choice");
            }
            else {
                return policytype === 2 ? this.translate.instant("GLOBAL.Renters") : this.translate.instant("GLOBAL.CONDO_OWNERS");
            }
        }
        else {
            if (["Homeowners", "HomeStandard"].indexOf(policyTypeName) !== -1) {
                return this.translate.instant("GLOBAL.Homeowners");
            }
            else {
                return this.translate.instant("GLOBAL.Homeowners Choice");
            }
        }
    }

    sortEstimates(id) {
        if (id == -1) {
            this.ids = this.quoterRequestService.comparePageSelectedId;
        } else {

            if (this.ids.indexOf(id) === -1) {
                this.ids.push(id);
                this.ids.sort((a, b) => (a - b));
            } else {
                const index = this.ids.indexOf(id);
                if (index > -1) {
                    this.ids.splice(index, 1);
                }
            }
        }
    }

    selectEstimateMessage(id, titleTransKey, msgTransKey) {
        this.openHelperPopup(titleTransKey, msgTransKey);
        const ind = this.ids.indexOf(id);
        if (ind > -1) {
            this.ids.splice(ind, 1);
        }
    }

    selectCalculation(id) {
        this.sortEstimates(id);
        this.table = [];
        this.showTable = false;
        this.compareCollection = new Array(this.ids.length);

        (this.ids).forEach((value, index) => {
            let compareProperties: CompareProperties = {
                calcInput: this.quotesList[value].calcInput,
                quote: this.quotesList[value].quote,
                MonthlyPremium: this.quotesList[value].quoterResponse.MonthlyPremium,
                AnnualPremium: this.quotesList[value].quoterResponse.AnnualPremium,
                quoterResponse: this.quotesList[value].quoterResponse
            };

            if (index === 0) {
                this.firstSelectedPolicy = compareProperties.quote.policytype;
                this.firstSelectedPolicyDate = compareProperties.quote.ActualStartDate;
            }

            this.compareCollection[index] = (compareProperties);
            this.deductible = compareProperties.calcInput.PersonalPropertyDeductible;
            if (this.ids.length === 1) {
                this.policy = compareProperties.quote.policytype;
            }
            else {
                if (this.firstSelectedPolicy === compareProperties.quote.policytype) {
                    this.policy = this.firstSelectedPolicy;
                }
                else {
                    this.selectEstimateMessage(id, "COMPARE_SEND.COMPARE_POLICIES_WARNING_TITLE", "COMPARE_SEND.COMPARE_POLICIES_WARNING");
                    return;
                }
            }
        });

        if (this.policy === 1) {
            this.generateTableHomeTable(this.deductible);
        }
        if (this.policy === 2) {
            this.generateTableRenterTable();
        }
        if (this.policy === 3) {
            this.generateTableCondoTable();
        }
        if (this.policy === 4) {
            this.generateTableMobileTable();
        }
        setTimeout(() => {
            this.showTable = true;
        }, 1000);

    }

    openHelperPopup(title: string, text: string, text2: string = null, text3: string = null) {
        const header = this.translate.instant(title);
        let body = this.translate.instant(text);

        if (text2 !== null) {
            body += this.translate.instant(text2);

            if (text3 !== null) {
                body += this.translate.instant(text3);
            }
        }
        const data = {
            header,
            body,
            showTopCloseButton: false
        };
        const size = "xl";
        this.openModal(data, size);
    }


    generateTableRenterTable() {
        const tableProperties = [
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_DETAILS"),
                value: '',// this.translate.instant("GLOBAL.Renter"),
                type: "norm",
                class: "first",
                valueClass: "first"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_TYPE"),
                value: this.translate.instant("GLOBAL.Renter"),
                type: "index",
                valueClass: "first",
                key: "policyType"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.ZIP"), value: "ZipCode", type: "quote", key: "zipCode" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ESTIMATE_DATE"),
                value: moment().format("MM/DD/YYYY"),
                type: "norm",
                key: "estimateDate"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.EFFECTIVE_START"),
                value: "PolicyEffectiveDate",
                type: "calcInput",
                detailType: "date",
                key: "estimateStart"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.PERSONAL_PROPERTY"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "PersonalPropertyCoverage",
                type: "calcInput",
                unit: "$",
                key: "personalPropertyCoverage"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "PersonalPropertyCoverage",
                m2: "PersonalPropertyDeductible",
                m1type: "calcInput",
                m2type: "calcInput",
                unit: "$",
                key: "personalPropertyDeductibleAmount"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "PersonalPropertyDeductible",
                type: "calcInput", unit: "%",
                key: "personalPropertyDeductiblePercent"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.BREAKABLE_COVERAGE"),
                value: "BreakablesCoverage",
                type: "calcInput",
                key: "breakableCoverage"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.LOSS_OF_USE"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "LossOfUseCoverage",
                type: "calcInput",
                unit: "$",
                key: "lossOfUseCoverage"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.PREMIUM"), value: "", type: "" },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.MONTHLY"), value: "MonthlyPremium", type: "summary", unit: "$", key: "premiumMonthly" },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.ANNUAL"), value: "AnnualPremium", type: "summary", unit: "$", key: "premiumAnnual" },
        ];
        this.generateTable(this.frfTable(tableProperties));
    }

    generateTableHomeTable(deductible) {
        const tableProperties = [
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_DETAILS"),
                value: this.translate.instant("GLOBAL.Homeowners"),
                type: "norm",
                class: "first",
                valueClass: "first",
                key: "policyDetail"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_TYPE"),
                value: deductible > 0 ? this.translate.instant("GLOBAL.Choice") : this.translate.instant("GLOBAL.Homeowners"),
                type: "index",
                valueClass: "first",
                key: "policyType"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ZIP"), value: "ZipCode", type: "quote",
                key: "zipCode"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.YEAR_BUILT"), value: "YearBuilt", type: "quote", key: "yearBuilt" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.FOUNDATION_TYPE"), value: "foundationTypeVariable", type: "quote",
                key: "foundationType"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ROOF_TYPE"), value: "roofTypeVariable", type: "quote",
                key: "roofTypeVariable"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ROOF_TYPE"), value: "roofTypeVariable", type: "quote",
                key: "roofType"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.FRAME"), value: "IsWoodFrame", type: "quote",
                key: "frame"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.NUMBER_OF_STORIES"), value: "numberOfStories", type: "quote",
                key: "stories"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.MANSONRY_VENEER_COVERAGE"),
                value: "MasonryVeneerCoverage",
                type: "calcInput",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("GLOBAL.YES"),
                displayfalseValue: this.translate.instant("GLOBAL.NO"),
                key: "masonryCoverage"
            },

            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ESTIMATE_DATE"),
                value: moment().format("MM/DD/YYYY"),
                type: "norm",
                key: "estimateDate"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.EFFECTIVE_START"),
                value: "PolicyEffectiveDate",
                type: "calcInput",
                detailType: "date",
                key: "estimateStart"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.DWELLING"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "InsuredValue",
                type: "quote",
                unit: "$",
                key: "dwellingCoverage"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "BuildingPropertyDeductible",
                m1type: "calcInput",
                m2: "InsuredValue",
                m2type: "quote",
                unit: "$",
                key: "dwellingDeductibleAmount"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "BuildingPropertyDeductible",
                type: "calcInput",
                unit: "%",
                key: "dwellingDeductiblePercent"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.BUILDING_CODE_UPGRADE"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "BuildingCodeUpgradeLimitIncrease",
                type: "calcInput",
                unit: "$",
                key: "buildingCodeCoverage"

            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.PERSONAL_PROPERTY"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "PersonalPropertyCoverage",
                type: "calcInput",
                unit: "$",
                key: "personalPropertyCoverage"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "PersonalPropertyCoverage",
                m1type: "calcInput",
                m2: "PersonalPropertyDeductible",
                m2type: "calcInput",
                unit: "$",
                key: "personalPropertyDeductibleAmount"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "PersonalPropertyDeductible",
                type: "calcInput", unit: "%",
                key: "personalPropertyDeductiblePercent"
            },


            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.BREAKABLE_COVERAGE"),
                value: "BreakablesCoverage",
                type: "calcInput",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("GLOBAL.YES"),
                displayfalseValue: this.translate.instant("GLOBAL.NO"),
                key: "breakableCoverage"
            },


            { label: this.translate.instant("COMPARE_SEND.FIELDS.LOSS_OF_USE"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"), value: "LossOfUseCoverage", type: "calcInput", unit: "$",
                key: "lossOfUseCoverage"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.HAZARD_REDUCTION"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.STATUS"),
                value: "HasBracingSystem",
                type: "quote",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("COMPARE_SEND.FIELDS.MAY_QUALIFY"),
                displayfalseValue: this.translate.instant("COMPARE_SEND.FIELDS.DOES_NOT_QUALIFY"),
                key: "hazardStatus"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DISCOUNT"),
                value: "HasBracingSystem",
                type: "quote",
                detailtype: "boolean",
                displaytrueValue: "21%",
                displayfalseValue: "",
                key: "hazardDiscount"
            },


            { label: this.translate.instant("COMPARE_SEND.FIELDS.PREMIUM"), value: "", type: "", class: "first" },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.MONTHLY"), value: "MonthlyPremium", type: "summary", unit: "$", key: "premiumMonthly" },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.ANNUAL"), value: "AnnualPremium", type: "summary", unit: "$", key: "premiumAnnual" }
        ];
        this.generateTable(this.frfTable(tableProperties));
    }


    generateTableMobileTable() {
        const hrd = [
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.STATUS"),
                value: "HasBracingSystem",
                type: "quote",
                detailtype: "boolean", key: "hazardStatus", show: true
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DISCOUNT"),
                value: "HasBracingSystem",
                type: "quote",
                detailtype: "boolean",
                key: "hazardDiscount"
            }]
        const tableProperties = [
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_DETAILS"),
                value: this.translate.instant("GLOBAL.Mobilehome"),
                type: "norm",
                class: "first",
                valueClass: "first",
                key: "policyDetail"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_TYPE"),
                value: this.translate.instant("GLOBAL.Mobilehome"),
                type: "index",
                valueClass: "first",
                key: "policyType",
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ZIP"), value: "ZipCode", type: "quote",
                key: "zipCode"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.BRACING_SYSTEM"), value: "HasBracingSystem", type: "quote",
                key: "HasBracingSystem",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("GLOBAL.YES"),
                displayfalseValue: this.translate.instant("GLOBAL.NO"),
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.ESTIMATE_DATE"),
                value: moment().format("MM/DD/YYYY"),
                type: "norm",
                key: "estimateDate"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.EFFECTIVE_START"),
                value: "PolicyEffectiveDate",
                type: "calcInput",
                detailType: "date",
                key: "estimateStart"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.YEAR_BUILT"), value: "YearBuilt", type: "quote",
                key: "yearBuilt"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.DWELLING"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "InsuredValue",
                type: "quote",
                unit: "$",
                key: "dwellingCoverage"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "BuildingPropertyDeductible",
                m1type: "calcInput",
                m2: "InsuredValue",
                m2type: "quote",
                unit: "$",
                key: "dwellingDeductibleAmount"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "BuildingPropertyDeductible",
                type: "calcInput",
                unit: "%",
                key: "dwellingDeductiblePercent"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.BUILDING_CODE_UPGRADE"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "BuildingCodeUpgradeLimitIncrease",
                type: "calcInput",
                unit: "$",
                key: "buildingCodeCoverage"
            },


            { label: this.translate.instant("COMPARE_SEND.FIELDS.PERSONAL_PROPERTY"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "PersonalPropertyCoverage",
                type: "calcInput",
                unit: "$",
                key: "personalPropertyCoverage"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "PersonalPropertyCoverage",
                m1type: "calcInput",
                m2: "PersonalPropertyDeductible",
                m2type: "calcInput",
                unit: "$",
                key: "personalPropertyDeductibleAmount"
            },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "PersonalPropertyDeductible",
                type: "calcInput", unit: "%",
                key: "personalPropertyDeductiblePercent"
            },


            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.BREAKABLE_COVERAGE"),
                value: "BreakablesCoverage",
                type: "calcInput",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("GLOBAL.YES"),
                displayfalseValue: this.translate.instant("GLOBAL.NO"),
                key: "breakableCoverage"
            },


            { label: this.translate.instant("COMPARE_SEND.FIELDS.LOSS_OF_USE"), value: "", type: "", class: "first" },
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "LossOfUseCoverage",
                type: "calcInput",
                unit: "$",
                key: "lossOfUseCoverage"
            },

            { label: this.translate.instant("COMPARE_SEND.FIELDS.HAZARD_REDUCTION"), value: "", type: "", class: "first" },
            ...hrd,
            { label: this.translate.instant("COMPARE_SEND.FIELDS.PREMIUM"), value: "", type: "", class: "first" },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.MONTHLY"), value: "MonthlyPremium", type: "summary", unit: "$", key: "premiumMonthly" },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.ANNUAL"), value: "AnnualPremium", type: "summary", unit: "$", key: "premiumAnnual" },
        ];
        this.generateTable(this.frfTable(tableProperties));
    }

    generateTableCondoTable() {
        const tableProperties = [
            {
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_DETAILS"),
                value: this.translate.currentLang === 'en' ? this.translate.instant("GLOBAL.Condo") : '',
                type: "norm",
                class: "first",
                valueClass: "first",
                key: "policyDetail"
            },
            {
                key: "policyType",
                label: this.translate.instant("COMPARE_SEND.FIELDS.POLICY_TYPE"),
                value: this.translate.currentLang === 'en' ? this.translate.instant("COMPARE_SEND.OWNERS") : this.translate.instant("GLOBAL.CONDO_OWNERS"),
                type: "index",
                valueClass: "first"
            },
            {
                key: "zipCode",
                label: this.translate.instant("COMPARE_SEND.FIELDS.ZIP"), value: "ZipCode", type: "quote"
            },
            {
                key: "estimateDate",
                label: this.translate.instant("COMPARE_SEND.FIELDS.ESTIMATE_DATE"),
                value: moment().format("MM/DD/YYYY"),
                type: "norm"
            },
            {
                key: "estimateStart",
                label: this.translate.instant("COMPARE_SEND.FIELDS.EFFECTIVE_START"),
                value: "PolicyEffectiveDate",
                type: "calcInput",
                detailType: "date"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.BUILDING_PROPERTY"), value: "", type: "", class: "first" },
            {
                key: "dwellingCoverage",
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "BuildingPropertyCoverage",
                type: "calcInput",
                unit: "$"
            },
            {
                key: "dwellingDeductibleAmount",
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "BuildingPropertyCoverage",
                m1type: "calcInput",
                m2: "BuildingPropertyDeductible",
                m2type: "calcInput",
                unit: "$"
            },
            {
                key: "dwellingDeductiblePercent",
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "BuildingPropertyDeductible",
                type: "calcInput",
                unit: "%"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.PERSONAL_PROPERTY"), value: "", type: "", class: "first" },
            {
                key: "personalPropertyCoverage",
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"),
                value: "PersonalPropertyCoverage",
                type: "calcInput",
                unit: "$"
            },
            {
                key: "personalPropertyDeductibleAmount",
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)",
                value: "",
                type: "Calculate",
                m1: "PersonalPropertyCoverage",
                m1type: "calcInput",
                m2: "PersonalPropertyDeductible",
                m2type: "calcInput",
                unit: "$"
            },
            {
                key: "personalPropertyDeductiblePercent",
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "PersonalPropertyDeductible",
                type: "calcInput", unit: "%"
            },
            {
                key: "breakableCoverage",
                label: this.translate.instant("COMPARE_SEND.FIELDS.BREAKABLE_COVERAGE"),
                value: "BreakablesCoverage",
                type: "calcInput",
                detailtype: "boolean",
                displaytrueValue: this.translate.instant("GLOBAL.YES"),
                displayfalseValue: this.translate.instant("GLOBAL.NO")
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.LOSS_OF_USE"), value: "", type: "", class: "first" },
            {
                key: "lossOfUseCoverage",
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"), value: "LossOfUseCoverage", type: "calcInput", unit: "$"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.LOSS_ASSESSMENT"), value: "", type: "", class: "first" },
            {
                key: "lossAssessmentCoverage",
                label: this.translate.instant("COMPARE_SEND.FIELDS.COVERAGE"), value: "LossAssessmentCoverage", type: "calcInput", unit: "$"
            },
            {
                key: "lossAssessmentDeductibleAmount",
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " ($)", value: "", type: "", unit: "$"
            },
            {
                key: "lossAssessmentDeductiblePercent",
                label: this.translate.instant("COMPARE_SEND.FIELDS.DEDUCTIBLE") + " (%)",
                value: "LossAssessmentDeductible",
                type: "calcInput", unit: "%"
            },
            { label: this.translate.instant("COMPARE_SEND.FIELDS.PREMIUM"), value: "", type: "" },
            { key: "premiumMonthly", label: this.translate.instant("COMPARE_SEND.FIELDS.MONTHLY"), value: "MonthlyPremium", type: "summary", unit: "$" },
            { key: "premiumAnnual", label: this.translate.instant("COMPARE_SEND.FIELDS.ANNUAL"), value: "AnnualPremium", type: "summary", unit: "$" },
        ];
        this.generateTable(this.frfTable(tableProperties));
    }

    frfTable(tableProperties) {
        //prune frfMode disabled fields here
        if (this.isTableFrfMode()) {
            const breakableIndex = tableProperties.map(function (e) { return e.key; }).indexOf('breakableCoverage');
            if (breakableIndex && breakableIndex > -1) {
                tableProperties.splice(breakableIndex, 1);
            }
            const masonryIndex = tableProperties.map(function (e) { return e.key; }).indexOf('masonryCoverage');
            if (masonryIndex && masonryIndex > -1) {
                tableProperties.splice(masonryIndex, 1);
            }
        }

        return tableProperties;
    }

    isTableFrfMode() {
        if (this.compareCollection.length == 0) {
            return false;
        }

        for (const compare of this.compareCollection) {
            if (!this.calcDatesService.isFrfMode(compare.quote.ActualStartDate, compare.quote.IsRenewal)) {
                return false;
            }
        }

        return true;
    }


    generateTable(tbl) {
        this.table = [];

        if (this.ids.length === 0) {
            this.firstSelectedPolicy = null;
            this.policy = null;
            this.showTable = false;
            return false;
        }


        for (let i = 0; i < tbl.length; i++) {
            const obj: { [k: string]: any } = {};
            obj.label = tbl[i].label;
            if (tbl[i].class === "first") {
                obj.label += ":";
            }
            obj.key = tbl[i].key;
            obj.class = tbl[i].class;
            obj.valueClass = tbl[i].valueClass;
            for (let j = 0; j < this.ids.length; j++) {
                if (tbl[i].type === "") {
                    obj["value" + j] = "";
                } else if (tbl[i].type === "summary") {
                    const v = this.compareCollection[j][tbl[i].value] ? this.compareCollection[j][tbl[i].value] : 0;
                    obj["value" + j] = this.numberWithCommas(v.toFixed(2));
                    if (tbl[i].unit === "$") {
                        obj["value" + j] = tbl[i].unit + obj["value" + j];
                    }
                } else if (tbl[i].type === "calcInput" && tbl[i].detailtype === "boolean") {

                    obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value] === true ?
                        tbl[i].displaytrueValue :
                        tbl[i].displayfalseValue;
                } else if (tbl[i].type === "calcInput" && tbl[i].detailType === undefined) {
                    if (this.compareCollection[j][tbl[i].type][tbl[i].value] !== null) {
                        obj["value" + j] = this.numberWithCommas(this.compareCollection[j][tbl[i].type][tbl[i].value]);
                        if (tbl[i].unit === "$") {
                            obj["value" + j] = obj["value" + j] === "" ? tbl[i].unit + "0" : tbl[i].unit + obj["value" + j];
                        } else if (tbl[i].unit === "%") {
                            if ((this.policy === 1 || this.policy === 4) && tbl[i].key === 'personalPropertyDeductiblePercent'
                                && (this.compareCollection[j][tbl[i].type][tbl[i].value] === 0 && this.compareCollection[j].calcInput.PersonalPropertyDeductible == 0 && this.compareCollection[j].calcInput?.PersonalPropertyCoverage != 0)) {
                                obj["value" + j] = this.translate.instant("COMPARE_SEND.FIELDS.NA");
                            } else if ((this.policy === 1 || this.policy === 4) && tbl[i].key === 'personalPropertyDeductiblePercent' && this.compareCollection[j].calcInput?.PersonalPropertyCoverage == 0) {
                                obj["value" + j] = '-';
                            } else { obj["value" + j] = (this.policy === 3 && obj["value" + j] === "") ? '-' : obj["value" + j] + tbl[i].unit; }
                        }
                    } else {
                        if ((this.policy === 1 || this.policy === 4) && tbl[i].key === 'personalPropertyDeductiblePercent') {
                            obj["value" + j] = this.compareCollection[j].calcInput.yesCompare ? this.translate.instant("COMPARE_SEND.FIELDS.NA") : '-';
                        } else {
                            if (tbl[i].unit === "$") {
                                obj["value" + j] = tbl[i].unit + "0";
                            } else if (tbl[i].unit === "%") {
                                obj["value" + j] = '-';
                            }
                        }
                    }
                } else if (tbl[i].type === "calcInput" && tbl[i].detailType === "date") {
                    obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value];
                } else if (tbl[i].type === "quote" && (tbl[i].detailtype === undefined) && tbl[i].unit === undefined) {
                    switch (tbl[i].key) {
                        case 'foundationType':
                            obj["value" + j] = this.getFoundation(this.compareCollection[j][tbl[i].type][tbl[i].value])
                            break;
                        case 'frame':
                            obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value] ? this.translate.instant("GLOBAL.YES") : this.translate.instant("GLOBAL.NO");
                            break;
                        case 'roofTypeVariable':
                            obj["value" + j] = this.getRoofType(this.compareCollection[j][tbl[i].type][tbl[i].value]);
                            break;
                        case 'roofType':
                            obj["value" + j] = this.getRoofType(this.compareCollection[j][tbl[i].type][tbl[i].value]);
                            break;
                        case 'stories':
                            obj["value" + j] = (this.compareCollection[j][tbl[i].type][tbl[i].value] === 0 ? this.translate.instant("HOMEOWNERS.QUESTIONS.STORIES.STORIES_SINGLE") : this.translate.instant("HOMEOWNERS.QUESTIONS.STORIES.STORIES_GREATER_THAN_ONE"));
                            break;
                        default:
                            obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value];

                    }
                } else if (tbl[i].type === "quote" && tbl[i].detailtype === "boolean") {
                    if (this.policy === 4) {
                        if (tbl[i].key === 'hazardStatus') {
                            let hazardStatus = '';
                            if (this.compareCollection[j].quote.HasBracingSystem === true) {
                                hazardStatus = this.compareCollection[j].quote.HasInspection === true ?
                                    this.translate.instant("COMPARE_SEND.FIELDS.MAY_QUALIFY") : this.translate.instant("COMPARE_SEND.FIELDS.DOES_NOT_QUALIFY");
                            } else {
                                hazardStatus = this.translate.instant("COMPARE_SEND.FIELDS.DOES_NOT_QUALIFY");
                            }
                            obj["value" + j] = hazardStatus;
                        } else if (tbl[i].key === 'hazardDiscount') {
                            let hazardDiscount = '';
                            if (this.compareCollection[j].quote.HasBracingSystem === true) {
                                hazardDiscount = this.compareCollection[j].quote.HasInspection === true ? "21%" : "";
                            } 
                            obj["value" + j] = hazardDiscount;
                        } else if ((tbl[i].key === 'breakableCoverage' || tbl[i].key === 'masonryCoverage') && this.isTableFrfMode()) {
                            obj["value" + j] = this.translate.instant("COMPARE_SEND.FIELDS.NA");
                        } else {
                            obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value] === true ?
                                tbl[i].displaytrueValue :
                                tbl[i].displayfalseValue;
                        }

                    } else if (this.policy === 1) {
                        if (tbl[i].key === 'hazardStatus') {
                            // tslint:disable-next-line:max-line-length
                            let hazardStatus = '';
                            if (this.compareCollection[j].quote.HrdRequirementsConfirmed && this.compareCollection[j].quote.HrdRequirementsVerifiedByEngineer) {
                                hazardStatus = this.translate.instant("COMPARE_SEND.FIELDS.MAY_QUALIFY");
                            } else {
                                hazardStatus = this.translate.instant("COMPARE_SEND.FIELDS.DOES_NOT_QUALIFY");
                            }
                            obj["value" + j] = hazardStatus;

                        } else if (tbl[i].key === 'hazardDiscount') {
                            // tslint:disable-next-line:max-line-length
                            let hazardDiscount = '';
                            if (this.compareCollection[j].quote.HrdRequirementsConfirmed && this.compareCollection[j].quote.HrdRequirementsVerifiedByEngineer) {
                                if (this.compareCollection[j].quote.foundationTypeVariable === 2) {
                                    // tslint:disable-next-line:radix
                                    let yearbuild = parseInt(this.compareCollection[j].quote.YearBuilt.toString());
                                    if (yearbuild >= 1940 && yearbuild <= 1979) {
                                        hazardDiscount = "10%";
                                        // tslint:disable-next-line:radix
                                    } else if (yearbuild < 1940) {
                                        hazardDiscount = "15%";
                                    }
                                }
                                if (this.compareCollection[j].quote.foundationTypeVariable === 1) {
                                    let yearbuild = parseInt(this.compareCollection[j].quote.YearBuilt.toString());
                                    if (yearbuild >= 1940 && yearbuild <= 1979) {
                                        hazardDiscount = "20%";
                                        // tslint:disable-next-line:radix
                                    } else if (yearbuild < 1940) {
                                        hazardDiscount = "25%";
                                    }
                                }
                            }
                            obj["value" + j] = hazardDiscount;

                        } else {
                            obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value] === true ?
                                tbl[i].displaytrueValue :
                                tbl[i].displayfalseValue;
                        }
                    } else {

                        obj["value" + j] = this.compareCollection[j][tbl[i].type][tbl[i].value] === true ?
                            tbl[i].displaytrueValue :
                            tbl[i].displayfalseValue;
                    }

                } else if (tbl[i].type === "quote" && tbl[i].detailtype === undefined && tbl[i].unit !== undefined) {

                    obj["value" + j] = this.numberWithCommas(this.compareCollection[j][tbl[i].type][tbl[i].value]);
                    if (tbl[i].unit === "$") {
                        obj["value" + j] = tbl[i].unit + obj["value" + j];
                    }

                    if (tbl[i].unit === "%") {
                        obj["value" + j] = obj["value" + j] + tbl[i].unit;
                    }
                } else if (tbl[i].type === "Calculate") {
                    obj["value" + j] = this.numberWithCommas((this.compareCollection[j][tbl[i].m1type][tbl[i].m1]
                        * this.compareCollection[j][tbl[i].m2type][tbl[i].m2]) / 100);
                    if (tbl[i].unit === "$") {
                        if (this.policy === 1 || this.policy === 4) {
                            if (obj["value" + j] === '' && this.compareCollection[j].calcInput.PersonalPropertyDeductible == 0 && this.compareCollection[j].calcInput?.PersonalPropertyCoverage != 0) {
                                obj["value" + j] = this.translate.instant("COMPARE_SEND.FIELDS.NA");
                            } else if (obj["value" + j] === '' && this.compareCollection[j].calcInput?.PersonalPropertyCoverage == 0) {
                                obj["value" + j] = '-';
                            } else {
                                obj["value" + j] = tbl[i].unit + obj["value" + j];
                            }
                        } else {
                            obj["value" + j] = obj["value" + j] === '' ? '-' : tbl[i].unit + obj["value" + j];
                        }
                    }

                    if (tbl[i].unit === "%") {
                        obj["value" + j] = obj["value" + j] === '' ? '-' : obj["value" + j] + tbl[i].unit;
                    }
                } else if (tbl[i].type === "norm") {
                    if (tbl[i].key === 'policyDetail') {
                        if (this.policy === 1) {
                            const calcInput = this.compareCollection[j].calcInput;
                            const policyType = (!calcInput.PersonalPropertyCoverage ||
                                (calcInput.PersonalPropertyDeductible !== 0 &&
                                    calcInput.PersonalPropertyDeductible !== null)) ?
                                this.translate.instant("GLOBAL.Homeowners") : '';
                            obj["value" + j] = policyType;
                        } else if (this.policy === 4) {
                            const calcInput = this.compareCollection[j].calcInput;
                            const policyType = (!calcInput.PersonalPropertyCoverage ||
                                (calcInput.PersonalPropertyDeductible !== 0 &&
                                    calcInput.PersonalPropertyDeductible !== null)) ?
                                this.translate.instant("GLOBAL.Mobilehome") : '';
                            obj["value" + j] = policyType;
                        } else {
                            obj["value" + j] = [tbl[i].value];
                        }
                    } else {
                        obj["value" + j] = [tbl[i].value];
                    }
                } else if (tbl[i].type === "quoterResponse" && tbl[i].detailType === undefined) {
                    if (this.compareCollection[j][tbl[i].type][tbl[i].value] !== null) {
                        obj["value" + j] = this.numberWithCommas(this.compareCollection[j][tbl[i].type][tbl[i].value]);
                        if (tbl[i].unit === "$") {
                            obj["value" + j] = obj["value" + j] === "" ? '-' : tbl[i].unit + obj["value" + j];
                        } else if (tbl[i].unit === "%") {
                            obj["value" + j] = obj["value" + j] === "" ? '-' : obj["value" + j] + tbl[i].unit;
                        }

                    } else {
                        if (tbl[i].unit === "$" || tbl[i].unit === "%") {
                            obj["value" + j] = '-';
                        }
                    }
                }

                if (tbl[i].type === "index") {
                    if (this.policy === 1) {
                        const calcInput = this.compareCollection[j].calcInput;
                        const policyType = (!calcInput.PersonalPropertyCoverage ||
                            (calcInput.PersonalPropertyDeductible !== 0 &&
                                calcInput.PersonalPropertyDeductible !== null)) ?
                            this.translate.instant("COMPARE_SEND.CHOICE") : this.translate.instant("GLOBAL.Homeowners");
                        obj["value" + j] = policyType + " #" + (calcInput.position);
                    } else if (this.policy === 4) {
                        const calcInput = this.compareCollection[j].calcInput;
                        const policyType = (!calcInput.PersonalPropertyCoverage ||
                            (calcInput.PersonalPropertyDeductible !== 0 &&
                                calcInput.PersonalPropertyDeductible !== null)) ?
                            this.translate.instant("COMPARE_SEND.CHOICE") : this.translate.instant("GLOBAL.Mobilehome");
                        obj["value" + j] = policyType + " #" + (calcInput.position);
                    } else {
                        obj["value" + j] = [tbl[i].value] + " #" + (this.compareCollection[j].calcInput.position);
                    }
                }
            }
            this.table.push(obj);
        }
    }

    numberWithCommas(x) {
        return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
    }

    openModal(data, size) {
        const modalRef = this.modalService.open(PopupComponent,
            {
                scrollable: false,
                windowClass: "myCustomModalClass",
                size
            });
        modalRef.componentInstance.fromParent = data;
        modalRef.result.then();
    }

    trackAgentClick(url) {
        this.trackingService.eventEmitter(url, "FindYourAgent", "Click");
    }

    validateMultipleEmail() {
        setTimeout(() => {
            if (this.email.sendTo === "") {
                this.showErrorSentToEmail = false;
                return false;
            }
            const arrayOfEmails = this.email.sendTo.split(",");
            this.showErrorSentToEmail = false;

            arrayOfEmails.forEach((email) => {
                email = email !== '' ? email.trim() : email;
                if (this.ValidateEmail(email)) {
                    this.showErrorSentToEmail = false;
                } else {
                    this.showErrorSentToEmail = true;
                    return false;
                }
            });
        }, 100);
    }

    validateSingleEmail() {
        setTimeout(() => {
            if (!this.email.to) {
                this.showErrorToEmail = false;
                return false;
            }
            this.email.to = this.email.to.trim();
            if (this.ValidateEmail(this.email.to)) {
                this.showErrorToEmail = false;
            } else {
                this.showErrorToEmail = true;
                return false;
            }
        }, 100);
    }

    ValidateEmail(mail) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {

            return true;
        }
        return false;
    }

    getCompleteAddress() {
        let address = "";
        if (this.quote.StreetAddress !== null) {
            address += this.quote.StreetAddress + ", ";
        }
        if (this.quote.Address2 !== null) {
            address += this.quote.Address2 + ", ";
        }
        if (this.quote.City !== null) {
            address += this.quote.City + ", ";
        }
        if (this.quote.AddressState !== null) {
            address += this.quote.AddressState + " ";
        }
        if (this.quote.ZipCode !== null) {
            address += this.quote.ZipCode;
        }
        return address;
    }

    getPolicyNameById(policy) {
        let policyType = "";
        if (policy === 2) {
            policyType = "GLOBAL.Renters";
        }
        if (policy === 1) {
            policyType = "GLOBAL.Homeowners Choice";
        }
        if (policy === 4) {
            policyType = "GLOBAL.Mobilehome Choice";
        }
        if (policy === 3) {
            policyType = "GLOBAL.CONDO_OWNERS";
        }
        return this.translate.instant(policyType);
    }

    getReportType() {
        let reportType = "";
        if (this.policy === this.modules.renter) {
            reportType = "Renter";
        } else if (this.policy === this.modules.homeowner) {
            reportType = "Home2019";
        } else if (this.policy === this.modules.mobilehome) {
            reportType = "Mobilehome2019";
        } else if (this.policy === this.modules.condo) {
            reportType = "Condo";
        }
        return reportType;
    }


    tableDataRearrangement() {
        const header: HeaderForHeader = {
            langCode: this.translationService.currentLanguage,
            packageKey: 0,
            reportAddress: this.getCompleteAddress(),
            propertyKey: 0,
            reportTitle: this.getCompleteAddress(),
            reportType: this.getReportType()
        };

        const label: LabelForPdf = new LabelForPdf();

        const quotes: QuoteForPdf[] = [];
        this.ids.forEach((v, i) => {
            const quote: QuoteForPdf = new QuoteForPdf();
            this.table.forEach((value, index) => {
                switch (value.key) {
                    case "policyType":
                        quote.policyType = (quote.policyType ? quote.policyType : '') + ' ' + value["value" + i];
                        break;
                    case "policyDetail":
                        quote.policyType = value["value" + i];
                        break;
                    case "zipCode":
                        quote.zipCode = value["value" + i];
                        break;
                    case "estimateDate":
                        quote.estimateDate = value["value" + i][0];
                        break;
                    case "estimateStart":
                        quote.estimateStart = value["value" + i];
                        break;
                    case "personalPropertyCoverage":
                        quote.personalPropertyCoverage = value["value" + i];
                        break;

                    case "personalPropertyDeductibleAmount":
                        quote.personalPropertyDeductibleAmount = value["value" + i];
                        break;
                    case "personalPropertyDeductiblePercent":
                        quote.personalPropertyDeductiblePercent = value["value" + i];
                        break;
                    case "breakableCoverage":
                        quote.breakableCoverage = value["value" + i];
                        break;
                    case "lossOfUseCoverage":
                        quote.lossOfUseCoverage = value["value" + i];
                        break;
                    case "premiumMonthly":
                        quote.premiumMonthly = value["value" + i];
                        break;
                    case "premiumAnnual":
                        quote.premiumAnnual = value["value" + i];
                        break;
                    case "dwellingCoverage":
                        quote.dwellingCoverage = value["value" + i];
                        break;
                    case "dwellingDeductibleAmount":
                        quote.dwellingDeductibleAmount = value["value" + i];
                        break;
                    case "dwellingDeductiblePercent":
                        quote.dwellingDeductiblePercent = value["value" + i];
                        break;

                    case "lossAssessmentCoverage":
                        quote.lossAssessmentCoverage = value["value" + i];
                        break;
                    case "lossAssessmentDeductibleAmount":
                        quote.lossAssessmentDeductibleAmount = value["value" + i];
                        break;
                    case "lossAssessmentDeductiblePercent":
                        quote.lossAssessmentDeductiblePercent = value["value" + i];
                        break;
                    case "yearBuilt":
                        quote.yearBuilt = value["value" + i];
                        break;
                    case "hazardDiscount":
                        quote.hazardDiscount = value["value" + i];
                        break;
                    case "hazardStatus":
                        if (value["value" + i]) {
                            quote.hazardStatus = value["value" + i];
                        }
                        break;
                    case "hazardHrdIndicator":
                        if (value["value" + i]) {
                            quote.hazardHrdIndicator = value["value" + i];
                            quote.hazardStatus = this.correctHazardStatus(quote.hazardHrdIndicator);
                        }
                        break;
                    case "buildingCodeCoverage":
                        quote.buildingCodeCoverage = value["value" + i];
                        break;
                    case "bracingSystem":
                        quote.bracingSystem = value["value" + i];
                        break;
                    case "roofType":
                        quote.roofType = value["value" + i];
                        break;
                    case "stories":
                        quote.stories = value["value" + i];
                        break;
                    case "masonryCoverage":
                        quote.masonryCoverage = value["value" + i];
                        break;
                    case "foundationType":
                        quote.foundationType = value["value" + i];
                        break;
                    case "frame":
                        quote.frame = value["value" + i];
                        break;
                    case "HasBracingSystem":
                        quote.bracingSystem = value["value" + i];
                        break;
                }
            });
            quotes.push(quote);
        });

        this.comparepdf = {
            header,
            label,
            quotes
        };
    }


    correctHazardStatus(hazardHrdIndicator) {
        if ('合格' == hazardHrdIndicator || 'Qualified' == hazardHrdIndicator || 'Calificado' == hazardHrdIndicator) {
            return this.translate.instant("GLOBAL.YES");
        } else {
            return this.translate.instant("GLOBAL.NO");
        }
    }

    closeModal(sendData) {
        this.activeModal.close(sendData);
    }

    custLoader = true;
    send = this.translate.instant("EMAIL.SEND_EMAIL");

    buildPdf() {
        console.log('buildPdf');
        let model = new DownloadFilePostModel();
        model.quotes = [];
        let isHeader = false;

        this.setPosition();

        if (this.quotesList && this.quotesList.length !== 0) {
            (this.ids).forEach((value, index) => {
                const estimate = this.estimateBuilderService.BuildEstimate(this.quotesList[value].calcInput, this.quotesList[value].quote, this.quotesList[value].quoterResponse);
                if (!isHeader) {
                    if ((this.isTableFrfMode() && estimate.IsFrfMode) ||
                        (!this.isTableFrfMode() && !estimate.IsFrfMode)) {
                        model.header = this.printBuilderService.buildHeader(estimate);
                        model.label = this.printBuilderService.buildLabel(estimate);
                        isHeader = true;
                    }
                }

                model.quotes.push(this.printBuilderService.buildQuotePdf(estimate, true));
            });
        }
        return model;
    }

    mobileVisible(value: boolean) {
        this.isMobileView = value;
        this.changeDetector.detectChanges();
    }

    setPosition() {
        let position = 1;
        (this.quotesList).forEach((value => {
            value.calcInput.position = position;
            position++;
        }));
    }

    logEmailRequest() {
        let calcInputs = [] as CalcInput[];
        for (let quote of this.quotesList) {
            calcInputs.push(quote.calcInput);
        }

        this.quoterRequestService.logRequests(calcInputs, this.quotesList[0].quote.policytype, "email");
    }

    sentEmail() {
        const comparePdf = this.buildPdf();
        this.printoutService.email(this.email, comparePdf);

        this.custLoader = false;
        this.showSpin = true;
        this.send = this.translate.instant("EMAIL.SENDING");
        setTimeout(() => {
            this.showSpin = false;
            this.send = this.translate.instant("EMAIL.SENT");
            this.custLoader = true;

            this.logEmailRequest();
        }, 3000);
        setTimeout(() => {
            this.showSpin = false;
            this.showSuccess = true;
        }, 5000);
    }

    getFoundation(index) {
        const foundationTypeVariableOptions = [
            this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.SLAB"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.RAISED"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.FOUNDATIONS.OTHER")
        ];

        return foundationTypeVariableOptions[index];
    }

    getRoofType(index) {
        const roofTypeVariableOptions = [
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.TILE"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.COMPOSITION"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.WOODSHAKE"),
            this.translate.instant("HOMEOWNERS.QUESTIONS.ROOF_TYPES.OTHER")
        ];

        return roofTypeVariableOptions[index];
    }

}
