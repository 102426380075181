
<div class="owner" *ngIf="enablePreview">
    <strong>Draft is owned by user: {{getOwner()}}</strong>
</div>

<h4 *ngIf="translation">{{title}}</h4>

<div class="compare" *ngIf="translation && enablePreview">
    <div class="label">Compare:</div>
    <div class="options">
        <span (click)="goEditable()" [class.selected]="isEditableMode()">Editable</span> / <span (click)="goLive()" [class.selected]="isLiveMode()">Live</span>
    </div>
</div>

<form [formGroup]="formInfo" style="margin-top: 15px;" class="formInfo" *ngIf="translation">

    <div class="input-group">
        <label for="english">English:</label>
        <textarea required autocomplete="off"
                  placeholder="English"
                  id="english"
                  name="english"
                  formControlName="english"
                  [readonly]="!isEditable || !isEditableMode()"></textarea>
    </div>


    <div class="input-group">
        <label for="spanish">Spanish:</label>
        <textarea required autocomplete="off"
                  placeholder="Spanish"
                  id="spanish"
                  name="spanish"
                  formControlName="spanish"
                  [readonly]="!isEditable || !isEditableMode()"></textarea>
    </div>

    <div class="input-group">
        <label for="chinese">Chinese:</label>
        <textarea required autocomplete="off"
                  placeholder="Chinese"
                  id="chinese"
                  name="chinese"
                  formControlName="chinese"
                  [readonly]="!isEditable || !isEditableMode()"></textarea>
    </div>

    <div class="input-group" style="display: block; text-align: right;">
        <button mat-stroked-button class="primary"
                style="margin-top: 18px"
                type="submit"
                *ngIf="isEditable"
                (click)="onSave()"
                [disabled]="formInfo.pristine || !formInfo.valid || !isEditableMode()">
            SAVE
        </button>

    </div>
</form>
