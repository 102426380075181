export class HeaderForHeader {
    langCode = "";
    packageKey = 0;
    propertyKey = 0;
    reportAddress = "";
    reportTitle = "";
    reportType = "";
}

export class QuoteForPdf {
    policyType = "";
    zipCode = "";
    estimateDate = "";
    estimateStart = "";
    personalPropertyCoverage = "";
    personalPropertyDeductibleAmount = "";
    personalPropertyDeductiblePercent = "";
    breakableCoverage = "";
    lossOfUseCoverage = "";
    premiumMonthly = "";
    premiumAnnual = "";
    dwellingCoverage = "";
    dwellingDeductibleAmount = "";
    dwellingDeductiblePercent = "";
    lossAssessmentCoverage = "";
    lossAssessmentDeductibleAmount = "";
    lossAssessmentDeductiblePercent = "";
    yearBuilt = "0";
    hazardDiscount = "";
    hazardStatus = " ";
    hazardHrdIndicator = "";
    buildingCodeCoverage = "";
    bracingSystem = "";
    stories = "";
    masonryCoverage = "";
    foundationType = "";
    frame = "";
    roofType = "";
}

export class LabelForPdf {
    title = "";
    minimum = "";
    disclaimer = "";
    policyDetails = "";
    policyType = "";
    zipCode = "";
    estimateDate = "";
    estimateStart = "";
    personalProperty = "";
    personalPropertyCoverage = "";
    personalPropertyDeductibleAmount = "";
    personalPropertyDeductiblePercent = "";
    breakableCoverage = "";
    lossOfUse = "";
    lossOfUseCoverage = "";
    premium = "";
    premiumMonthly = "";
    premiumAnnual = "";
    dwelling = "";
    dwellingCoverage = "";
    dwellingDeductibleAmount = "";
    dwellingDeductiblePercent = "";
    lossAssessment = "";
    lossAssessmentCoverage = "";
    lossAssessmentDeductibleAmount = "";
    lossAssessmentDeductiblePercent = "";
    yearBuilt = "";
    hazardDiscount = "";
    hazardStatus = " ";
    hazardHrdIndicator = "";
    buildingCode = "";
    buildingCodeCoverage = "";
    bracingSystem = "";
    stories = "";
    masonryCoverage = "";
    foundationType = "";
    frame = "";
    roofType = "";
}


export class ComparePdfModel {
    header: HeaderForHeader;
    label: LabelForPdf;
    quotes: QuoteForPdf[];
}

