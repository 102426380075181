<div class="container">
    <div class="header">
        <div class="header_flex" fxLayoutGap="20px">
            <div fxFlex="35%"><h1>Premium Calculator Content</h1></div>
            <div fxFlex="40%">
                <select id="draft" name="draft" style="width: 200px;" (change)="onSelection($event.target.value)">
                    <option class="" value="">Live</option>
                    <option *ngFor="let d of drafts" [value]="d">{{displayName(d)}}</option>
                    <option *ngFor="let h of shortHistory" [value]="h">{{displayName(h)}}</option>
                    <option *ngIf="history.length >= historyMax" value="__more">More...</option>
                </select>
            </div>
            <div fxFlex="8%" (click)="onPreview()" *ngIf="canPreview()" class="mat-text">
                <mat-icon>open_in_new</mat-icon> &nbsp; <span>Preview</span>
            </div>
            <div fxFlex="8%" (click)="onRequest()" *ngIf="canRequest()" class="mat-text">
                <mat-icon>swap_calls</mat-icon> &nbsp; <span>Take Control</span>
            </div>
            <div fxFlex="8%" (click)="onCreate()" *ngIf="canCreate()"  class="mat-text">
                <mat-icon>create</mat-icon> &nbsp; <span>Create</span>
            </div>
            <div fxFlex="8%" (click)="onRemove()" *ngIf="canRemove()" class="mat-text">
                <mat-icon>delete_forever</mat-icon> &nbsp; <span>Remove</span>
            </div>
            <div fxFlex="8%" (click)="onPublish()" *ngIf="canPublish()" class="mat-text">
                <mat-icon>swap_vertical_circle</mat-icon> &nbsp; <span>Publish</span>
            </div>

        </div>
    </div>
    <div class="sidebar" *ngIf="!isLoading">
        <app-tree-folder [translations]="translations"
                         [live]="current"
                         (emitSelectEvent)="onTranslationSelect($event)">
        </app-tree-folder>
    </div>
    <div class="content" *ngIf="!isLoading">
        <app-translation-form [translation]="current"
                              [live]="currentLive"
                              [draft]="currentDraft"
                              [enablePreview]="!isMain() && !isHistory()"
                              [isEditable]="isEditable()"
                              [isOwnerMsg]="isOwnerMsg()"
                              (emitFormChangeEvent)="onTranslationUpdate($event)"></app-translation-form>
    </div>

    <app-loading *ngIf="isLoading">
    </app-loading>
</div>
