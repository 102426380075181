import { Injectable } from "@angular/core";



export const LIVE_VERSION = "Live";

@Injectable({
    providedIn: "root"
})
export class DraftNameService {
    DisplayName(name: string): string {
        if(!name)  {
            return LIVE_VERSION;
        }

        if (name.startsWith("hist_")) {
            let parts = name.split("_");
            if (parts.length == 2) {
                return name.replace("hist_", "History ").replace("-", "/").replace("-", "/");
            }
            else {
                return "History " + parts[2] + " (" + parts[1] + ") " + parts[3].replace("-", "/").replace("-", "/") + (parts[4] ? " " + parts[4].replace("P", "PM").replace("A", "AM") : "");
            }
        }

        let parts = name.split("_");

        return parts[1] + " (" + parts[0] + ")";
    }

    GetUserName(draft: string): string {
        let parts = draft.split("_");
        return parts[0];
    }

    GetDraft(draft: string): string {
        let parts = draft.split("_");
        return parts[1];
    }

    HasWriteAccess(user: string, draft: string): boolean {
        return user === this.GetUserName(draft);
    }

    HistoryDate(name: string): Date {
        let parts = name.split("_");

        //name_date
        if (parts.length == 2) {
            return new Date(parts[1]);
        }

        //name_owner_date
        if (parts.length == 4) {
            return new Date(parts[3]);
        }

        //name_owner_date_time
        if (parts.length == 5) {
            const isAm = parts[4].indexOf('A') > 0;
            let timeParts = parts[4].split(':');

            const hours = parseInt(timeParts[0]) + (isAm ? 0 : 12);
            const mins = parseInt(timeParts[1].replace('A', '').replace('P', ''));

            let dt = new Date(parts[3]);
            dt.setHours(hours);
            dt.setMinutes(mins);

            return dt;
        }

        //pick an early date
        return new Date(2010, 1, 1);
    }
}
