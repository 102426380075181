import { Injectable } from "@angular/core";
import { moduleType } from "../../models/module.type";
import { QuoterRequest } from "../../models/quoterRequest.model";
import { RadioGroupSettings } from "../../models/radioGroup.model";
import { SliderSettings } from "../../models/slider.model";
import { HazardReductionService } from "../hazard-reduction/hazard-reduction.service";


@Injectable({
  providedIn: "root"
})
export class SlidersConfigService {

    constructor(private hrdService: HazardReductionService) {

    }

    getBuildingPropertyCoverage(displayValue, caption, shortCaption, key, disabled, steps): SliderSettings {
        return   {
            sliderKey: key,
            caption: caption,
            shortCaption: shortCaption,
            displayValue: displayValue,
            // tslint:disable-next-line:max-line-length
            helpSlug: {
                title: key == "BuildingPropertyCoverage" ? "CONDO.CALCULATIONS.HELP.BUILDING_PROPERTY.TITLE" : "HOMEOWNERS.CALCULATIONS.HELP.BUILDING_COVERAGE.TITLE",
                text: key == "BuildingPropertyCoverage" ? "CONDO.CALCULATIONS.HELP.BUILDING_PROPERTY.TEXT" : "HOMEOWNERS.CALCULATIONS.HELP.BUILDING_COVERAGE.TEXT"
            },
            leftLabel: this.leftLabel(steps),
            rightLabel: this.rightLabel(steps),
            isReversed: false,
            errorMessage: "",
            value: 0,
            disabled: disabled,
            readonly: false,
            step: 0,
            floor: 0,
            ceil: 0,
            unit: "$",
            isStepArrayRequired: true, isSpecial: this.isSpecialValue(steps), SpecialValue: this.isSpecialValue(steps) ? "GLOBAL.NONE" : "",
            // tslint:disable-next-line:max-line-length
            stepsArray: steps
        };
    }

    getBuildingPropertyDeductible(displayValue, disabled, steps): SliderSettings {
        return {
            sliderKey: "BuildingPropertyDeductible",
            caption: "CONDO.CALCULATIONS.BUILDING_PROPERTY_DEDUCTIBLE",
            shortCaption: "CONDO.CALCULATIONS.BUILDING_PROPERTY_DEDUCTIBLE",
            displayValue: displayValue,
            // tslint:disable-next-line:max-line-length
            helpSlug: {
                title: "CONDO.CALCULATIONS.HELP.BUILDING_PROPERTY_DEDUCTIBLE.TITLE",
                text: "CONDO.CALCULATIONS.HELP.BUILDING_PROPERTY_DEDUCTIBLE.TEXT"
            },
            leftLabel: this.leftLabel(steps, "GLOBAL.NONE"),
            rightLabel: this.rightLabel(steps),
            isReversed: false,
            errorMessage: "",
            value: 0,
            disabled: disabled,
            readonly: false,
            step: 0,
            floor: 0,
            ceil: 0,
            unit: "%",
            isStepArrayRequired: true, isSpecial: this.isSpecialValue(steps), SpecialValue: this.isSpecialValue(steps) ? "GLOBAL.NONE" : "",
            stepsArray: steps
        };
    }

    getBuildingCodeUpgradeIncreasedLimit(model): RadioGroupSettings {
        return {
            radioKey: "MasonryVeneerCoverage",
            disabled: false,
            bindModel: model,
            caption: "HOMEOWNERS.CALCULATIONS.VENEER_COVERAGE",
            shortCaption: "HOMEOWNERS.CALCULATIONS.VENEER_COVERAGE_MOBILE",
            helpSlug: {
                title: "HOMEOWNERS.CALCULATIONS.HELP.VENEER_COVERAGE.TITLE",
                text: "HOMEOWNERS.CALCULATIONS.HELP.VENEER_COVERAGE.TEXT"
            },
        };
    }

    getPersonalPropertyCoverage(displayValue, caption, shortCaption, steps): SliderSettings {
        return {
            sliderKey: "PersonalPropertyCoverage",
            caption: caption,
            shortCaption: shortCaption,
            displayValue: displayValue,
            // tslint:disable-next-line:max-line-length
            helpSlug: {
                title: "HOMEOWNERS.CALCULATIONS.HELP.PERSONAL_PROPERTY_COVERAGE.TITLE",
                text: "HOMEOWNERS.CALCULATIONS.HELP.PERSONAL_PROPERTY_COVERAGE.TEXT"
            },
            leftLabel: this.leftLabel(steps),
            rightLabel: this.rightLabel(steps),
            isReversed: false,
            errorMessage: "",
            value: 0,
            disabled: false,
            readonly: false,
            step: 0,
            floor: 0,
            ceil: 0,
            unit: "$",
            isStepArrayRequired: true, isSpecial: this.isSpecialValue(steps), SpecialValue: this.isSpecialValue(steps) ? "GLOBAL.NONE" : "",
            // tslint:disable-next-line:max-line-length
            stepsArray: steps
        };
    }

    getPersonalPropertyDeductible(displayValue, caption, shortCaption, disabled, specialLabel, steps): SliderSettings {
        return {
            sliderKey: "PersonalPropertyDeductible",
            caption: caption,
            shortCaption: shortCaption,
            displayValue: displayValue,
            // tslint:disable-next-line:max-line-length
            helpSlug: {
                title: "RENTER.HELP.PERSONAL_PROPERTY_DEDUCTABLE.TITLE",
                text: "RENTER.HELP.PERSONAL_PROPERTY_DEDUCTABLE.TEXT"
            },
            leftLabel: this.leftLabel(steps, specialLabel),
            rightLabel: this.rightLabel(steps),
            isReversed: false,
            errorMessage: "",
            value: 0,
            disabled: disabled,
            readonly: false,
            step: 0,
            floor: 0,
            ceil: 0,
            unit: "%",
            isStepArrayRequired: true, isSpecial: this.isSpecialValue(steps), SpecialValue: this.isSpecialValue(steps) ? specialLabel : "",
            stepsArray: steps
        };
    }

    getWantsBreakableCoverage(model, disabled): RadioGroupSettings {
        return {
            radioKey: "BreakablesCoverage",
            bindModel: model,
            caption: "HOMEOWNERS.CALCULATIONS.BREAKABLE_ITEMS_COVERAGE",
            shortCaption: "HOMEOWNERS.CALCULATIONS.BREAKABLE_ITEMS_COVERAGE_MOBILE",
            disabled: disabled,
            helpSlug: {
                title: "HOMEOWNERS.CALCULATIONS.HELP.BREAKABLE_ITEMS_COVERAGE.TITLE",
                text: "HOMEOWNERS.CALCULATIONS.HELP.BREAKABLE_ITEMS_COVERAGE.TEXT"
            },
        };
    }

    getLossOfUseCoverage(displayValue, steps): SliderSettings {
        return {
            sliderKey: "LossOfUseCoverage",
            caption: "HOMEOWNERS.CALCULATIONS.LOSS_OF_USE_COVERAGE",
            shortCaption: "HOMEOWNERS.CALCULATIONS.LOSS_OF_USE_COVERAGE_MOBILE",
            displayValue: displayValue,
            // tslint:disable-next-line:max-line-length
            helpSlug: {
                title: "HOMEOWNERS.CALCULATIONS.HELP.LOSS_OF_USE_COVERAGE.TITLE",
                text: "HOMEOWNERS.CALCULATIONS.HELP.LOSS_OF_USE_COVERAGE.TEXT"
            },
            leftLabel: this.leftLabel(steps),
            rightLabel: this.rightLabel(steps),
            isReversed: false,
            errorMessage: "",
            value: 0,
            disabled: false,
            readonly: false,
            step: 0,
            floor: 0,
            ceil: 0,
            unit: "$",
            isStepArrayRequired: true, isSpecial: this.isSpecialValue(steps), SpecialValue: this.isSpecialValue(steps) ? "GLOBAL.NONE" : "",
            // tslint:disable-next-line:max-line-length
            stepsArray: steps
        };
    }

    getLossAssessmentCoverage(displayValue, steps): SliderSettings {
        return {
            sliderKey: "LossAssessmentCoverage",
            caption: "CONDO.CALCULATIONS.LOSS_ASSESSMENT_COVERAGE",
            shortCaption: "CONDO.CALCULATIONS.LOSS_ASSESSMENT_COVERAGE_MOBILE",
            displayValue: displayValue,
            // tslint:disable-next-line:max-line-length
            helpSlug: {
                title: "CONDO.CALCULATIONS.HELP.LOSS_ASSESSMENT_COVERAGE.TITLE",
                text: "CONDO.CALCULATIONS.HELP.LOSS_ASSESSMENT_COVERAGE.TEXT"
            },
            leftLabel: "GLOBAL.NONE",
            rightLabel: "$100K",
            isReversed: false,
            errorMessage: "",
            value: 0,
            disabled: false,
            readonly: false,
            step: 0,
            floor: 0,
            ceil: 0,
            unit: "$",
            isStepArrayRequired: true, isSpecial: true, SpecialValue: "GLOBAL.NONE",
            // tslint:disable-next-line:max-line-length
            stepsArray: steps
        };
    }

    getLossAssessmentDeductible(displayValue, disabled, steps): SliderSettings {
        return {
            sliderKey: "LossAssessmentDeductible",
            caption: "CONDO.CALCULATIONS.LOSS_ASSESSMENT_DEDUCTIBLE",
            shortCaption: "CONDO.CALCULATIONS.LOSS_ASSESSMENT_DEDUCTIBLE_MOBILE",
            displayValue: displayValue,
            // tslint:disable-next-line:max-line-length
            helpSlug: {
                title: "CONDO.CALCULATIONS.HELP.LOSS_ASSESSMENT_DEDUCTIBLE.TITLE",
                text: "CONDO.CALCULATIONS.HELP.LOSS_ASSESSMENT_DEDUCTIBLE.TEXT"
            },
            leftLabel: this.leftLabel(steps, "GLOBAL.NONE"),
            rightLabel: this.rightLabel(steps),
            isReversed: false,
            errorMessage: "",
            value: 0,
            disabled: disabled,
            readonly: false,
            step: 0,
            floor: 0,
            ceil: 0,
            unit: "%",
            isStepArrayRequired: true, isSpecial: false, SpecialValue: "",
            stepsArray: steps
        };
    }

    getDwellingDeductible(displayValue, steps, frfMode, over1M): SliderSettings {
        let helpSlugText = "HOMEOWNERS.CALCULATIONS.HELP.DWELLING_DEDUCTIBLE.TEXT"
        if (steps[steps.length - 1].value !== 5) {
            helpSlugText = over1M ? "HOMEOWNERS.CALCULATIONS.HELP.DWELLING_DEDUCTIBLE.TEXT_OVER_1M" : "HOMEOWNERS.CALCULATIONS.HELP.DWELLING_DEDUCTIBLE.TEXT_NO_0510";
        }

        return {
            sliderKey: "BuildingPropertyDeductible",
            caption: "HOMEOWNERS.CALCULATIONS.DWELLING_DEDUCTIBLE",
            shortCaption: "HOMEOWNERS.CALCULATIONS.DWELLING_DEDUCTIBLE_MOBILE",
            displayValue: displayValue,
            // tslint:disable-next-line:max-line-length
            helpSlug: {
                title: "HOMEOWNERS.CALCULATIONS.HELP.DWELLING_DEDUCTIBLE.TITLE",
                text: helpSlugText
            },
            leftLabel: this.rightLabel(steps),
            rightLabel: this.leftLabel(steps),
            isReversed: true,
            errorMessage: "",
            value: 0,
            disabled: false,
            readonly: false,
            step: 0,
            floor: 0,
            ceil: 0,
            unit: "%",
            isStepArrayRequired: true, isSpecial: false, SpecialValue: "",
            stepsArray: steps
        };
    }

    getBuildingCodeUpgradeLimitIncrease(displayValue): SliderSettings {
        return {
            sliderKey: "BuildingCodeUpgradeLimitIncrease",
            caption: "HOMEOWNERS.CALCULATIONS.BUILDING_COVERAGE",
            shortCaption: "HOMEOWNERS.CALCULATIONS.BUILDING_COVERAGE_MOBILE",
            displayValue: displayValue,
            // tslint:disable-next-line:max-line-length
            helpSlug: {
                title: "HOMEOWNERS.CALCULATIONS.HELP.BUILDING_COVERAGE.TITLE",
                text: "HOMEOWNERS.CALCULATIONS.HELP.BUILDING_COVERAGE.TEXT"
            },
            leftLabel: " $10K",
            rightLabel: " $30K",
            isReversed: false,
            errorMessage: "",
            value: 0,
            disabled: false,
            readonly: false,
            step: 10000,
            floor: 10000,
            ceil: 30000,
            unit: "$",
            isStepArrayRequired: false, isSpecial: false, SpecialValue: "",
            stepsArray: []
        };
    }

    leftLabel(steps, defLabel = ""): string {
        if (steps[0].value === 0) {
            if (defLabel) {
                return defLabel;
            }
            return this.isPercent(steps) ? "GLOBAL.NO" : "GLOBAL.NONE";
        }
        else if (this.isPercent(steps)) {
            return this.percentFormat(steps[0].value);
        }
        else {
            return this.moneyFormat(steps[0].value);
        }
    }

    rightLabel(steps): string {
        const index: number = steps.length - 1;
        if (this.isPercent(steps)) {
            return this.percentFormat(steps[index].value);
        }
        else {
            return this.moneyFormat(steps[index].value);
        }
    }

    isPercent(steps): boolean {
        const index: number = steps[0].value === 0 ? 1 : 0;

        return steps[index].value <= 100;
    }

    moneyFormat(val: number): string {
        const num = val / 1000;
        return "$" + num.toString() + "K";
    }

    percentFormat(val: number) : string {
        return val.toString() + "%";
    }

    isSpecialValue(steps): boolean {
        return steps[0].value === 0;
    }

    getCondoSteps(isOver135k: boolean) {
        if (isOver135k === true) {
            return [{ value: 0 }, { value: 50000 }, { value: 75000 }, { value: 100000 }];
        }

        return [{ value: 0 }, { value: 25000 }, { value: 50000 }, { value: 75000 }, { value: 100000 }];
    }

    getCondoBPDSteps(isDisabled: boolean) {
        //for now these are equivalent in functionality but different in concept
        return this.getCondoPPDeductible(isDisabled);
    }

    frfPPCoverageOptions(options, frfMode: boolean) {
        if (!frfMode) {
            return options;
        }

        let ret = [];
        for (const option of options) {
            if (option.value <= 25000) {
                ret.push(option);
            }
        }

        return ret;
    }

    getCondoPPCoverageSteps() {
        return [{ value: 0 }, { value: 5000 }, { value: 25000 }, { value: 50000 }, { value: 75000 }, { value: 100000 }, { value: 150000 }, { value: 200000 }];
    }

    getRenterPPCoverageSteps() {
        return [{ value: 5000 }, { value: 25000 }, { value: 50000 }, { value: 75000 }, { value: 100000 }, { value: 150000 }, { value: 200000 }]; 
    }

    getHomeownerPPCoverageSteps(yesCompare) {
        if (yesCompare === true) {
            return [{ value: 5000 }, { value: 25000 }, { value: 50000 }, { value: 75000 }, { value: 100000 }, { value: 150000 }, { value: 200000 }];
        }
        return [{ value: 0 }, { value: 5000 }, { value: 25000 }, { value: 50000 }, { value: 75000 }, { value: 100000 }, { value: 150000 }, { value: 200000 }];
    }

    frfPPDeductibleOptions(options, frfMode) {
        if (!frfMode) {
            return options;
        }

        let ret = [];
        for (const option of options) {
            if (option.value > 10) {
                ret.push(option);
            }
        }

        return ret;
    }

    frfException(frfMode: boolean, quoteRequest: QuoterRequest) {
        if (!frfMode) {
            return false;
        }

        if (quoteRequest.policytype == moduleType.mobilehome &&
            quoteRequest.InsuredValue <= 1000000) {
            return false;
        }

        if (quoteRequest.policytype === moduleType.homeowner) {
            if (quoteRequest.InsuredValue > 1000000) {
                return true;
            }

            if (!quoteRequest.IsWoodFrame) {
                return false;
            }

            if (quoteRequest.foundationTypeVariable === 0 ||
                quoteRequest.YearBuilt >= 1980 ||
                this.hrdService.isHomeowner2019Qualified(quoteRequest)) {
                return false;
            }
        }

        return true;
    }

    frfOver1Million(quoteRequest: QuoterRequest) {
        return quoteRequest.InsuredValue >= 1000000;
    }

    getCondoPPDeductible(isDisabled: boolean) {
        if (isDisabled) {
            return [{ value: 0 }, { value: 25 }, { value: 20 }, { value: 15 }, { value: 10 }, { value: 5 }];
        }
        return [{ value: 25 }, { value: 20 }, { value: 15 }, { value: 10 }, { value: 5 }];
    }

    getHomeownerPPDeductibleSteps(yesCompare: boolean|null) {
        if (yesCompare === false) {
            return [{ value: 25 }, { value: 20 }, { value: 15 }, { value: 10 }, { value: 5 }];
        }
        
        return [{ value: 0 }, { value: 25 }, { value: 20 }, { value: 15 }, { value: 10 }, { value: 5 }];
        
    }

    getCondoLUCoverageSteps() {
        return [{ value: 0 }, { value: 1500 }, { value: 10000 }, { value: 15000 }, { value: 25000 }, { value: 50000 }, { value: 75000 }, { value: 100000 }];
    }

    getRenterLUCoverageSteps() {
        return [{ value: 1500 }, { value: 10000 }, { value: 15000 }, { value: 25000 }, { value: 50000 }, { value: 75000 }, { value: 100000 }];
    }

    getRenterLACoverageSteps() {
        return [{ value: 0 }, { value: 50000 }, { value: 75000 }, { value: 100000 }];
    }

    getCondoBPCoverageSteps() {
        return [{ value: 0 }, { value: 25000 }, { value: 50000 }, { value: 75000 }, { value: 100000 }];
    }

    getHomeownerBPCoverageSteps() {
        return [{ value: 10000 }, { value: 20000 }, { value: 30000 }];
    }

    getDDSteps() {
        return [{ value: 25 }, { value: 20 }, { value: 15 }, { value: 10 }, { value: 5 }];
    }

    getLADSteps(isDisabled: boolean) {
        if (isDisabled) {
            return [{ value: 0 }, { value: 25 }, { value: 20 }, { value: 15 }, { value: 10 }, { value: 5 }]
        }
        return [{ value: 25 }, { value: 20 }, { value: 15 }, { value: 10 }, { value: 5 }];
    }

    isChoice(personalPropertyDeductible: number, personalPropertyCoverage: number) {
        if (!personalPropertyDeductible && personalPropertyCoverage) {
            return false;
        }
        return true;
    }
}
