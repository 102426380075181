import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Translation } from '../../models/translation';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DraftNameService } from '../../services/draftname.service';

export const EDITABLE_MODE = "editable";
export const LIVE_MODE = "live";

@Component({
    selector: 'app-translation-form',
    templateUrl: './translation-form.component.html',
    styleUrls: ['./translation-form.component.scss']
})
export class TranslationFormComponent implements OnInit, OnChanges {
    public formInfo: UntypedFormGroup;
    @Input("translation") translation: Translation;
    @Input("live") live: Translation;
    @Input("enablePreview") enablePreview: boolean;
    @Input("isEditable") isEditable: boolean;
    @Input("isOwnerMsg") isOwnerMsg: boolean;
    @Input("draft") draft: string;
    @Output() emitFormChangeEvent = new EventEmitter<Translation>();
    public title: string;
    public mode: string = EDITABLE_MODE;

    constructor(private fb: UntypedFormBuilder,
        private draftnameService: DraftNameService) {

    }

    ngOnInit(): void {
        this.initializeForms();
    }

    ngOnChanges() {
        if (this.translation && this.formInfo) {
            this.formInfo.get("english").setValue(this.translation.english);
            this.formInfo.get("spanish").setValue(this.translation.spanish);
            this.formInfo.get("chinese").setValue(this.translation.chinese);
            this.title = this.translation.path.replace(".", ". ");
            this.mode = EDITABLE_MODE;
        }
    }

    private initializeForms(): void {
        this.formInfo = this.fb.group({
            english: ['', Validators.required],
            spanish: ['', Validators.required],
            chinese: ['', Validators.required],
        });
    }

    public onSave() {
        let ret = new Translation();
        ret.label = this.translation.label;
        ret.path = this.translation.path;
        ret.english = this.formInfo.get("english").value;
        ret.spanish = this.formInfo.get("spanish").value;
        ret.chinese = this.formInfo.get("chinese").value;

        this.emitFormChangeEvent.emit(ret);
    }

    public goEditable() {
        this.formInfo.get("english").setValue(this.translation.english);
        this.formInfo.get("spanish").setValue(this.translation.spanish);
        this.formInfo.get("chinese").setValue(this.translation.chinese);
        this.mode = EDITABLE_MODE;
    }

    public goLive() {
        this.formInfo.get("english").setValue(this.live.english);
        this.formInfo.get("spanish").setValue(this.live.spanish);
        this.formInfo.get("chinese").setValue(this.live.chinese);
        this.mode = LIVE_MODE;
    }

    public isLiveMode() : boolean {
        return this.mode === LIVE_MODE;
    }

    public isEditableMode() : boolean {
        return this.mode === EDITABLE_MODE;
    }

    public getOwner() {
        return this.draftnameService.GetUserName(this.draft);
    }
}
