import { Component, OnInit, HostListener } from "@angular/core";
//import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { language } from "src/app/shared/models/language.model";
import { TranslationService } from "src/app/shared/services/translation/translation.service";
import { ImageService } from "./../../shared/services/images/image.service";
import { QuoterRequest2Service } from "../../shared/services/quote-request/quote-request2.service";
import { QuoteStorageService } from "../../shared/services/quote-storage/quote-storage.service";
import { SessionService } from "../../shared/services/session/session.service";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
    //fromCode = _("demo.text-in-code");
    lang = language;
    screenWidth = 0;
    constructor(public translationService: TranslationService,
        private quoterRequestService: QuoterRequest2Service,
        private quoteStorageService: QuoteStorageService,
        private sessionService: SessionService,
        private imageService: ImageService
    ) {

        this.sessionService.listenUnload("/", function () {
            this.quoteStorageService.SaveState(this.sessionService.getRelativeUrl())
                .SaveStateParams(null)
                .SaveLanguage(this.translationService.currentLanguage);
        }.bind(this));
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.screenWidth = window.innerWidth;
    }

    ngOnInit(): void {
        this.quoteStorageService.RemoveCompareItem();
        this.quoterRequestService.setAddNewCalc(false);
    }

    useLanguage(selectedlanguage: string) {
        this.translationService.useLanguage(selectedlanguage);
    }

    imageUrl(relative: string) {
        return this.imageService.ImageLoc(relative);
    }
}
