import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopupComponent } from "..";
import { ImageService } from '../../shared/services/images/image.service';

@Component({
  selector: 'app-cea-help-button',
  templateUrl: './cea-help-button.component.html',
  styleUrls: ['./cea-help-button.component.scss']
})
export class CeaHelpButtonComponent implements OnInit {
    @Input() stype: string;
    @Input() title: string;
    @Input() text1: string;
    @Input() text2?: string;
    @Input() text3?: string;
    @Output() moveTop: EventEmitter<string> = new EventEmitter();
    label: string;
    css: {};
    icss: {};

    constructor(public translate: TranslateService,
        private imageService: ImageService,
        private modalService: NgbModal) {
    }

    ngOnInit(): void {
        if (this.stype.toLowerCase() === "input") {
            this.css = {
                'btn': true,
                'cea-help-btn': true,
                'cea-help-btn-input': true
            }
        }
        else if (this.stype.toLowerCase() === "radio") {
            this.css = {
                'btn': true,
                'btn-xs': true,
                'btn-link': true,
                'cea-help-link': true,
                'cea-help-link-radio': true
            };
        }
        else if (this.stype.toLowerCase() === "header") {
            this.css = {
                'btn': true,
                'cea-help-btn': true,
                'cea-help-btn-header': true
            };

            this.icss = {
                'cea-help-icon-header': true
            };
        }
        else {
            this.css = {
                'btn': true,
                'cea-help-clear-btn': true
            };
        }
    }

    openHelperPopup() {
        const header = this.translate.instant(this.title);
        let body = this.imageService.HelpImageLoc(this.translate.instant(this.text1));

        if (this.text2) {
            body += this.imageService.HelpImageLoc(this.translate.instant(this.text2));

            if (this.text3) {
                body += this.imageService.HelpImageLoc(this.translate.instant(this.text3));
            }
        }
        const data = {
            header,
            body,
            showTopCloseButton: false
        };
        const size = "xl";

        this.moveTop.emit(this.title);
        this.openModal(data, size);
    }

    openModal(data, size) {
        const modalRef = this.modalService.open(PopupComponent,
            {
                scrollable: false,
                windowClass: "myCustomModalClass",
                size
            });
        modalRef.componentInstance.fromParent = data;
        modalRef.result.then();
    }

}
