import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class HelperService {

  GenerateGuid(): string {
    let d = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g,
        c => {
          let r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
        });
    return uuid;
  }

  removeid: number;

  deletePolicy(id){
    this.removeid = id;
  }

}
