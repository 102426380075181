<!-- simpleheader.html -->
<div class="cea-header-view">
    <div class="row" id="cea-simple-header-1">
        <div class="offset-sm-0 cea-col-sm-12 cea-col-xs-12 cea-col-xxs-12 cea-col-xss-12">
            <div class="header-container">
                <div class="simple-header text-center">
                    <div class="row title">{{headerTitle()}}</div>
                    <div class="row subtitle">{{"HOMEOWNERS.HEADER_TEXT" | translate}}</div>
                    <div [ngClass]="'header-image'" *ngIf="policytype === 1"
                         [inlineSVG]="imageUrl('/files/assets/img/home_circle.svg')"  [removeSVGAttributes]="['xmlns']"></div>
                    <div class="header-image" *ngIf="policytype === 2"
                         [inlineSVG]="imageUrl('/files/assets/img/renter_circle.svg')"></div>
                    <div class="header-image" *ngIf="policytype === 3"
                         [inlineSVG]="imageUrl('/files/assets/img/condo_circle.svg')"></div>
                    <div class="header-image" *ngIf="policytype === 4"
                         [inlineSVG]="imageUrl('/files/assets/img/mobile_circle.svg')"></div>
                </div>
            </div>
            <div class="header-language simple">
                <button id="es" class="btn btn-link"
                        style="float:left;font-weight:normal"
                        *ngIf="!(this.translationService.currentLanguage === lang.spanish)"
                        (click)="useLanguage(lang.spanish)">
                    en Español
                </button>
                <button id="en" class="btn btn-link"
                        style="float:left;font-weight:normal"
                        *ngIf="!(this.translationService.currentLanguage === lang.english)"
                        (click)="useLanguage(lang.english)">
                    in English
                </button>
                <button id="zh" class="btn btn-link"
                        *ngIf="!(this.translationService.currentLanguage === lang.chinese)"
                        style="float:left;font-weight:normal" (click)="useLanguage(lang.chinese)">
                    中文
                </button>
            </div>
        </div>
    </div>
</div>


<app-general *ngIf="!showQuestions" (gotoQuestionPageEvent)="goToQuestionPage($event)" [policytype] = "policytype"  #general></app-general>
<app-questions *ngIf="showQuestions" (gotoGeneralPageEvent)="goToGeneralPage($event)" [policytype] = "policytype" [yesCompare]="yesCompare"  #questions></app-questions>
