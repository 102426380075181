import { Component } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-policy-diff',
  templateUrl: './policy-diff.component.html',
  styleUrls: ['./policy-diff.component.scss']
})
export class PolicyDiffComponent  {

  constructor(public activeModal: NgbActiveModal) { }


  closeModal(sendData) {
      this.activeModal.close(sendData);
  }

}
