import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SessionStorageService {

  getItem(key) {
    return sessionStorage.getItem(key);
  }

  saveItem(key, strVal) {
    sessionStorage.setItem(key, strVal);
  }

  removeItem(key) {
    sessionStorage.removeItem(key);
  }

  getObject(key) {
    const str = this.getItem(key);
    if (str) {
      return JSON.parse(str);
    }
    return null;
  }

  saveObject(key, obj) {
    this.saveItem(key, JSON.stringify(obj));
  }

  removeObject(key) {
    this.removeItem(key);
  }
}
